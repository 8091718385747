import { DEFAULT_HEADINGS_CSS_VARIABLES } from '../Text/constants/globalTextCssVariables'
import { ComponentMeta } from '../componentCommonTypes'
import { componentDefaultConfigFactory } from '../helpers'
import { textToLexical } from '@sceneio/wysiwyg-utils'

export const timelineItemComponentMeta: ComponentMeta<'timelineItem'> = {
  defaultConfig: {
    meta: {
      dataType: 'timeline-item-component',
    },
    margin: {
      data: {
        '--margin-default-bottom': '1rem',
      },
    },
    components: {
      contentContainer: {
        meta: {
          dataType: 'container-component',
        },
        padding: {
          data: {
            '--padding-default-top': '0rem',
            '--padding-default-bottom': '4rem',
            '--padding-default-left': '1.5rem',
            '--padding-default-right': '1.5rem',
          },
        },
      },
      heading: {
        meta: {
          dataType: 'text-component',
        },
        ...DEFAULT_HEADINGS_CSS_VARIABLES['h4'],
        children: textToLexical('Subheading one', 'h4'),
        as: 'h4',
        padding: {
          data: {
            '--padding-default-bottom': '0.5rem',
          },
        },
      },
      text: {
        meta: {
          dataType: 'text-component',
        },
        font: {
          meta: {
            dataType: 'font-style',
          },
        },
        children: textToLexical(
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique.',
        ),
        as: 'p',
      },
      image: {
        meta: {
          dataType: 'image-component',
        },
        responsiveConfig: {
          default: {
            src: 'https://assets.scene.io/global/placeholders/check-circle.svg',
          },
        },
      },
    },
    swapOrder: false,
    show: true,
  },
}

export const timelineItemDefaults =
  componentDefaultConfigFactory<'timelineItem'>({
    componentMeta: timelineItemComponentMeta,
  })
