import type { SubscriptionFormBlock } from '@sceneio/schemas/lib/types'
import { textToLexical } from '@sceneio/wysiwyg-utils'
import {
  formDefaults,
  formFieldSampleFactory,
} from '../../../components/Form/formMeta'
import { BlockComponentMeta, BlockMeta } from '../../blockCommonTypes'
import { blockDefaultConfigFactory } from '../../helpers'
import { DEFAULT_HEADINGS_CSS_VARIABLES } from '../../../components/Text/constants/globalTextCssVariables'
import data from '../../../scripts/libs/external/bootstrap/dom/data'

const FORM_DEFAULTS = formDefaults.config

export const subscriptionFormComponentMeta: BlockComponentMeta<SubscriptionFormBlock> =
  {
    block: {
      path: 'components.block',
      name: 'Block',
      type: 'block',
      defaultConfig: {
        meta: {
          dataType: 'block-component',
        },
        horizontalAlignment: {
          data: {
            '--horizontal-alignment-default': 'center',
          },
        },
        verticalAlignment: {
          data: {
            '--vertical-alignment-default': 'center',
          },
        },
      },
    },
    blockContainer: {
      path: 'components.block.blockContainer',
      name: 'Container',
      disableToggleShow: true,
      defaultConfig: {
        meta: {
          dataType: 'block-container-component',
        },
        responsiveConfig: {
          default: {
            maxWidth: '50%',
            rowGap: '20px',
          },
          md: {
            maxWidth: '75%',
          },
          xs: {
            maxWidth: '100%',
          },
        },
        padding: {
          data: {
            '--padding-default-bottom': '4rem',
            '--padding-default-top': '4rem',
          },
        },
        horizontalAlignment: {
          data: {
            '--horizontal-alignment-default': 'center',
          },
        },
      },
      type: 'blockContainer',
    },
    tagline: {
      path: 'components.tagline',
      name: 'Tagline',
      inlineEditors: [
        {
          editorType: 'inlineText',
          editorPath: 'components.tagline',
        },
      ],
      defaultConfig: {
        meta: {
          dataType: 'text-component',
        },
        children: textToLexical('Tagline'),
        as: 'p',
        font: {
          meta: {
            dataType: 'font-style',
          },
          data: {
            default: {
              '--font-default-weight-default': 'bold',
            },
          },
        },
        horizontalAlignment: {
          data: {
            '--horizontal-alignment-default': 'center',
          },
        },
        show: false,
      },
      type: 'text',
    },
    heading: {
      path: 'components.heading',
      name: 'Heading',
      inlineEditors: [
        {
          editorType: 'inlineText',
          editorPath: 'components.heading',
        },
      ],
      defaultConfig: {
        meta: {
          dataType: 'text-component',
        },
        ...DEFAULT_HEADINGS_CSS_VARIABLES['h2'],
        children: textToLexical('Join waitlist', 'h2'),
        as: 'h2',
        horizontalAlignment: {
          data: {
            '--horizontal-alignment-default': 'center',
          },
        },
      },
      type: 'text',
    },
    subheading: {
      path: 'components.subheading',
      name: 'Subheading',
      inlineEditors: [
        {
          editorType: 'inlineText',
          editorPath: 'components.subheading',
          editorOptions: {
            include: ['table', 'list'],
          },
        },
      ],
      defaultConfig: {
        meta: {
          dataType: 'text-component',
        },
        font: {
          meta: {
            dataType: 'font-style',
          },
        },
        children: textToLexical(
          'Enter your email, and be the first to know when we’ll launch. No spam, we promise.',
        ),
        as: 'p',
        horizontalAlignment: {
          data: {
            '--horizontal-alignment-default': 'center',
          },
        },
        padding: {
          data: {
            '--padding-default-bottom': '1rem',
          },
        },
      },
      type: 'text',
    },
    form: {
      type: 'form',
      path: 'components.form',
      name: 'Form',
      disableToggleShow: true,
      components: {
        items: {
          isArrayComponent: true,
          path: 'items',
          name: 'Email field',
          type: 'formField',
          disableToggleShow: true,
          components: {
            label: {
              path: 'components.label',
              name: 'Label',
              type: 'text',
              inlineEditors: [
                {
                  editorType: 'inlineText',
                  editorPath: 'components.label',
                  editorOptions: {
                    isNestedArrayItem: true,
                  },
                },
              ],
            },
            field: {
              path: 'components.field',
              name: 'Input',
              type: 'input',
            },
            requiredValueError: {
              path: 'components.requiredValueError',
              name: 'Required value',
              type: 'text',
              inlineEditors: [
                {
                  editorType: 'inlineText',
                  editorPath: 'components.requiredValueError',
                  editorOptions: {
                    isNestedArrayItem: true,
                  },
                },
              ],
            },
            invalidPatternError: {
              path: 'components.invalidPatternError',
              name: 'Invalid pattern',
              type: 'text',
              inlineEditors: [
                {
                  editorType: 'inlineText',
                  editorPath: 'components.invalidPatternError',
                  editorOptions: {
                    isNestedArrayItem: true,
                  },
                },
              ],
            },
            minLengthError: {
              path: 'components.minLengthError',
              name: 'Min length',
              type: 'text',
              inlineEditors: [
                {
                  editorType: 'inlineText',
                  editorPath: 'components.minLengthError',
                  editorOptions: {
                    isNestedArrayItem: true,
                  },
                },
              ],
            },
            maxLengthError: {
              path: 'components.maxLengthError',
              name: 'Max length',
              type: 'text',
              inlineEditors: [
                {
                  editorType: 'inlineText',
                  editorPath: 'components.maxLengthError',
                  editorOptions: {
                    isNestedArrayItem: true,
                  },
                },
              ],
            },
          },
        },
        button: {
          path: 'components.button',
          name: 'Submit button',
          type: 'button',
          configEditor: {
            formProps: {
              excludedSettings: ['link'],
            },
          },
        },
        successMessage: {
          path: 'components.successMessage',
          name: 'Success message',
          type: 'text',
          inlineEditors: [
            {
              editorType: 'inlineText',
              editorPath: 'components.successMessage',
              editorOptions: {
                isNestedArrayItem: true,
              },
            },
          ],
        },
        errorMessage: {
          path: 'components.errorMessage',
          name: 'Error message',
          type: 'text',
          inlineEditors: [
            {
              editorType: 'inlineText',
              editorPath: 'components.errorMessage',
              editorOptions: {
                isNestedArrayItem: true,
              },
            },
          ],
        },
      },
      defaultConfig: {
        ...FORM_DEFAULTS,
        items: [
          formFieldSampleFactory({
            name: 'email',
            type: 'email',
            showLabel: false,
            placeholder: 'Enter your email',
            label: 'Email',
          }),
        ],
        flow: {
          data: {
            '--flow-default': 'row',
            '--flow-xs': 'column',
          },
        },
        components: {
          ...FORM_DEFAULTS.components,
          button: {
            ...FORM_DEFAULTS.components.button,
            children: textToLexical('Join waitlist'),
            font: {
              meta: {
                dataType: 'font-style',
              },
              data: {
                default: {
                  ...FORM_DEFAULTS.components.button.font?.data?.default,
                  '--font-default-weight-default': 600,
                },
              },
            },
          },
          successMessage: {
            ...FORM_DEFAULTS.components.successMessage,
            horizontalAlignment: {
              data: {
                '--horizontal-alignment-default': 'center',
              },
            },
          },
          errorMessage: {
            ...FORM_DEFAULTS.components.errorMessage,
            children: textToLexical(
              'Something went wrong, and we couldn’t submit your email. Please try again.',
            ),
            horizontalAlignment: {
              data: {
                '--horizontal-alignment-default': 'center',
              },
            },
          },
        },
      },
    },
  }

export const subscriptionFormBlockMeta: BlockMeta<SubscriptionFormBlock> = {
  defaultConfig: {},
  configEditor: {},
}

export const subscriptionFormDefaultConfig =
  blockDefaultConfigFactory<SubscriptionFormBlock>({
    componentMeta: subscriptionFormComponentMeta,
    blockMeta: subscriptionFormBlockMeta,
    version: 1,
  })
