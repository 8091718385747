import React from 'react'
import { Block } from '../../../components/Block/Block'
import Text from '../../../components/Text/Text'
import resolveComponentId from '../../../tools/helpers/resolveComponentId'
import clsx from 'clsx'
import { pricingVariant1DefaultConfig } from './pricingVariant1Meta'
import { PluginsType } from '../../../scripts/plugins/pluginTypes'
import Tabs from '../../../components/Tabs/Tabs'
import PricingCard from '../../../components/PricingCard/PricingCard'
import { PricingVariant1Block } from '@sceneio/schemas/lib/types'
import Grid from '../../../components/Grid/Grid'

function PricingVariant1({
  id,
  components: { block, tagline, heading, text, tabs },
  editor,
  preview,
}: PricingVariant1Block = pricingVariant1DefaultConfig) {
  const { className, ...restBlockProps } = block || {}
  const pricing1Class = clsx('sc-pricing-1', className)

  return (
    <Block
      className={pricing1Class}
      id={id}
      editor={editor}
      {...restBlockProps}
    >
      <Text
        {...tagline}
        editor={{
          ...editor?.component,
          blockId: id,
          id: 'tagline',
        }}
        cid={resolveComponentId(id, 'tagline')}
      />
      <Text
        {...heading}
        editor={{
          ...editor?.component,
          blockId: id,
          id: 'heading',
        }}
        cid={resolveComponentId(id, 'heading')}
      />
      <Text
        {...text}
        editor={{
          ...editor?.component,
          blockId: id,
          id: 'text',
        }}
        cid={resolveComponentId(id, 'text')}
      />
      <Tabs
        {...tabs}
        editor={{
          ...editor?.component,
          blockId: id,
          id: 'tabs',
        }}
        preview={preview}
        components={{
          tabsMenu: tabs.components.tabsMenu,
          tabsContent: {
            ...tabs.components.tabsContent,
            items: tabs.components.tabsContent.items?.map((tabPane, idx) => ({
              ...tabPane,
              children: (
                <Grid
                  {...tabPane.components.cards}
                  className="bui-w-100"
                  editor={{
                    ...editor?.component,
                    blockId: id,
                    id: `tabs.tabsContent.items[${idx}].cards`,
                  }}
                  items={tabPane.components.cards.items?.map(
                    (pricingCard, pIdx) => (
                      <PricingCard
                        {...pricingCard}
                        key={pricingCard.id}
                        editor={{
                          ...editor?.component,
                          blockId: id,
                          id: `tabs.tabsContent.items[${idx}].cards.items[${pIdx}]`,
                        }}
                      />
                    ),
                  )}
                />
              ),
            })),
          },
        }}
      />
    </Block>
  )
}

export const pricingVariant1Plugins: PluginsType = {
  js: ['animation', 'tabs'],
  css: [],
}

export default PricingVariant1
