import { assocPath } from 'ramda'
import { convertJMESPathToArrayPath } from './convertJMESPathToArrayPath'

export function assocJMESPath<T extends Object>(
  path?: string,
  value?: any,
  object?: T,
) {
  if (typeof path !== 'string') throw Error('Property `path` is not defined')
  if (typeof value === 'undefined')
    throw Error('Property `value` is not defined')
  if (!object) throw Error('Property `object` is not defined')

  const normalizedPath = convertJMESPathToArrayPath(path, object)
  return assocPath(normalizedPath, value, object)
}
