import { ComponentMeta } from '../componentCommonTypes'
import { componentDefaultConfigFactory } from '../helpers'

export const blockComponentMeta: ComponentMeta<'block'> = {
  defaultConfig: {
    meta: {
      dataType: 'block-component',
    },
    show: true,
    background: {
      fit: 'cover',
      parallax: false,
    },
    as: 'section',
    dataAttributes: {},
    position: {
      default: 'relative',
    },
  },
}

export const blockDefaults = componentDefaultConfigFactory<'block'>({
  componentMeta: blockComponentMeta,
})
