import { ComponentMeta } from '../componentCommonTypes'
import { componentDefaultConfigFactory } from '../helpers'
import { randomString } from '@sceneio/tools'
import { DEFAULT_HEADINGS_CSS_VARIABLES } from '../Text/constants/globalTextCssVariables'
import { mediaDefaults } from '../Media/mediaMeta'
import {
  DEFAULT_BUTTON_CSS_VARIABLES,
  DEFAULT_OUTLINE_BUTTON_CSS_VARIABLES,
} from '../Button/constants/globalButtonCssVariables'
import { textToLexical } from '@sceneio/wysiwyg-utils'
import { CheckListItemComponent } from '@sceneio/schemas/lib/components/pricingCardComponentSchema'
import { ButtonComponent } from '@sceneio/schemas/lib/types'

const MEDIA_DEFAULTS = mediaDefaults.config

export const buttonSampleFactory = ({
  title = 'Button',
}: {
  title?: string
} = {}): ButtonComponent & { id: string } => ({
  meta: {
    dataType: 'button-component',
  },
  id: randomString(8),
  children: textToLexical(title, 'array-item'),
  ...DEFAULT_BUTTON_CSS_VARIABLES,
  width: {
    data: {
      '--width-default': 'auto',
      '--width-xs': '100%',
    },
  },
})

export const checkListItemSampleFactory = (): CheckListItemComponent => ({
  meta: {
    dataType: 'container-component',
  },
  id: randomString(8),
  flow: {
    data: {
      '--flow-default': 'row',
    },
  },
  verticalAlignment: {
    data: {
      '--vertical-alignment-default': 'center',
    },
  },
  gap: {
    data: {
      '--column-gap-default': '0.75rem',
    },
  },
  components: {
    icon: {
      meta: {
        dataType: 'icon-component',
      },
      show: true,
      width: {
        data: {
          '--width-default': '24px',
        },
      },
      responsiveConfig: {
        default: {
          default: {
            provider: 'phosphor',
            name: 'Check',
          },
        },
      },
    },
    text: {
      meta: {
        dataType: 'text-component',
      },
      children: textToLexical('Included feature text goes here'),
      as: 'p',
      font: {
        meta: {
          dataType: 'font-style',
        },
      },
    },
  },
})

export const pricingCardComponentMeta: ComponentMeta<'pricingCard'> = {
  defaultConfig: {
    meta: {
      dataType: 'pricing-card-component',
    },
    padding: {
      data: {
        '--padding-default-top': '1.5rem',
        '--padding-default-bottom': '1.5rem',
        '--padding-default-left': '1.5rem',
        '--padding-default-right': '1.5rem',
      },
    },
    border: {
      meta: {
        dataType: 'border-style',
      },
      data: {
        default: {
          '--border-default-color-default': '#000000',
          '--border-default-width-default': '1px',
          '--border-default-style-default': 'solid',
          '--border-default-top-style-default': 'solid',
          '--border-default-bottom-style-default': 'solid',
          '--border-default-left-style-default': 'solid',
          '--border-default-right-style-default': 'solid',
          '--border-default-radius-top-left-default': '0px',
          '--border-default-radius-top-right-default': '0px',
          '--border-default-radius-bottom-left-default': '0px',
          '--border-default-radius-bottom-right-default': '0px',
        },
      },
    },
    components: {
      media: {
        meta: {
          dataType: 'media-component',
        },
        format: 'image',
        margin: {
          data: {
            '--margin-default-top': '1rem',
            '--margin-default-bottom': '1rem',
          },
        },
        components: {
          ...MEDIA_DEFAULTS.components,
          image: {
            meta: {
              dataType: 'image-component',
            },
            responsiveConfig: {
              default: {
                src: 'https://assets.scene.io/global/placeholders/check-circle.svg',
              },
            },
          },
        },
      },
      badge: {
        meta: {
          dataType: 'container-component',
        },
        border: {
          meta: {
            dataType: 'border-style',
          },
          data: {
            default: {
              '--border-default-color-default': '#000000',
              '--border-default-width-default': '1px',
              '--border-default-style-default': 'solid',
              '--border-default-top-style-default': 'solid',
              '--border-default-bottom-style-default': 'solid',
              '--border-default-left-style-default': 'solid',
              '--border-default-right-style-default': 'solid',
              '--border-default-radius-top-left-default': '0px',
              '--border-default-radius-top-right-default': '0px',
              '--border-default-radius-bottom-left-default': '0px',
              '--border-default-radius-bottom-right-default': '0px',
            },
          },
        },
        padding: {
          data: {
            '--padding-default-bottom': '0.5rem',
            '--padding-default-top': '0.5rem',
            '--padding-default-left': '1rem',
            '--padding-default-right': '1rem',
          },
        },
        components: {
          text: {
            meta: {
              dataType: 'text-component',
            },
            children: textToLexical('Best value'),
            as: 'p',
            font: {
              meta: {
                dataType: 'font-style',
              },
              data: {
                default: {
                  '--font-default-weight-default': 600,
                },
              },
            },
          },
        },
      },
      contentContainer: {
        meta: {
          dataType: 'container-component',
        },
        gap: {
          data: {
            '--row-gap-default': '0.75rem',
          },
        },
        margin: {
          data: {
            '--margin-default-top': '1rem',
          },
        },
        horizontalAlignment: {
          data: {
            '--horizontal-alignment-default': 'flex-start',
          },
        },
      },
      tagline: {
        meta: {
          dataType: 'text-component',
        },
        children: textToLexical('Plan name goes here'),
        as: 'p',
        font: {
          meta: {
            dataType: 'font-style',
          },
          data: {
            default: {
              '--font-default-weight-default': 'bold',
            },
          },
        },
      },
      heading: {
        meta: {
          dataType: 'text-component',
        },
        ...DEFAULT_HEADINGS_CSS_VARIABLES['h2'],
        children: textToLexical('$15/mo', 'h2'),
        as: 'h2',
      },
      description: {
        meta: {
          dataType: 'text-component',
        },
        font: {
          meta: {
            dataType: 'font-style',
          },
        },
        children: textToLexical('Lorem ipsum dolor sit amet, consectetur.'),
        as: 'p',
      },
      buttons: {
        meta: {
          dataType: 'container-component',
        },
        gap: {
          data: {
            '--row-gap-default': '1rem',
            '--column-gap-default': '0.5rem',
          },
        },
        flow: {
          data: {
            '--flow-default': 'row',
          },
        },
        horizontalAlignment: {
          data: {
            '--horizontal-alignment-default': 'flex-start',
          },
        },
        verticalAlignment: {
          data: {
            '--vertical-alignment-default': 'center',
          },
        },
        margin: {
          data: {
            '--margin-default-top': '2.25rem',
            '--margin-default-bottom': '2.25rem',
          },
        },
        items: [
          buttonSampleFactory({ title: 'Button' }),
          {
            ...buttonSampleFactory({ title: '2nd Button' }),
            ...DEFAULT_OUTLINE_BUTTON_CSS_VARIABLES,
          },
        ],
      },
      subheading: {
        meta: {
          dataType: 'text-component',
        },
        children: textToLexical('Subheading'),
        as: 'p',
        font: {
          meta: {
            dataType: 'font-style',
          },
          data: {
            default: {
              '--font-default-weight-default': 500,
            },
          },
        },
      },
      checkList: {
        meta: {
          dataType: 'container-component',
        },
        gap: {
          data: {
            '--row-gap-default': '0.5rem',
          },
        },
        flow: {
          data: {
            '--flow-default': 'column',
          },
        },
        items: [
          checkListItemSampleFactory(),
          checkListItemSampleFactory(),
          checkListItemSampleFactory(),
        ],
      },
    },
  },
}

export const pricingCardDefaults = componentDefaultConfigFactory<'pricingCard'>(
  {
    componentMeta: pricingCardComponentMeta,
  },
)
