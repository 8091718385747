import type { SnippetType } from '@sceneio/snippets-tools/lib/snippetsTypes'
import {
  assocJMESPath,
  changeObjectPropertyByJmespath,
  normalizeJMESPath,
} from '@sceneio/tools'
import { search } from 'jmespath'

const SNIPPET_TYPE_TO_STYLE_TYPE = {
  TEXT: 'font-style',
  STROKE: 'border-style',
  TEXT_SHADOW: 'text-shadow-style',
  BOX_SHADOW: 'box-shadow-style',
}

export function updateEntityDataWithAssignSnippet<T>({
  entityData,
  snippet,
  snippetTargetPathWithBasePath,
  snippetData,
  targetPath,
}: {
  entityData: Record<string, any>
  snippetTargetPathWithBasePath: string
  snippet: SnippetType
  snippetData: Record<string, any>
  targetPath?: string
}): {
  updatedEntityData: T
  snippetTargetPath: string
  entityTargetPath: string
} {
  const dataAlreadyExists = search(
    entityData,
    normalizeJMESPath(snippetTargetPathWithBasePath),
  )

  let updatedEntityData = entityData

  // get highest path level to be able add meta dataType
  const snippetTargetPath = snippetTargetPathWithBasePath.replace(
    /(.data|.data.responsiveConfig)$/g,
    '',
  )
  const entityTargetPath = targetPath
    ? targetPath.replace(/(.data|.data.responsiveConfig)$/g, '')
    : targetPath

  let snippetDataWithMetaDataType = snippetData

  if (
    snippetTargetPathWithBasePath.endsWith('.data') &&
    Object.keys(SNIPPET_TYPE_TO_STYLE_TYPE).includes(snippet.type)
  ) {
    snippetDataWithMetaDataType = {
      data: snippetData,
      meta: {
        dataType: SNIPPET_TYPE_TO_STYLE_TYPE[snippet.type],
      },
    }
  }

  if (
    snippetTargetPathWithBasePath.endsWith('.data.responsiveConfig') &&
    Object.keys(SNIPPET_TYPE_TO_STYLE_TYPE).includes(snippet.type)
  ) {
    snippetDataWithMetaDataType = {
      data: {
        responsiveConfig: snippetData,
      },
      meta: {
        dataType: SNIPPET_TYPE_TO_STYLE_TYPE[snippet.type],
      },
    }
  }

  if (dataAlreadyExists) {
    updatedEntityData = changeObjectPropertyByJmespath(
      entityData,
      snippetTargetPath,
      snippetDataWithMetaDataType,
      {
        shouldMergeValue: snippet?.meta?.shouldMergeData,
      },
    )
  } else {
    updatedEntityData = assocJMESPath(
      snippetTargetPath,
      snippetDataWithMetaDataType,
      entityData,
    )
  }
  return { updatedEntityData, snippetTargetPath, entityTargetPath }
}
