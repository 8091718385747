import { InferType, bool, number, object, string } from 'yup'
import { styleSchema } from '../shared/styleSchema'
import { boxShadowStyleSchema } from '../styles/boxShadowStyleSchema'
import { marginStyleSchema } from '../styles/marginStyleSchema'
import { paddingStyleSchema } from '../styles/paddingStyleSchema'
import { componentEditorSchema } from '../shared/componentEditorSchema'
import { borderStyleSchema } from '../styles/borderStyleSchema'

const imageResponsiveConfigBreakpointSchema = object({
  src: string().required(),
  alt: string(),
  width: number(),
  height: number(),
}).noUnknown()

export const imageComponentSchema = object({
  meta: object({
    dataType: string().oneOf(['image-component']).required(),
  })
    .noUnknown()
    .required(),
  className: string(),
  padding: paddingStyleSchema.optional(),
  margin: marginStyleSchema.optional(),
  show: bool(),
  cover: bool(),
  transformationOptions: object({
    cover: string(),
    contain: string(),
  })
    .noUnknown()
    .optional(),
  style: styleSchema,
  isAnimated: bool(),
  boxShadow: boxShadowStyleSchema.optional(),
  border: borderStyleSchema.optional(),
  dataAttributes: object().optional(),
  editor: componentEditorSchema,
  responsiveConfig: object({
    default: imageResponsiveConfigBreakpointSchema.required(),
    xs: imageResponsiveConfigBreakpointSchema.optional(),
    md: imageResponsiveConfigBreakpointSchema.optional(),
    lg: imageResponsiveConfigBreakpointSchema.optional(),
  })
    .noUnknown()
    .optional(),
}).noUnknown()

export type ImageComponent = InferType<typeof imageComponentSchema>

export type ImageComponentStyles = Pick<
  ImageComponent,
  'margin' | 'padding' | 'boxShadow' | 'border'
>
