import React from 'react'
import { TabsComponent } from '@sceneio/schemas/lib/types'
import clsx from 'clsx'
import { PluginsType } from '../../scripts/plugins/pluginTypes'
import { getRootLevelCssVariables } from '../../tools/cssVariables/getRootLevelCssVariables'
import { getShadowCssVariables } from '../../tools/cssVariables/getShadowCssVariables'
import TabsMenu from './components/TabsMenu'
import TabsContent from './components/TabsContent'
import { getBackgroundCssVariables } from '../../tools/cssVariables/getBackgroundCssVariables'
import { twicPicsSourceUrlResolver } from '@sceneio/middleware/lib/twicPicsSourceUrlResolver'
import { resolveBreakpointImageClasses } from '../Image/helpers/resolveBreakpointImageClasses'
import { resolveDefaultImageClasses } from '../Image/helpers/resolveDefaultImageClasses'
import {
  AvailableBreakpointsEnum,
  BreakpointEnum,
} from '../Image/constants/breakpoints'

function Tabs({
  components: { tabsMenu, tabsContent },
  defaultActive,
  horizontalAlignment,
  verticalAlignment,
  padding,
  margin,
  boxShadow,
  border,
  background,
  gap,
  style,
  cid,
  editor,
  className,
  preview,
  show,
}: TabsComponent) {
  if (!show) {
    return null
  }

  const tabsClassName = clsx(
    'sc-tabs-container',
    editor?.resolveClassName?.({
      blockId: editor.blockId,
      componentId: editor.id,
    }),
    className,
  )

  const {
    fit,
    parallax,
    responsiveConfig: bgResponsiveConfig,
  } = background || {}

  return (
    <div
      data-plugin-tabs={JSON.stringify({ defaultActive })}
      data-component-id={editor?.id}
      className={tabsClassName}
      style={{
        ...style,
        ...getRootLevelCssVariables({
          cssVariables: {
            horizontalAlignment,
            verticalAlignment,
            padding,
            margin,
            border,
            gap,
          },
          prefix: 'tabs',
        }),
        ...getShadowCssVariables({
          shadow: boxShadow,
          shadowType: 'box',
          prefix: 'tabs',
        }),
        ...getBackgroundCssVariables({
          background,
          prefix: 'tabs',
        }),
      }}
    >
      <TabsMenu
        {...tabsMenu}
        cid={cid}
        editor={editor}
        preview={preview}
        defaultActive={defaultActive}
      />
      <TabsContent
        {...tabsContent}
        editor={editor}
        preview={preview}
        defaultActive={defaultActive}
      />

      {bgResponsiveConfig && Object.keys(bgResponsiveConfig).length && (
        <>
          <div className="sc-tabs-container__bg-overlay" />
          {Object.entries(bgResponsiveConfig).map(([bp, config]) => {
            const { image } = config || {}
            return (
              <React.Fragment key={bp}>
                {image?.src && (
                  <div
                    style={{
                      backgroundImage: `url(${image.src}?twic=v1/output=preview)`,
                    }}
                    data-twic-background={`url(${twicPicsSourceUrlResolver(
                      image.src,
                    )}${editor ? `?cover=${fit === 'cover'}` : ''})`}
                    className={clsx('sc-tabs-container__bg-image', {
                      'sc-tabs-container__bg-image--parallax': parallax,
                      'sc-tabs-container__bg-image--cover': fit === 'cover',
                      [resolveBreakpointImageClasses({
                        breakpoint: bp as BreakpointEnum,
                        elementClassName: 'sc-tabs-container__bg-image',
                      })]: bp !== 'default',
                      [resolveDefaultImageClasses({
                        breakpointsArr: (
                          Object.keys(
                            bgResponsiveConfig,
                          ) as AvailableBreakpointsEnum[]
                        ).filter((bp) => bgResponsiveConfig[bp]),
                        elementClassName: 'sc-tabs-container__bg-image',
                      })]: bp === 'default',
                    })}
                  />
                )}
              </React.Fragment>
            )
          })}
        </>
      )}
    </div>
  )
}

export const plugins: PluginsType = {
  js: ['video', 'animation', 'tabs'],
  css: [],
}

export default Tabs
