import { BlockComponentMeta, BlockMeta } from '../blockCommonTypes'
import { blockDefaultConfigFactory } from '../helpers'
import type { BlockComponent, EmbedBlock } from '@sceneio/schemas/lib/types'
import { blockLayoutAdditionalFormFieldRenderer } from './form/additionalFormFieldRenderers'

export const embedComponentMeta: BlockComponentMeta<EmbedBlock> = {
  block: {
    path: 'components.block',
    name: 'Block',
    type: 'block',
    defaultConfig: {
      meta: {
        dataType: 'block-component',
      },
      embedHtml: '<div class="bui-text-center">Add your HTML here</div>',
      padding: {
        data: {
          '--padding-default-top': '1rem',
          '--padding-default-bottom': '1rem',
        },
      },
    } as BlockComponent & {
      embedHtml: string
    },
    configEditor: {
      additionalFormFields: {
        layout: {
          formFieldRenderer: blockLayoutAdditionalFormFieldRenderer,
        },
      },
    },
  },
  blockContainer: {
    path: 'components.block.blockContainer',
    name: 'Container',
    disableToggleShow: true,
    type: 'blockContainer',
    defaultConfig: {
      meta: {
        dataType: 'block-container-component',
      },
      responsiveConfig: {
        default: {
          maxWidth: 'screen',
        },
      },
      horizontalAlignment: {
        data: {
          '--horizontal-alignment-default': 'center',
        },
      },
    },
  },
}

export const embedBlockMeta: BlockMeta<EmbedBlock> = {}

export const embedDefaultConfig = blockDefaultConfigFactory<EmbedBlock>({
  componentMeta: embedComponentMeta,
  blockMeta: embedBlockMeta,
  version: 1,
})
