import { InferType, bool, object, string } from 'yup'
import { reactNodeSchema } from '../shared/reactNodeSchema'
import { marginStyleSchema } from '../styles/marginStyleSchema'
import { paddingStyleSchema } from '../styles/paddingStyleSchema'
import { borderStyleSchema } from '../styles/borderStyleSchema'
import { boxShadowStyleSchema } from '../styles/boxShadowStyleSchema'
import { horizontalAlignmentFlexStyleSchema } from '../styles/horizontalAlignmentFlexStyleSchema'
import { verticalAlignmentStyleSchema } from '../styles/verticalAlignmentStyleSchema'
import { backgroundSchema } from './common/backgroundSchema'
import { styleSchema } from '../shared/styleSchema'
import { gapStyleSchema } from '../styles/gapStyleSchema'
import { componentEditorSchema } from '../shared/componentEditorSchema'
import { flowStyleSchema } from '../styles/flowStyleSchema'

export const containerComponentSchema = object({
  meta: object({
    dataType: string().oneOf(['container-component']).required(),
  })
    .noUnknown()
    .required(),
  name: string().oneOf(['group', 'componentArray', 'component']),
  children: reactNodeSchema,
  className: string(),
  as: string().oneOf(['div']),
  margin: marginStyleSchema.optional(),
  padding: paddingStyleSchema.optional(),
  border: borderStyleSchema.optional(),
  boxShadow: boxShadowStyleSchema.optional(),
  horizontalAlignment: horizontalAlignmentFlexStyleSchema.optional(),
  verticalAlignment: verticalAlignmentStyleSchema.optional(),
  background: backgroundSchema.optional(),
  flow: flowStyleSchema.optional(),
  show: bool(),
  style: styleSchema,
  editor: componentEditorSchema,
  gap: gapStyleSchema.optional(),
}).noUnknown()

export type ContainerComponent = InferType<
  typeof containerComponentSchema & {
    as?: keyof JSX.IntrinsicElements
  }
>

export type ContainerComponentStyles = Pick<
  ContainerComponent,
  | 'margin'
  | 'padding'
  | 'boxShadow'
  | 'border'
  | 'horizontalAlignment'
  | 'verticalAlignment'
  | 'gap'
  | 'flow'
>
