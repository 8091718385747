import { convertJMESPathToArrayIdsJMESPath } from '@sceneio/tools'

export function getReferencePath({
  configPath,
  data,
}: {
  configPath: string
  data: Record<string, any>
}): string {
  return convertJMESPathToArrayIdsJMESPath({
    configPath,
    data,
  })
}
