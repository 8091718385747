import * as Popover from '@radix-ui/react-popover'
import { Icon } from '@sceneio/ui-icons'
import type { Icons } from '@sceneio/ui-icons'

import { SnippetTypeToIcon } from './components/SnippetTypeToIcon'
import { debounce } from '@sceneio/tools'
import { useRef, useState } from 'react'
import clsx from 'clsx'
import {
  SNIPPET_TYPE_TO_DISPLAY_VALUE_MAP,
  isSnippetSlugAvailable,
} from './snippetHelpers'
import { SnippetValueMap } from './components/SnippetValueMap'
import { useOnClickOutside } from '@sceneio/hooks/lib/useOnClickOutside'
import { useFormContext, SNIPPET_TYPE } from '../../form/context/FormContext'

export const SnippetsDropdown = ({
  snippetsType,
  showSaveButton = true,
  onCreateSnippet = () => {},
  onAssignSnippet = () => {},
  onDeleteSnippet = () => {},
  icon = 'CirclesFour',
  popoverClassname,
  triggerClassName,
  onOpenChange,
}: {
  snippetsType: SNIPPET_TYPE
  showSaveButton?: boolean
  onCreateSnippet: (snippetName: string) => void
  onDeleteSnippet: (id: string) => void
  onAssignSnippet: (id: string) => void
  icon?: Icons['phosphor']
  popoverClassname?: string
  triggerClassName?: string
  onOpenChange?: (open: boolean) => void
}) => {
  const { snippets = [] } = useFormContext({ snippetsType })
  const [snippetName, setSnippetName] = useState('')
  const isSaveBtnEnabled =
    isSnippetSlugAvailable(snippetName) && snippetName.length >= 3
  const inputRef = useRef<HTMLInputElement>(null)
  const containerRef = useRef<HTMLDivElement>(null)

  const [open, setOpen] = useState(false)

  const handleOnChange = debounce((inputValue) => {
    setSnippetName(inputValue)
  }, 250) as (value: string) => void

  useOnClickOutside(
    () => {
      if (open) {
        setSnippetName('')
        setOpen(false)
      }
    },
    {
      refs: [containerRef],
      ignoreClass: ['tw-trigger-btn', 'tw-dropdown-content'],
    },
  )

  return (
    <div className="tw-flex" ref={containerRef}>
      <Popover.Root
        open={open}
        onOpenChange={(open) => {
          if (open) {
            setTimeout(() => {
              inputRef.current?.focus()
            }, 100)
          }
          if (onOpenChange) {
            onOpenChange(open)
          }
        }}
      >
        <Popover.Trigger asChild>
          <button
            className={clsx(
              'tw-trigger-btn tw-p-1 tw-rounded-sm focus-visible:tw-outline-none data-[state=open]:tw-bg-gray-100 tw-group/save-btn',
              triggerClassName,
            )}
            onClick={(e) => {
              e.stopPropagation()
              setOpen(!open)
            }}
          >
            <Icon provider="phosphor" icon={icon} />
          </button>
        </Popover.Trigger>
        <Popover.Content
          onClick={(e) => e.stopPropagation()}
          className={clsx(
            'tw-dropdown-content tw-rounded tw-min-w-[290px] tw-max-w-[400px] tw-bg-white tw-border tw-border-border-color tw-shadow-basic tw-text-xs tw-flex tw-flex-col tw-z-[999]',
            popoverClassname,
          )}
          sideOffset={5}
          align="end"
        >
          {showSaveButton && (
            <>
              <div className="tw-flex tw-justify-between tw-items-center tw-h-[40px] tw-pr-2 tw-pl-3 tw-relative tw-gap-2">
                <input
                  ref={inputRef}
                  placeholder="Style name"
                  required
                  type="text"
                  className="focus:tw-outline-none tw-grow"
                  onChange={(e) => handleOnChange(e.target.value)}
                  onKeyDown={(e) => {
                    e.stopPropagation()
                  }}
                  onKeyDownCapture={(e) => {
                    if (e.key === 'Enter' && isSaveBtnEnabled) {
                      onCreateSnippet(snippetName)
                      setSnippetName('')
                      setOpen(false)
                    }
                  }}
                />
                <button
                  type="button"
                  className="tw-text-primary tw-font-semibold hover:tw-bg-secondary tw-p-2 tw-rounded disabled:tw-text-gray-400 disabled:hover:tw-bg-transparent"
                  onClick={() => {
                    onCreateSnippet(snippetName)
                    setSnippetName('')
                    setOpen(false)
                  }}
                  disabled={!isSaveBtnEnabled}
                >
                  Save style
                </button>
                <div
                  className={clsx(
                    "tw-transition-all tw-opacity-1 tw-absolute tw-p-2 tw-bottom-[80%] tw-rounded-md tw-bg-[#252523] tw-text-white after:content-[' '] after:tw-absolute after:tw-top-full after:tw-left-1/2 after:-tw-ml-[5px] after:tw-border after:tw-border-[5px] after:tw-border-l-transparent after:tw-border-r-transparent after:tw-border-b-transparent after:tw-border-t-[#252523]",
                    {
                      'tw-opacity-0 tw-transition-none':
                        !snippetName || isSaveBtnEnabled,
                    },
                  )}
                >
                  {snippetName && snippetName.length < 3 && (
                    <>Name should contain at least 3 characters</>
                  )}
                  {snippetName && snippetName.length >= 3 && (
                    <>
                      Style{' '}
                      <span className="tw-truncate tw-font-semibold">
                        {snippetName}
                      </span>{' '}
                      already exists
                    </>
                  )}
                </div>
              </div>
              <hr className="tw-my-0" />
            </>
          )}
          <div className="tw-py-2 tw-max-h-[300px] tw-overflow-y-auto">
            <ul className="tw-pl-0 tw-mb-0">
              {snippets.length === 0 ? (
                <div className="tw-flex tw-justify-center tw-items-center tw-text-label-color tw-h-[64px]">
                  No
                  <span className="tw-font-semibold tw-px-1">
                    {SNIPPET_TYPE_TO_DISPLAY_VALUE_MAP[snippetsType]}
                  </span>
                  styles available
                </div>
              ) : (
                snippets.map(({ id, data = {}, name }, idx) => (
                  <li
                    key={idx}
                    className="tw-flex tw-justify-between tw-gap-3 tw-p-3 tw-cursor-default tw-relative hover:tw-bg-form-field-bg tw-group/snippet"
                    onClick={() => onAssignSnippet(id)}
                  >
                    <div className="tw-flex tw-items-center tw-gap-1 tw-flex-1 tw-min-w-px tw-max-w-[40%]">
                      <SnippetTypeToIcon
                        snippetType={snippetsType}
                        value={data.value}
                      />
                      <span className="tw-font-semibold tw-truncate">
                        {name}
                      </span>
                    </div>
                    <div className="tw-flex tw-items-center tw-truncate">
                      <SnippetValueMap
                        snippetType={snippetsType}
                        value={data.value}
                      />
                      <button
                        type="button"
                        className={clsx(
                          'tw-text-danger tw-px-1 tw-ml-1 tw-rounded-sm focus-visible:tw-outline-none hover:tw-bg-gray-4 tw-invisible group-hover/snippet:tw-visible',
                        )}
                        onClick={(e) => {
                          e.stopPropagation()
                          if (onDeleteSnippet) {
                            onDeleteSnippet(id)
                          }
                        }}
                      >
                        <Icon provider="phosphor" icon="Trash" size={15} />
                      </button>
                    </div>
                  </li>
                ))
              )}
            </ul>
          </div>
        </Popover.Content>
      </Popover.Root>
    </div>
  )
}
