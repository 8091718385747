import clsx from 'clsx'
import {
  FormContextProvider,
  useFormContext,
} from '../../form/context/FormContext'
import { RHFBorderSettingsPropType } from './BorderSettings'
import { SegmentWrapper } from '../../atoms/SegmentWrapper/SegmentWrapper'
import { RHFStateTabs, StateType } from '../../atoms/StateTabs/StateTabs'
import { HeaderSegment } from '../../atoms/HeaderSegment/HeaderSegment'
import { useEffect, useState } from 'react'
import { Icon } from '@sceneio/ui-icons'
import { BreakpointBorderFields } from './components/BreakpointBorderFields'
import { stringify } from '@sceneio/tools'
import { SnippetHeader } from '../../atoms/SnippetHeader/SnippetHeader'
import { SaveButton } from '../../atoms/SaveButton/SaveButton'
import { search } from 'jmespath'
import { unflatten } from 'flat'
import { BreakpointType } from '../../atoms/BreakpointDropdown/BreakpointDropdown'
import { InheritedSettingsWrapper } from '../../atoms/InheritedSettingsWrapper/InheritedSettingsWrapper'

const StateFields = ({
  isBreakpointActive,
  forceBreakpoint,
  hasDefaultValues,
  valuesPath,
  state,
  onEditDefaultFrameClick,
}: {
  isBreakpointActive: boolean
  forceBreakpoint?: BreakpointType
  hasDefaultValues: boolean
  valuesPath: string
  state: StateType
  onEditDefaultFrameClick: (arg0: { focusedMolecule?: string }) => void
}) => (
  <>
    {isBreakpointActive && (
      <BreakpointBorderFields
        breakpoint={forceBreakpoint!}
        state={state}
        valuesPath={valuesPath}
      />
    )}
    {(hasDefaultValues || isBreakpointActive) && (
      <InheritedSettingsWrapper
        onEditClick={() =>
          onEditDefaultFrameClick({ focusedMolecule: 'stroke' })
        }
      >
        <BreakpointBorderFields
          breakpoint="default"
          state={state}
          valuesPath={valuesPath}
        />
      </InheritedSettingsWrapper>
    )}
  </>
)

export const ResponsiveBorderSettings = ({
  basePath,
  className,
  stateTabs: {
    inheritedState,
    allowHover = false,
    allowActive = false,
    activeStateLabel = 'Active',
  } = {},
}: RHFBorderSettingsPropType) => {
  const valuesPath = `${basePath ? basePath + '.' : ''}data`
  const {
    values,
    unregister,
    register,
    registerValues,
    onCreateSnippet,
    onDetachSnippet,
    onAssignSnippet,
    onIssueSnippet,
    onDiscardSnippet,
    onDeleteSnippet,
    allowSaveSnippet,
    parentSnippet,
    meta,
    forceBreakpoint,
    onEditDefaultFrameClick,
    disabledSnippetActions,
  } = useFormContext({
    snippetsType: 'STROKE',
    metaPath: valuesPath,
    name: basePath,
  })

  const [isBreakpointActive, setIsBreakpointActive] = useState(false)

  const isSnippet = Object.keys(meta?.snippet || {}).length > 0
  const hasSnippetUnpublishedChanges = meta?.snippet?.hasUnpublishedChanges
  const snippetId = meta?.snippet?.id
  const isInvalidReference = meta?.invalidReference

  const filteredBorderValues = Object.entries(values).reduce(
    (acc, [key, val]) => {
      if (!key.includes('radius') && !key.includes('meta')) {
        return {
          ...acc,
          [key]: val,
        }
      }
      return acc
    },
    {},
  )
  const hasBreakpointValues = Object.keys(filteredBorderValues).some(
    (val) =>
      val.startsWith(`${valuesPath}`) && val.includes(`-${forceBreakpoint!}-`),
  )

  const hasDefaultValues = Object.keys(filteredBorderValues).some(
    (val) => val.startsWith(`${valuesPath}`) && val.includes('-default-'),
  )

  const showSettings = hasDefaultValues || hasBreakpointValues

  useEffect(() => {
    if (hasBreakpointValues) {
      setIsBreakpointActive(true)
    } else {
      setIsBreakpointActive(false)
    }
  }, [stringify(hasBreakpointValues)])

  const onAssignSnippetCallback = (
    id: string,
    options?: { shouldReplace?: boolean },
  ) => {
    onAssignSnippet({
      id,
      fieldName: valuesPath,
      parentSnippetId:
        (options?.shouldReplace ? undefined : snippetId) || parentSnippet?.id,
      parentSnippetPath: parentSnippet?.path,
    })
  }

  const onDetachSnippetCallback = () => {
    onDetachSnippet({
      id: snippetId,
      fieldName: valuesPath,
      parentSnippetId: parentSnippet?.id,
      parentSnippetPath: parentSnippet?.path,
    })
  }

  return (
    <FormContextProvider
      parentSnippet={{
        id: snippetId,
        path: meta?.snippet?.snippetPath,
        hasUnpublishedChanges: hasSnippetUnpublishedChanges,
      }}
    >
      <SegmentWrapper className={clsx('sc-bp-border-settings', className)}>
        {isSnippet ? (
          <SnippetHeader
            snippetType="STROKE"
            snippetName={meta?.snippet?.name}
            snippetId={snippetId}
            editSnippetMode={true}
            snippetHasOverrides={hasSnippetUnpublishedChanges}
            onAssignSnippet={onAssignSnippetCallback}
            detachButton={{
              show: !disabledSnippetActions?.includes('detach'),
              onClick: onDetachSnippetCallback,
            }}
            editButton={{
              show: !hasBreakpointValues,
              onClick: () => setIsBreakpointActive(!isBreakpointActive),
            }}
            customChildren={
              <>
                {hasBreakpointValues && (
                  <button
                    type="button"
                    className="tw-p-1"
                    onClick={() => {
                      unregister(
                        Object.keys(filteredBorderValues).filter((key) =>
                          key.includes(forceBreakpoint!),
                        ),
                        {
                          customData: {
                            parentSnippetId: snippetId,
                            parentSnippetPath: meta?.snippet?.snippetPath,
                          },
                        },
                      )
                    }}
                  >
                    Reset
                  </button>
                )}
              </>
            }
            // removeButton={{
            //   show: true,
            //   onClick: () => unregister([`${valuesPath}`]),
            // }}
            resetButton={{
              show: hasSnippetUnpublishedChanges,
              onClick: () => {
                onDiscardSnippet({ id: snippetId })
              },
            }}
          />
        ) : (
          <HeaderSegment
            heading="Stroke"
            addButton={{
              show: !hasDefaultValues && !hasBreakpointValues,
              onClick: () => {
                register(registerValues)
                setIsBreakpointActive(true)
              },
            }}
            isInvalidReference={isInvalidReference}
            customChildren={
              <>
                {hasBreakpointValues && (
                  <button
                    type="button"
                    className="tw-p-1"
                    onClick={() => {
                      unregister(
                        Object.keys(filteredBorderValues).filter((key) =>
                          key.includes(forceBreakpoint!),
                        ),
                        {
                          customData: {
                            parentSnippetId: snippetId,
                            parentSnippetPath: meta?.snippet?.snippetPath,
                          },
                        },
                      )
                    }}
                  >
                    Reset
                  </button>
                )}
                {hasDefaultValues && !hasBreakpointValues && (
                  <button
                    type="button"
                    className="tw-p-1"
                    onClick={() => {
                      setIsBreakpointActive(!isBreakpointActive)
                    }}
                  >
                    <Icon provider="phosphor" icon="PencilSimple" />
                  </button>
                )}
              </>
            }
            snippetsDropdown={{
              show: true,
              showSaveButton: hasDefaultValues || hasBreakpointValues,
              snippetsType: 'STROKE',
              onCreateSnippet: (snippetName) => {
                onCreateSnippet({
                  name: snippetName,
                  data: {
                    value: search(unflatten(filteredBorderValues), valuesPath),
                  },
                  type: 'STROKE',
                  fieldName: valuesPath,
                  parentSnippetId: snippetId || parentSnippet?.id,
                  parentSnippetPath: parentSnippet?.path,
                })
              },
              onDeleteSnippet,
              onAssignSnippet: onAssignSnippetCallback,
            }}
          />
        )}
        {showSettings && (
          <div className="tw-flex tw-flex-col tw-gap-2">
            {!allowHover && !allowActive && !inheritedState && (
              <StateFields
                valuesPath={valuesPath}
                forceBreakpoint={forceBreakpoint}
                isBreakpointActive={isBreakpointActive}
                hasDefaultValues={hasDefaultValues}
                state={'default'}
                onEditDefaultFrameClick={onEditDefaultFrameClick}
              />
            )}
            {inheritedState && (
              <StateFields
                valuesPath={valuesPath}
                forceBreakpoint={forceBreakpoint}
                isBreakpointActive={isBreakpointActive}
                hasDefaultValues={hasDefaultValues}
                state={inheritedState}
                onEditDefaultFrameClick={onEditDefaultFrameClick}
              />
            )}
            {!inheritedState && (allowHover || allowActive) && (
              <RHFStateTabs
                disabledTabs={[
                  ...(!allowHover ? ['hover'] : []),
                  ...(!allowActive ? ['active'] : []),
                ]}
                activeStateLabel={activeStateLabel}
                defaultStateTabContent={
                  <StateFields
                    valuesPath={valuesPath}
                    forceBreakpoint={forceBreakpoint}
                    isBreakpointActive={isBreakpointActive}
                    hasDefaultValues={hasDefaultValues}
                    state={'default'}
                    onEditDefaultFrameClick={onEditDefaultFrameClick}
                  />
                }
                hoverStateTabContent={
                  <StateFields
                    valuesPath={valuesPath}
                    forceBreakpoint={forceBreakpoint}
                    isBreakpointActive={isBreakpointActive}
                    hasDefaultValues={hasDefaultValues}
                    state={'hover'}
                    onEditDefaultFrameClick={onEditDefaultFrameClick}
                  />
                }
                activeStateTabContent={
                  <StateFields
                    valuesPath={valuesPath}
                    forceBreakpoint={forceBreakpoint}
                    isBreakpointActive={isBreakpointActive}
                    hasDefaultValues={hasDefaultValues}
                    state={'active'}
                    onEditDefaultFrameClick={onEditDefaultFrameClick}
                  />
                }
              />
            )}
            {hasSnippetUnpublishedChanges && allowSaveSnippet && (
              <SaveButton
                onClick={() => {
                  onIssueSnippet({ id: snippetId, name: meta?.snippet?.name })
                }}
              />
            )}
          </div>
        )}
      </SegmentWrapper>
    </FormContextProvider>
  )
}
