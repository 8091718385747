import { Icon } from '@sceneio/ui-icons'
import { TooltipTrigger } from '../../../../../../content/shared/ui/lib/TooltipTrigger/TooltipTrigger'
import clsx from 'clsx'

export const InvalidReferenceIcon = ({ className }: { className?: string }) => {
  return (
    <TooltipTrigger tooltip={'Reference no longer exists'}>
      <div className={clsx('tw-inline-block', className)}>
        <Icon
          provider="scene"
          icon="exclamation-mark"
          size={18}
          className={
            'tw-flex-shrink-0 tw-bg-yellow-300 tw-p-[0.25rem] tw-rounded-full'
          }
        />
      </div>
    </TooltipTrigger>
  )
}
