import {
  nestedArrayPathRegex,
  convertArrayIdsJMESPathToArrayIndexesJMESPath,
} from './convertArrayIdsJMESPathToArrayIndexesJMESPath'

export function convertJMESPathToArrayPath(
  path: string,
  data?: Record<string, any>,
): Array<string | number> {
  if (typeof path !== 'string') throw Error('Property `path` is not defined')
  const isNestedArrayIdsPath = nestedArrayPathRegex.test(path)

  let output = path
  if (isNestedArrayIdsPath && !data) {
    throw Error('Property `path` contains array ids but no data is provided')
  }
  if (isNestedArrayIdsPath && data) {
    try {
      output = convertArrayIdsJMESPathToArrayIndexesJMESPath(output, data)
    } catch (err) {
      throw Error(`[convertArrayIdsJMESPathToArrayIndexesJMESPath]: ${err}`)
    }
  }

  if (output.includes('id==')) {
    throw Error(
      'Property `path` contains array ids but array item with given id is not found within provided data',
    )
  }
  return output
    .replace(/\[/g, '.')
    .replace(/\]/g, '')
    .split('.')
    .map((key) => {
      if (key === Number(key).toString()) {
        return Number(key)
      }

      return key
    })
    .filter((val) => val !== '')
}
