import type {
  PricingCardComponent,
  PricingVariant1Block,
} from '@sceneio/schemas/lib/types'
import { randomString } from '@sceneio/tools'
import { textToLexical } from '@sceneio/wysiwyg-utils'
import { blockContainerDefaults } from '../../../components/BlockContainer/blockContainerMeta'
import { containerComponentFormRegisterValuesFactory } from '../../../components/Container/form/ContainerComponentForm'
import {
  checkListItemSampleFactory,
  pricingCardDefaults,
} from '../../../components/PricingCard/pricingCardMeta'
import {
  tabButtonSampleFactory,
  tabPaneSampleFactory,
  tabsContentDefaultConfig,
  tabsDefaults,
  tabsMenuDefaultConfig,
} from '../../../components/Tabs/tabsMeta'
import { DEFAULT_HEADINGS_CSS_VARIABLES } from '../../../components/Text/constants/globalTextCssVariables'
import { BlockComponentMeta, BlockMeta } from '../../blockCommonTypes'
import { blockDefaultConfigFactory } from '../../helpers'
import { buttonContainerLayoutAdditionalFormFieldRenderer } from '../../Hero/SplitHero/form/additionalFormFieldRenderers'
import { PricingTabPaneComponent } from '@sceneio/schemas/lib/contentBlocks/schemas/pricingVariant1BlockSchema'

const PRICING_CARD_DEFAULTS = pricingCardDefaults.config
const BLOCK_CONTAINER_DEFAULTS = blockContainerDefaults.config

type PricingCardType = PricingCardComponent & {
  id: string
}

export const pricingCardSampleFactory = ({
  heading,
  showBadge,
}: {
  heading: string
  showBadge: boolean
}): PricingCardType => ({
  id: randomString(),
  ...PRICING_CARD_DEFAULTS,
  components: {
    ...PRICING_CARD_DEFAULTS.components,
    heading: {
      ...PRICING_CARD_DEFAULTS.components.heading,
      children: textToLexical(heading),
    },
    badge: {
      ...PRICING_CARD_DEFAULTS.components.badge,
      show: showBadge,
    },
  },
})

export const tabPaneContentSampleFactory = ({
  items,
}: {
  items: PricingCardType[]
}): PricingTabPaneComponent => ({
  ...tabPaneSampleFactory(),
  components: {
    cards: {
      meta: {
        dataType: 'grid-component',
      },
      items,
      gap: {
        data: {
          [`--row-gap-default`]: '2rem',
          [`--column-gap-default`]: '2rem',
        },
      },
      columns: {
        data: {
          '--columns-default': 3,
          '--columns-md': 2,
          '--columns-xs': 1,
        },
      },
      horizontalAlignment: {
        data: {
          '--horizontal-alignment-default': 'center',
        },
      },
      cssType: 'flex',
    },
  },
})

export const pricingVariant1ComponentMeta: BlockComponentMeta<PricingVariant1Block> =
  {
    block: {
      path: 'components.block',
      name: 'Block',
      type: 'block',
      defaultConfig: {
        meta: {
          dataType: 'block-component',
        },
      },
    },
    blockContainer: {
      path: 'components.block.blockContainer',
      name: 'Container',
      disableToggleShow: true,
      defaultConfig: {
        meta: {
          dataType: 'block-container-component',
        },
        responsiveConfig: {
          default: {
            maxWidth: 'screen',
            columnGap: '1.5rem',
          },
        },
        horizontalAlignment: {
          data: {
            '--horizontal-alignment-default': 'center',
          },
        },
        padding: BLOCK_CONTAINER_DEFAULTS.padding,
      },
      type: 'blockContainer',
    },
    tagline: {
      path: 'components.tagline',
      name: 'Tagline',
      inlineEditors: [
        {
          editorType: 'inlineText',
          editorPath: 'components.tagline',
        },
      ],
      defaultConfig: {
        meta: {
          dataType: 'text-component',
        },
        children: textToLexical('Tagline'),
        as: 'p',
        horizontalAlignment: {
          data: {
            '--horizontal-alignment-default': 'center',
          },
        },
        margin: {
          data: {
            '--margin-default-bottom': '1.5rem',
          },
        },
        font: {
          meta: {
            dataType: 'font-style',
          },
          data: {
            default: {
              '--font-default-weight-default': 'bold',
            },
          },
        },
      },
      type: 'text',
    },
    heading: {
      path: 'components.heading',
      name: 'Heading',
      inlineEditors: [
        {
          editorType: 'inlineText',
          editorPath: 'components.heading',
        },
      ],
      defaultConfig: {
        meta: {
          dataType: 'text-component',
        },
        ...DEFAULT_HEADINGS_CSS_VARIABLES['h2'],
        children: textToLexical('Pricing plans', 'h2'),
        as: 'h2',
        horizontalAlignment: {
          data: {
            '--horizontal-alignment-default': 'center',
          },
        },
        margin: {
          data: {
            '--margin-default-bottom': '1.5rem',
          },
        },
      },
      type: 'text',
    },
    text: {
      path: 'components.text',
      name: 'Text',
      inlineEditors: [
        {
          editorType: 'inlineText',
          editorPath: 'components.text',
          editorOptions: {
            include: ['table', 'list'],
          },
        },
      ],
      defaultConfig: {
        meta: {
          dataType: 'text-component',
        },
        font: {
          meta: {
            dataType: 'font-style',
          },
        },
        children: textToLexical(
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros \n elementum tristique.',
        ),
        as: 'p',
        horizontalAlignment: {
          data: {
            '--horizontal-alignment-default': 'center',
          },
        },
        margin: {
          data: {
            '--margin-default-bottom': '1.5rem',
          },
        },
      },
      type: 'text',
    },
    tabs: {
      name: 'Tabs',
      path: 'components.tabs',
      components: {
        tabsMenu: {
          name: 'Tabs menu',
          path: 'components.tabsMenu',
          type: 'container',
          persistentSelectionId: 'tabs',
          components: {
            items: {
              isArrayComponent: true,
              sampleFactory: tabButtonSampleFactory,
              onAddCloneExistingItem: false,
              additionalItemsToAdd: [
                {
                  path: 'components.tabs.components.tabsContent.items',
                  isArrayPath: true,
                  cloneExistingItem: true,
                },
              ],
              additionalItemsToDelete: [
                {
                  path: 'components.tabs.components.tabsContent.items',
                  isArrayPath: true,
                },
              ],
              path: `items`,
              name: 'Tab button',
              type: 'button',
              disableToggleShow: true,
              configEditor: {
                formProps: {
                  excludedSettings: ['link'],
                },
              },
            },
          },
          configEditor: {
            additionalFormFields: {
              layout: {
                formFieldRenderer:
                  buttonContainerLayoutAdditionalFormFieldRenderer,
              },
            },
            formProps: {
              name: 'componentArray',
              registerValues:
                containerComponentFormRegisterValuesFactory('componentArray'),
            },
          },
        },
        tabsContent: {
          name: 'Tabs content',
          path: 'components.tabsContent',
          type: 'container',
          persistentSelectionId: 'tabs',
          components: {
            items: {
              isArrayComponent: true,
              sampleFactory: () =>
                tabPaneContentSampleFactory({
                  items: [
                    pricingCardSampleFactory({
                      heading: '$15/mo',
                      showBadge: false,
                    }),
                    pricingCardSampleFactory({
                      heading: '$25/mo',
                      showBadge: true,
                    }),
                    pricingCardSampleFactory({
                      heading: '$35/mo',
                      showBadge: false,
                    }),
                  ],
                }),
              onAddCloneExistingItem: true,
              additionalItemsToAdd: [
                {
                  path: 'components.tabs.components.tabsMenu.items',
                  isArrayPath: true,
                  cloneExistingItem: false,
                  config: tabButtonSampleFactory({ title: 'Tab' }),
                },
              ],
              additionalItemsToDelete: [
                {
                  path: 'components.tabs.components.tabsMenu.items',
                  isArrayPath: true,
                },
              ],
              path: `items`,
              name: 'Tab pane',
              type: 'container',
              disableToggleShow: true,
              components: {
                cards: {
                  path: 'components.cards',
                  name: 'Cards',
                  type: 'grid',
                  disableToggleShow: true,
                  components: {
                    items: {
                      isArrayComponent: true,
                      sampleFactory: pricingCardSampleFactory,
                      path: 'items',
                      name: 'Card',
                      type: 'pricingCard',
                      disableToggleShow: true,
                      components: {
                        media: {
                          type: 'media',
                          name: 'Media',
                          path: 'components.media',
                          components: {
                            credits: {
                              path: 'components.credits',
                              inlineEditors: [
                                {
                                  editorType: 'inlineText',
                                  editorPath:
                                    'components.media.components.credits',
                                  editorOptions: {
                                    isNestedArrayItem: true,
                                  },
                                },
                              ],
                              disableEditing: true,
                              name: 'Credits',
                              type: 'text',
                            },
                          },
                        },
                        contentContainer: {
                          type: 'container',
                          name: 'Content container',
                          path: 'components.contentContainer',
                          configEditor: {
                            formProps: {
                              name: 'group',
                              registerValues:
                                containerComponentFormRegisterValuesFactory(
                                  'group',
                                ),
                            },
                          },
                        },
                        tagline: {
                          type: 'text',
                          name: 'Tagline',
                          path: 'components.tagline',
                          inlineEditors: [
                            {
                              editorType: 'inlineText',
                              editorPath: 'components.tagline',
                              editorOptions: {
                                isNestedArrayItem: true,
                              },
                            },
                          ],
                        },
                        heading: {
                          type: 'text',
                          name: 'Heading',
                          path: 'components.heading',
                          inlineEditors: [
                            {
                              editorType: 'inlineText',
                              editorPath: 'components.heading',
                              editorOptions: {
                                isNestedArrayItem: true,
                              },
                            },
                          ],
                        },
                        description: {
                          type: 'text',
                          name: 'Description',
                          path: 'components.description',
                          inlineEditors: [
                            {
                              editorType: 'inlineText',
                              editorPath: 'components.description',
                              editorOptions: {
                                isNestedArrayItem: true,
                              },
                            },
                          ],
                        },
                        buttons: {
                          type: 'container',
                          name: 'Buttons',
                          path: 'components.buttons',
                          components: {
                            items: {
                              isArrayComponent: true,
                              path: 'items',
                              name: 'Button',
                              type: 'button',
                              disableToggleShow: true,
                              sampleFactory: () => tabButtonSampleFactory(),
                            },
                          },
                          inlineEditors: [
                            {
                              editorType: 'buttonEditor',
                              editorPath: 'components.buttons.items',
                              editorOptions: {
                                isNestedArrayItem: true,
                                isItemArray: true,
                                sampleFactory: () => tabButtonSampleFactory(),
                              },
                            },
                          ],
                          configEditor: {
                            additionalFormFields: {
                              layout: {
                                formFieldRenderer:
                                  buttonContainerLayoutAdditionalFormFieldRenderer,
                              },
                            },
                            formProps: {
                              name: 'componentArray',
                              registerValues:
                                containerComponentFormRegisterValuesFactory(
                                  'componentArray',
                                ),
                            },
                          },
                        },
                        subheading: {
                          type: 'text',
                          name: 'Subheading',
                          path: 'components.subheading',
                          inlineEditors: [
                            {
                              editorType: 'inlineText',
                              editorPath: 'components.subheading',
                              editorOptions: {
                                isNestedArrayItem: true,
                              },
                            },
                          ],
                        },
                        badge: {
                          type: 'container',
                          name: 'Badge',
                          path: 'components.badge',
                          components: {
                            text: {
                              type: 'text',
                              name: 'Text',
                              path: 'components.text',
                              inlineEditors: [
                                {
                                  editorType: 'inlineText',
                                  editorPath: 'components.text',
                                  editorOptions: {
                                    isNestedArrayItem: true,
                                  },
                                },
                              ],
                            },
                          },
                          configEditor: {
                            formProps: {
                              name: 'group',
                              registerValues:
                                containerComponentFormRegisterValuesFactory(
                                  'group',
                                ),
                            },
                          },
                        },
                        checkList: {
                          type: 'container',
                          name: 'Checklist',
                          path: 'components.checkList',
                          components: {
                            items: {
                              isArrayComponent: true,
                              path: 'items',
                              name: 'Feature',
                              type: 'container',
                              disableToggleShow: true,
                              sampleFactory: () => checkListItemSampleFactory(),
                              components: {
                                icon: {
                                  name: 'Icon',
                                  type: 'icon',
                                  path: 'components.icon',
                                },
                                text: {
                                  type: 'text',
                                  name: 'Text',
                                  path: 'components.text',
                                  inlineEditors: [
                                    {
                                      editorType: 'inlineText',
                                      editorPath: 'components.text',
                                      editorOptions: {
                                        isNestedArrayItem: true,
                                      },
                                    },
                                  ],
                                },
                              },
                              configEditor: {
                                formProps: {
                                  name: 'component',
                                  registerValues:
                                    containerComponentFormRegisterValuesFactory(
                                      'component',
                                    ),
                                },
                              },
                            },
                          },
                          configEditor: {
                            formProps: {
                              name: 'componentArray',
                              registerValues:
                                containerComponentFormRegisterValuesFactory(
                                  'componentArray',
                                ),
                            },
                          },
                        },
                      },
                    },
                  },
                },
              },
              configEditor: {
                formProps: {
                  name: 'componentArray',
                  registerValues:
                    containerComponentFormRegisterValuesFactory(
                      'componentArray',
                    ),
                },
              },
            },
          },
          configEditor: {
            formProps: {
              name: 'componentArray',
              registerValues:
                containerComponentFormRegisterValuesFactory('componentArray'),
            },
          },
        },
      },
      defaultConfig: {
        ...tabsDefaults.config,
        components: {
          tabsMenu: {
            ...tabsMenuDefaultConfig,
            items: [
              tabButtonSampleFactory({
                title: 'Pay monthly',
              }),
              tabButtonSampleFactory({ title: 'Pay yearly' }),
            ],
          },
          tabsContent: {
            ...tabsContentDefaultConfig,
            items: [
              tabPaneContentSampleFactory({
                items: [
                  pricingCardSampleFactory({
                    heading: '$15/mo',
                    showBadge: false,
                  }),
                  pricingCardSampleFactory({
                    heading: '$25/mo',
                    showBadge: true,
                  }),
                  pricingCardSampleFactory({
                    heading: '$35/mo',
                    showBadge: false,
                  }),
                ],
              }),
              tabPaneContentSampleFactory({
                items: [
                  pricingCardSampleFactory({
                    heading: '$150/yo',
                    showBadge: false,
                  }),
                  pricingCardSampleFactory({
                    heading: '$250/yo',
                    showBadge: true,
                  }),
                  pricingCardSampleFactory({
                    heading: '$350/yo',
                    showBadge: false,
                  }),
                ],
              }),
            ],
          },
        },
      },
      type: 'tabs',
    },
  }

export const pricingVariant1BlockMeta: BlockMeta<PricingVariant1Block> = {}

export const pricingVariant1DefaultConfig =
  blockDefaultConfigFactory<PricingVariant1Block>({
    componentMeta: pricingVariant1ComponentMeta,
    blockMeta: pricingVariant1BlockMeta,
    version: 1,
  })
