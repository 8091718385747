import { ComponentMeta } from '../componentCommonTypes'
import { componentDefaultConfigFactory } from '../helpers'

export const buttonComponentMeta: ComponentMeta<'button'> = {
  defaultConfig: {
    meta: {
      dataType: 'button-component',
    },
    font: {
      meta: {
        dataType: 'font-style',
      },
    },
    disabled: false,
    isAnimated: true,
    show: true,
    dataAttributes: {},
    link: {
      type: 'internal',
      href: '#',
      target: '_blank',
    },
  },
}

export const buttonDefaults = componentDefaultConfigFactory<'button'>({
  componentMeta: buttonComponentMeta,
})
