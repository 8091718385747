export const nestedArrayPathRegex = new RegExp(/\[\?id==[^\]]+\]\|\[\d+\]/)

export function convertArrayIdsJMESPathToArrayIndexesJMESPath(
  jmespath: string,
  data: Object,
) {
  // Regular expression to extract the path, id comparison, and array filter
  const regex = /(.*?)\[\?id=='([^'"]+)'\]\|\[\d+\]/g

  let match
  let currentData = data
  let newJmespath = jmespath

  // Function to resolve a JMESPath-like path to the actual value in the data object
  function resolvePath(path: string, obj: any) {
    const parts = path.split('.')
    return parts.reduce((prev, curr) => {
      if (prev && typeof prev === 'object') {
        return prev[curr]
      }
      return undefined
    }, obj)
  }

  // Process each part of the JMESPath with filters
  while ((match = regex.exec(jmespath)) !== null) {
    let pathToArray = match[1].trim() // Path to the array up to this point
    // remove leading dot
    if (pathToArray.startsWith('.')) {
      pathToArray = pathToArray.substring(1)
    }
    const idToMatch = match[2] // The id value to match

    // Resolve the object/array up to this point
    let resolvedPart = resolvePath(pathToArray, currentData)

    if (!Array.isArray(resolvedPart)) {
      throw new Error(
        `The resolved path "${pathToArray}" does not point to an array.`,
      )
    }

    // Find the index of the item in the array with the matching id
    const itemIndex = resolvedPart.findIndex((item) => item.id === idToMatch)

    if (itemIndex === -1) {
      throw new Error(
        `No item with id "${idToMatch}" found in the array at path "${pathToArray}".`,
      )
    }

    // Replace the id comparison with the found index in the JMESPath query
    newJmespath = newJmespath.replace(
      `[?id=='${idToMatch}']|[0]`,
      `[${itemIndex}]`,
    )

    // Move to the next nested structure based on the found item
    currentData = resolvedPart[itemIndex]
  }

  return newJmespath
}
