import { BreakpointType } from '../BreakpointDropdown/BreakpointDropdown'
import { StateType } from '../StateTabs/StateTabs'

export const resolveBorderValueString = (
  values: Record<string, any> = {},
  state: StateType,
  breakpoint: BreakpointType | 'default',
  valuesPath: string,
) => {
  const path = valuesPath ? `${valuesPath}.` : ''
  const borderTop =
    values[`${path}${state}.--border-${breakpoint}-top-style-${state}`] ||
    'none'
  const borderRight =
    values[`${path}${state}.--border-${breakpoint}-right-style-${state}`] ||
    'none'
  const borderLeft =
    values[`${path}${state}.--border-${breakpoint}-left-style-${state}`] ||
    'none'
  const borderBottom =
    values[`${path}${state}.--border-${breakpoint}-bottom-style-${state}`] ||
    'none'

  const result = []

  if (borderTop !== 'none') {
    result.push('t')
  }
  if (borderRight !== 'none') {
    result.push('r')
  }
  if (borderBottom !== 'none') {
    result.push('b')
  }
  if (borderLeft !== 'none') {
    result.push('l')
  }

  return result.sort().join('') || 'none'
}
