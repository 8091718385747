import { InferType, array, boolean, object, string } from 'yup'
import { cssLengthUnitSchema } from './common/cssLengthUnitSchema'
import { idSchema } from '../shared/idSchema'

const textShadowArrayItemSchema = object({
  x: cssLengthUnitSchema,
  y: cssLengthUnitSchema,
  blur: cssLengthUnitSchema,
  color: string(),
  id: idSchema.required(),
}).required()

const textShadowArraySchema = array().of(textShadowArrayItemSchema)

const textShadowResponsiveSchema = object({
  default: textShadowArraySchema,
  hover: textShadowArraySchema.optional(),
  active: textShadowArraySchema.optional(),
}).noUnknown()

export const textShadowResponsiveConfigSchema = object({
  default: textShadowResponsiveSchema,
  xs: textShadowResponsiveSchema.optional(),
  md: textShadowResponsiveSchema.optional(),
  lg: textShadowResponsiveSchema.optional(),
})
  .noUnknown()
  .required()

export const textShadowStyleSchema = object({
  data: object({
    responsiveConfig: textShadowResponsiveConfigSchema,
  }).noUnknown(),
  meta: object({
    dataType: string().oneOf(['text-shadow-style']).required(),
  })
    .noUnknown()
    .required(),
}).noUnknown()

export type TextShadowStyle = InferType<typeof textShadowStyleSchema>
export type TextShadowArrayItem = InferType<typeof textShadowArrayItemSchema>
