import { AsyncThunkAction, Dispatch } from '@reduxjs/toolkit'
import { StoreType } from '../store'

export class LocalHistoryCommand {
  thunkData: any
  pastState: any
  type: any // To satisfy redux AnyAction type the object must have a `type` property.

  preExecute(getState: StoreType['getState']) {
    throw new Error('Not Implemented')
  }

  execute():
    | AsyncThunkAction<any, any, any>
    | void
    | ((dispatch: Dispatch<any>) => void) {
    throw new Error('Not Implemented')
  }

  undo(
    getState: StoreType['getState'],
  ):
    | AsyncThunkAction<any, any, any>
    | void
    | ((dispatch: Dispatch<any>) => void) {
    throw new Error('Not Implemented')
  }
}
