import { StateType } from '../../atoms/StateTabs/StateTabs'
import { useFormContext } from '../../form/context/FormContext'
import { ScrollToViewHelper } from '../../helpers/ScrollToViewHelper'
import { DefaultShadowSettings } from './DefaultShadowSettings'
import { ResponsiveShadowSettings } from './ResponsiveShadowSettings'

export type RHFShadowSettingsPropType = {
  basePath?: string
  title?: string
  stateTabs?: {
    inheritedState?: StateType
    allowedInheritedStates?: StateType[]
    allowHover?: boolean
    allowActive?: boolean
    activeStateLabel?: string
  }
  className?: string
  shadowType: 'box' | 'text'
}

export const RHFShadowSettings = ({
  basePath,
  title = 'Shadow',
  className,
  stateTabs: {
    inheritedState,
    allowedInheritedStates = [],
    allowHover = false,
    allowActive = false,
    activeStateLabel = 'Active',
  } = {},
  shadowType,
}: RHFShadowSettingsPropType) => {
  const valuesPath = `${
    basePath ? basePath + '.data.responsiveConfig' : 'data.responsiveConfig'
  }`

  const { forceBreakpoint, excludedSettings } = useFormContext({
    snippetsType: 'SHADOW',
    metaPath: valuesPath,
    name: basePath,
  })

  if (excludedSettings.includes(`${shadowType}-shadow`)) {
    return null
  }

  if (forceBreakpoint) {
    return (
      <ResponsiveShadowSettings
        basePath={basePath}
        title={title}
        className={className}
        stateTabs={{
          inheritedState,
          allowHover,
          allowActive,
          activeStateLabel,
          allowedInheritedStates,
        }}
        shadowType={shadowType}
      />
    )
  }

  return (
    <>
      <ScrollToViewHelper type={shadowType} />
      <DefaultShadowSettings
        basePath={basePath}
        title={title}
        className={className}
        stateTabs={{
          inheritedState,
          allowHover,
          allowActive,
          activeStateLabel,
          allowedInheritedStates,
        }}
        shadowType={shadowType}
      />
    </>
  )
}
