import { InferType, bool, boolean, number, object, string } from 'yup'
import { componentEditorSchema } from '../shared/componentEditorSchema'
import { styleSchema } from '../shared/styleSchema'
import { borderStyleSchema } from '../styles/borderStyleSchema'
import { boxShadowStyleSchema } from '../styles/boxShadowStyleSchema'
import { colorStyleSchema } from '../styles/colorStyleSchema'
import { marginStyleSchema } from '../styles/marginStyleSchema'
import { paddingStyleSchema } from '../styles/paddingStyleSchema'
import { backgroundSchema } from './common/backgroundSchema'
import { iconComponentSchema } from './iconComponentSchema'
import { fontStyleSchema } from '../styles/fontStyleSchema'
import { backgroundColorStyleSchema } from '../styles/backgroundColorStyleSchema'

export const inputFieldComponentSchema = object({
  meta: object({
    dataType: string().oneOf(['input-field-component']).required(),
  })
    .noUnknown()
    .required(),
  id: string(),
  type: string()
    .oneOf([
      'text',
      'email',
      'tel',
      'textarea',
      // 'number',
      // 'password',
      // 'url',
      // 'tel',
      // 'date',
      // 'time',
      // 'select',
      // 'checkbox',
    ])
    .required(),
  components: object({
    icon: iconComponentSchema.optional(),
  }).noUnknown(),
  config: object({
    name: string(),
    maskedName: string(),
    placeholder: string(),
    required: bool(),
    minLength: string(),
    maxLength: string(),
    pattern: string(),
    rows: number(),
    cols: number(),
    defaultValue: string() || number() || bool(),
  }).noUnknown(),
  honeypot: boolean(),
  font: fontStyleSchema.required(),
  color: colorStyleSchema.optional(),
  backgroundColor: backgroundColorStyleSchema.optional(),
  placeholderColor: colorStyleSchema.optional(),
  margin: marginStyleSchema.optional(),
  padding: paddingStyleSchema.optional(),
  border: borderStyleSchema.optional(),
  boxShadow: boxShadowStyleSchema.optional(),
  background: backgroundSchema.optional(),
  style: styleSchema,
  className: string(),
  preview: bool(),
  editor: componentEditorSchema,
  show: bool(),
}).noUnknown()

export type InputFieldComponent = InferType<typeof inputFieldComponentSchema>

export type InputFieldComponentStyles = Pick<
  InputFieldComponent,
  | 'margin'
  | 'padding'
  | 'boxShadow'
  | 'border'
  | 'color'
  | 'backgroundColor'
  | 'placeholderColor'
  | 'font'
>
