import { InferType, array, bool, object } from 'yup'
import { blockComponentSchema } from '../../components/blockComponentSchema'
import { pricingCardComponentSchema } from '../../components/pricingCardComponentSchema'
import {
  tabPaneComponentSchema,
  tabsComponentSchema,
  tabsComponentsSchema,
  tabsContentComponentSchema,
} from '../../components/tabsComponentSchema'
import { textComponentSchema } from '../../components/textComponentSchema'
import { blockEditorSchema } from '../../shared/blockEditorSchema'
import { idSchema } from '../../shared/idSchema'
import { version } from '../../shared/versionSchema'
import { gridComponentSchema } from '../../components/gridComponentSchema'

const pricingTabPaneComponentSchema = tabPaneComponentSchema.shape({
  components: object({
    cards: gridComponentSchema
      .shape({
        items: array().of(
          pricingCardComponentSchema
            .shape({
              id: idSchema.required(),
            })
            .noUnknown(),
        ),
      })
      .noUnknown(),
  }),
})

export const pricingVariant1BlockSchema = object({
  id: idSchema,
  version: version(1),
  components: object({
    block: blockComponentSchema,
    tagline: textComponentSchema,
    heading: textComponentSchema,
    text: textComponentSchema,
    tabs: tabsComponentSchema.shape({
      components: tabsComponentsSchema.shape({
        tabsContent: tabsContentComponentSchema.shape({
          items: array().of(pricingTabPaneComponentSchema),
        }),
      }),
    }),
  }).noUnknown(),
  config: object().optional(),
  preview: bool(),
  editor: blockEditorSchema,
})
  .noUnknown()
  .required()

export type PricingVariant1Block = InferType<typeof pricingVariant1BlockSchema>
export type PricingTabPaneComponent = InferType<
  typeof pricingTabPaneComponentSchema
>
