import { ComponentMeta } from '../componentCommonTypes'
import { componentDefaultConfigFactory } from '../helpers'
import { ImageType } from './imageType'

export const imageComponentMeta: ComponentMeta<'image'> = {
  defaultConfig: {
    meta: {
      dataType: 'image-component',
    },
    dataAttributes: {},
    isAnimated: false,
    show: true,
  },
}

export const imageDefaults = componentDefaultConfigFactory<'image'>({
  componentMeta: imageComponentMeta,
})
