import { boolean, InferType, object, string } from 'yup'
import { styleSchema } from '../shared/styleSchema'
import { colorStyleSchema } from '../styles/colorStyleSchema'
import { gapStyleSchema } from '../styles/gapStyleSchema'
import { widthStyleSchema } from '../styles/widthStyleSchema'

const iconConfigSchema = object({
  provider: string().oneOf(['phosphor', 'scene']).required(),
  name: string().required(),
}).noUnknown()

const iconResponsiveSchema = object({
  default: iconConfigSchema,
  hover: iconConfigSchema.optional(),
  active: iconConfigSchema.optional(),
}).noUnknown()

export const iconResponsiveConfigSchema = object({
  default: iconResponsiveSchema,
  xs: iconResponsiveSchema.optional(),
  md: iconResponsiveSchema.optional(),
  lg: iconResponsiveSchema.optional(),
})
  .noUnknown()
  .required()

export const iconComponentSchema = object({
  meta: object({
    dataType: string().oneOf(['icon-component']).required(),
  })
    .noUnknown()
    .required(),
  responsiveConfig: iconResponsiveConfigSchema,
  mode: string().oneOf(['leading', 'trailing', 'only']),
  width: widthStyleSchema.optional(),
  gap: gapStyleSchema.optional(),
  color: colorStyleSchema.optional(),
  style: styleSchema,
  className: string(),
  show: boolean(),
}).noUnknown()

export type IconComponent = InferType<typeof iconComponentSchema>
export type IconConfigType = InferType<typeof iconConfigSchema>
export type IconComponentStyles = Pick<IconComponent, 'width' | 'gap' | 'color'>
