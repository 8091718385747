import _ from '../localization/translate'
import { PluginsType } from '../scripts/externalPluginsTypes'
import {
  BlockComponentMeta,
  BlockEditorMeta,
  BlockMeta,
} from './blockCommonTypes'

// Blocks
import Banner, { bannerPlugins } from './Hero/Banner/Banner'
import Media, { mediaPlugins } from './Media/Media'
import Text, { textPlugins } from './Text/Text'

import {
  bannerBlockMeta,
  bannerComponentMeta,
  bannerDefaultConfig,
} from './Hero/Banner/bannerMeta'
import {
  mediaBlockMeta,
  mediaComponentMeta,
  mediaDefaultConfig,
} from './Media/mediaMeta'
import {
  textBlockMeta,
  textComponentMeta,
  textDefaultConfig,
} from './Text/textMeta'
import {
  HeaderVariant1,
  headerVariant1Plugins,
} from './Header/HeaderVariant1/HeaderVariant1'

import {
  headerVariant1BlockMeta,
  headerVariant1ComponentMeta,
  headerVariant1DefaultConfig,
} from './Header/HeaderVariant1/headerVariant1Meta'
import {
  FooterVariant1,
  footerVariant1Plugins,
} from './Footer/FooterVariant1/FooterVariant1'

import {
  footerVariant1BlockMeta,
  footerVariant1ComponentMeta,
  footerVariant1DefaultConfig,
} from './Footer/FooterVariant1/footerVariant1Meta'
import BannerPreview from './Hero/Banner/BannerPreview'
import { HeaderVariant1Preview } from './Header/HeaderVariant1/HeaderVariant1Preview'
import { FooterVariant1Preview } from './Footer/FooterVariant1/FooterVariant1Preview'
import MediaPreview from './Media/MediaPreview'
import SplitHero, { splitHeroPlugins } from './Hero/SplitHero/SplitHero'
import SplitHeroPreview from './Hero/SplitHero/SplitHeroPreview'
import {
  splitHeroBlockMeta,
  splitHeroComponentMeta,
  splitHeroDefaultConfig,
} from './Hero/SplitHero/splitHeroMeta'
import TextPreview from './Text/TextPreview'

import LogoStrip, { logoStripPlugins } from './Logo/LogoStrip/LogoStrip'
import { LogoStripPreview } from './Logo/LogoStrip/LogoStripPreview'
import {
  logoStripBlockMeta,
  logoStripComponentMeta,
  logoStripDefaultConfig,
} from './Logo/LogoStrip/logoStripMeta'

import GalleryGrid, {
  galleryGridPlugins,
} from './Gallery/GalleryGrid/GalleryGrid'
import { GalleryGridPreview } from './Gallery/GalleryGrid/GalleryGridPreview'
import {
  galleryGridBlockMeta,
  galleryGridComponentMeta,
  galleryGridDefaultConfig,
} from './Gallery/GalleryGrid/galleryGridMeta'

import TestimonialVariant1, {
  testimonialVariant1Plugins,
} from './Testimonials/TestimonialVariant1/TestimonialVariant1'
import TestimonialVariant1Preview from './Testimonials/TestimonialVariant1/TestimonialVariant1Preview'
import {
  testimonialVariant1BlockMeta,
  testimonialVariant1ComponentMeta,
  testimonialVariant1DefaultConfig,
} from './Testimonials/TestimonialVariant1/testimonialVariant1Meta'

import FeatureGrid, {
  featureGridPlugins,
} from './Feature/FeatureGrid/FeatureGrid'
import {
  featureGridBlockMeta,
  featureGridComponentMeta,
  featureGridDefaultConfig,
} from './Feature/FeatureGrid/featureGridMeta'
import FeatureGridPreview from './Feature/FeatureGrid/FeatureGridPreview'

import Embed, { embedPlugins } from './Embed/Embed'
import {
  embedBlockMeta,
  embedComponentMeta,
  embedDefaultConfig,
} from './Embed/embedMeta'
import { EmbedPreview } from './Embed/EmbedPreview'

import FeatureGrid2, {
  featureGrid2Plugins,
} from './Feature/FeatureGrid2/FeatureGrid2'
import FeatureGrid2Preview from './Feature/FeatureGrid2/FeatureGrid2Preview'
import {
  featureGrid2BlockMeta,
  featureGrid2ComponentMeta,
  featureGrid2DefaultConfig,
} from './Feature/FeatureGrid2/featureGrid2Meta'

import { LogoStrip2Preview } from './Logo/LogoStrip2/LogoStrip2Preview'
import {
  logoStrip2BlockMeta,
  logoStrip2ComponentMeta,
  logoStrip2DefaultConfig,
} from './Logo/LogoStrip2/logoStrip2Meta'
import LogoStrip2, { logoStrip2Plugins } from './Logo/LogoStrip2/LogoStrip2'

import FAQ1, { fAQ1Plugins } from './FAQ/FAQ1/FAQ1'
import {
  fAQ1BlockMeta,
  fAQ1ComponentMeta,
  fAQ1DefaultConfig,
} from './FAQ/FAQ1/fAQ1Meta'
import FAQ1Preview from './FAQ/FAQ1/FAQ1Preview'

import SplitHero2, { splitHero2Plugins } from './Hero/SplitHero2/SplitHero2'
import {
  splitHero2BlockMeta,
  splitHero2ComponentMeta,
  splitHero2DefaultConfig,
} from './Hero/SplitHero2/splitHero2Meta'
import SplitHero2Preview from './Hero/SplitHero2/SplitHero2Preview'

import Timeline, { timelinePlugins } from './Timeline/Timeline'
import {
  timelineBlockMeta,
  timelineComponentMeta,
  timelineDefaultConfig,
} from './Timeline/timelineMeta'
import TimelinePreview from './Timeline/TimelinePreview'

import FAQ2, { fAQ2Plugins } from './FAQ/FAQ2/FAQ2'
import {
  fAQ2BlockMeta,
  fAQ2ComponentMeta,
  fAQ2DefaultConfig,
} from './FAQ/FAQ2/fAQ2Meta'
import FAQ2Preview from './FAQ/FAQ2/FAQ2Preview'

import FAQ3, { fAQ3Plugins } from './FAQ/FAQ3/FAQ3'
import {
  fAQ3BlockMeta,
  fAQ3ComponentMeta,
  fAQ3DefaultConfig,
} from './FAQ/FAQ3/fAQ3Meta'
import FAQ3Preview from './FAQ/FAQ3/FAQ3Preview'

import {
  subscriptionFormBlockMeta,
  subscriptionFormComponentMeta,
  subscriptionFormDefaultConfig,
} from './Form/SubscriptionForm/subscriptionFormMeta'
import SubscriptionForm, {
  subscriptionFormPlugins,
} from './Form/SubscriptionForm/SubscriptionForm'
import SubscriptionFormPreview from './Form/SubscriptionForm/SubscriptionFormPreview'

import ContactForm, { contactFormPlugins } from './Form/ContactForm/ContactForm'
import {
  contactFormBlockMeta,
  contactFormComponentMeta,
  contactFormDefaultConfig,
} from './Form/ContactForm/contactFormMeta'
import ContactFormPreview from './Form/ContactForm/ContactFormPreview'
import Buttons, { buttonsPlugins } from './Buttons/Buttons'
import {
  buttonsBlockMeta,
  buttonsComponentMeta,
  buttonsDefaultConfig,
} from './Buttons/buttonsMeta'
import ButtonsPreview from './Buttons/ButtonsPreview'
import HeaderVariant2, {
  headerVariant2Plugins,
} from './Header/HeaderVariant2/HeaderVariant2'
import {
  headerVariant2BlockMeta,
  headerVariant2ComponentMeta,
  headerVariant2DefaultConfig,
} from './Header/HeaderVariant2/headerVariant2Meta'
import { HeaderVariant2Preview } from './Header/HeaderVariant2/HeaderVariant2Preview'
import FooterVariant2, {
  footerVariant2Plugins,
} from './Footer/FooterVariant2/FooterVariant2'
import {
  footerVariant2BlockMeta,
  footerVariant2ComponentMeta,
  footerVariant2DefaultConfig,
} from './Footer/FooterVariant2/footerVariant2Meta'
import { FooterVariant2Preview } from './Footer/FooterVariant2/FooterVariant2Preview'
import FooterVariant3, {
  footerVariant3Plugins,
} from './Footer/FooterVariant3/FooterVariant3'
import {
  footerVariant3BlockMeta,
  footerVariant3ComponentMeta,
  footerVariant3DefaultConfig,
} from './Footer/FooterVariant3/footerVariant3Meta'
import { FooterVariant3Preview } from './Footer/FooterVariant3/FooterVariant3Preview'
import FeatureGrid3, {
  featureGrid3Plugins,
} from './Feature/FeatureGrid3/FeatureGrid3'
import {
  featureGrid3BlockMeta,
  featureGrid3ComponentMeta,
  featureGrid3DefaultConfig,
} from './Feature/FeatureGrid3/featureGrid3Meta'
import FeatureGrid3Preview from './Feature/FeatureGrid3/FeatureGrid3Preview'
import FeatureGrid4, {
  featureGrid4Plugins,
} from './Feature/FeatureGrid4/FeatureGrid4'
import {
  featureGrid4BlockMeta,
  featureGrid4ComponentMeta,
  featureGrid4DefaultConfig,
} from './Feature/FeatureGrid4/featureGrid4Meta'
import FeatureGrid4Preview from './Feature/FeatureGrid4/FeatureGrid4Preview'
import FeatureGrid5, {
  featureGrid5Plugins,
} from './Feature/FeatureGrid5/FeatureGrid5'
import {
  featureGrid5BlockMeta,
  featureGrid5ComponentMeta,
  featureGrid5DefaultConfig,
} from './Feature/FeatureGrid5/featureGrid5Meta'
import FeatureGrid5Preview from './Feature/FeatureGrid5/FeatureGrid5Preview'
import VideoHero, { videoHeroPlugins } from './Hero/VideoHero/VideoHero'
import {
  videoHeroBlockMeta,
  videoHeroComponentMeta,
  videoHeroDefaultConfig,
} from './Hero/VideoHero/videoHeroMeta'
import VideoHeroPreview from './Hero/VideoHero/VideoHeroPreview'
import ParallaxHero, {
  parallaxHeroPlugins,
} from './Hero/ParallaxHero/ParallaxHero'
import {
  parallaxHeroBlockMeta,
  parallaxHeroComponentMeta,
  parallaxHeroDefaultConfig,
} from './Hero/ParallaxHero/parallaxHeroMeta'
import ParallaxHeroPreview from './Hero/ParallaxHero/ParallaxHeroPreview'
import HeaderVariant3, {
  headerVariant3Plugins,
} from './Header/HeaderVariant3/HeaderVariant3'
import {
  headerVariant3BlockMeta,
  headerVariant3ComponentMeta,
  headerVariant3DefaultConfig,
} from './Header/HeaderVariant3/headerVariant3Meta'
import { HeaderVariant3Preview } from './Header/HeaderVariant3/HeaderVariant3Preview'
import Hero4, { hero4Plugins } from './Hero/Hero4/Hero4'
import {
  hero4BlockMeta,
  hero4ComponentMeta,
  hero4DefaultConfig,
} from './Hero/Hero4/hero4Meta'
import Hero4Preview from './Hero/Hero4/Hero4Preview'
import Hero5, { hero5Plugins } from './Hero/Hero5/Hero5'
import {
  hero5BlockMeta,
  hero5ComponentMeta,
  hero5DefaultConfig,
} from './Hero/Hero5/hero5Meta'
import Hero5Preview from './Hero/Hero5/Hero5Preview'
import HeroWithForm1, {
  heroWithForm1Plugins,
} from './Hero/HeroWithForm1/HeroWithForm1'
import {
  heroWithForm1BlockMeta,
  heroWithForm1ComponentMeta,
  heroWithForm1DefaultConfig,
} from './Hero/HeroWithForm1/heroWithForm1Meta'
import HeroWithForm1Preview from './Hero/HeroWithForm1/HeroWithForm1Preview'
import HeroWithForm2, {
  heroWithForm2Plugins,
} from './Hero/HeroWithForm2/HeroWithForm2'
import {
  heroWithForm2BlockMeta,
  heroWithForm2ComponentMeta,
  heroWithForm2DefaultConfig,
} from './Hero/HeroWithForm2/heroWithForm2Meta'
import HeroWithForm2Preview from './Hero/HeroWithForm2/HeroWithForm2Preview'
import {
  heroWithForm3BlockMeta,
  heroWithForm3ComponentMeta,
  heroWithForm3DefaultConfig,
} from './Hero/HeroWithForm3/heroWithForm3Meta'
import HeroWithForm3Preview from './Hero/HeroWithForm3/HeroWithForm3Preview'
import HeroWithForm3, {
  heroWithForm3Plugins,
} from './Hero/HeroWithForm3/HeroWithForm3'
import CTABanner1, { ctaBanner1Plugins } from './CTA/CTABanner1/CTABanner1'
import {
  ctaBanner1BlockMeta,
  ctaBanner1ComponentMeta,
  ctaBanner1DefaultConfig,
} from './CTA/CTABanner1/CTABanner1Meta'
import CTABanner1Preview from './CTA/CTABanner1/CTABanner1Preview'
import CTABanner2, { ctaBanner2Plugins } from './CTA/CTABanner2/CTABanner2'
import CTABanner2Preview from './CTA/CTABanner2/CTABanner2Preview'
import {
  ctaBanner2BlockMeta,
  ctaBanner2ComponentMeta,
  ctaBanner2DefaultConfig,
} from './CTA/CTABanner2/CTABanner2Meta'
import CTABanner3, { ctaBanner3Plugins } from './CTA/CTABanner3/CTABanner3'
import {
  ctaBanner3BlockMeta,
  ctaBanner3ComponentMeta,
  ctaBanner3DefaultConfig,
} from './CTA/CTABanner3/CTABanner3Meta'
import CTABanner3Preview from './CTA/CTABanner3/CTABanner3Preview'
import CTABanner4, { ctaBanner4Plugins } from './CTA/CTABanner4/CTABanner4'
import {
  ctaBanner4BlockMeta,
  ctaBanner4ComponentMeta,
  ctaBanner4DefaultConfig,
} from './CTA/CTABanner4/CTABanner4Meta'
import CTABanner4Preview from './CTA/CTABanner4/CTABanner4Preview'
import FeatureWithForm, {
  featureWithFormPlugins,
} from './Feature/FeatureWithForm/FeatureWithForm'
import {
  featureWithFormBlockMeta,
  featureWithFormComponentMeta,
  featureWithFormDefaultConfig,
} from './Feature/FeatureWithForm/featureWithFormMeta'
import FeatureWithFormPreview from './Feature/FeatureWithForm/FeatureWithFormPreview'
import HeroWithForm4, {
  heroWithForm4Plugins,
} from './Hero/HeroWithForm4/HeroWithForm4'
import HeroWithForm4Preview from './Hero/HeroWithForm4/HeroWithForm4Preview'
import {
  heroWithForm4BlockMeta,
  heroWithForm4ComponentMeta,
  heroWithForm4DefaultConfig,
} from './Hero/HeroWithForm4/heroWithForm4Meta'
import MasonryGallery, {
  masonryGalleryPlugins,
} from './Gallery/MasonryGallery/MasonryGallery'
import {
  masonryGalleryBlockMeta,
  masonryGalleryComponentMeta,
  masonryGalleryDefaultConfig,
} from './Gallery/MasonryGallery/masonryGalleryMeta'
import { MasonryGalleryPreview } from './Gallery/MasonryGallery/MasonryGalleryPreview'
import MasonryGallery2, {
  masonryGallery2Plugins,
} from './Gallery/MasonryGallery2/MasonryGallery2'
import {
  masonryGallery2BlockMeta,
  masonryGallery2ComponentMeta,
  masonryGallery2DefaultConfig,
} from './Gallery/MasonryGallery2/masonryGallery2Meta'
import { MasonryGallery2Preview } from './Gallery/MasonryGallery2/MasonryGallery2Preview'
import PricingVariant1, {
  pricingVariant1Plugins,
} from './Pricing/PricingVariant1/PricingVariant1'
import {
  pricingVariant1BlockMeta,
  pricingVariant1ComponentMeta,
  pricingVariant1DefaultConfig,
} from './Pricing/PricingVariant1/pricingVariant1Meta'
import PricingVariant1Preview from './Pricing/PricingVariant1/PricingVariant1Preview'

export type BlockDefinitionType = {
  component: any
  plugins?: PluginsType
  title?: string
  config?: (overrides?: any) => Record<string, any>
  editor?: {
    formMetaData?: BlockEditorMeta
    form?: any
  }
  meta: {
    component: BlockComponentMeta
    block: BlockMeta
  }
  thumbnail?: string | null
  category?: string
  id?: string
  type: string
  rootType?: string
  preview?: any
}

export default (): {
  [key: string]: BlockDefinitionType
} => ({
  text: {
    component: Text,
    plugins: textPlugins,
    title: _('text.block.title', 'Text'),
    meta: {
      component: textComponentMeta,
      block: textBlockMeta,
    },
    config: () => textDefaultConfig,
    thumbnail: 'https://assets.scene.io/global/thumbnails/blocks/text.png',
    category: _('elements.category', 'Elements'),
    id: 'text',
    type: 'text',
    preview: TextPreview,
  },
  buttons: {
    component: Buttons,
    plugins: buttonsPlugins,
    title: _('buttons.block.title', 'Button'),
    meta: {
      component: buttonsComponentMeta,
      block: buttonsBlockMeta,
    },
    config: () => buttonsDefaultConfig,
    thumbnail: 'https://assets.scene.io/global/thumbnails/blocks/buttons.png',
    category: _('elements.category', 'Elements'),
    type: 'buttons',
    id: 'buttons',
    preview: ButtonsPreview,
  },
  headerVariant1: {
    component: HeaderVariant1,
    plugins: headerVariant1Plugins,
    title: _('headerVariant1.block.title', 'Single-line header'),
    meta: {
      component: headerVariant1ComponentMeta,
      block: headerVariant1BlockMeta,
    },
    config: () => headerVariant1DefaultConfig,
    thumbnail:
      'https://assets.scene.io/global/thumbnails/blocks/header-variant-1.png',
    category: _('headers.category', 'Headers'),
    id: 'headerVariant1',
    type: 'headerVariant1',
    preview: HeaderVariant1Preview,
  },
  headerVariant2: {
    component: HeaderVariant2,
    plugins: headerVariant2Plugins,
    title: _('headerVariant2.block.title', 'Box header'),
    meta: {
      component: headerVariant2ComponentMeta,
      block: headerVariant2BlockMeta,
    },
    config: () => headerVariant2DefaultConfig,
    thumbnail:
      'https://assets.scene.io/global/thumbnails/blocks/header-variant-2.png',
    category: _('headers.category', 'Headers'),
    id: 'headerVariant2',
    type: 'headerVariant2',
    preview: HeaderVariant2Preview,
  },
  headerVariant3: {
    component: HeaderVariant3,
    plugins: headerVariant3Plugins,
    title: _('headerVariant3.block.title', 'Header 3'),
    meta: {
      component: headerVariant3ComponentMeta,
      block: headerVariant3BlockMeta,
    },
    config: () => headerVariant3DefaultConfig,
    thumbnail: 'https://assets.scene.io/global/thumbnails/blocks/header-3.png',
    category: _('headers.category', 'Headers'),
    id: 'headerVariant3',
    type: 'headerVariant3',
    preview: HeaderVariant3Preview,
  },
  footerVariant1: {
    component: FooterVariant1,
    plugins: footerVariant1Plugins,
    title: _('footerVariant1.block.title', 'Footer 1'),
    meta: {
      component: footerVariant1ComponentMeta,
      block: footerVariant1BlockMeta,
    },
    config: () => footerVariant1DefaultConfig,
    thumbnail:
      'https://assets.scene.io/global/thumbnails/blocks/footer-variant-1.png',
    category: _('footers.category', 'Footers'),
    id: 'footerVariant1',
    type: 'footerVariant1',
    preview: FooterVariant1Preview,
  },
  footerVariant2: {
    component: FooterVariant2,
    plugins: footerVariant2Plugins,
    title: _('footerVariant2.block.title', 'Footer 2'),
    meta: {
      component: footerVariant2ComponentMeta,
      block: footerVariant2BlockMeta,
    },
    config: () => footerVariant2DefaultConfig,
    thumbnail:
      'https://assets.scene.io/global/thumbnails/blocks/footer-variant-2.png',
    category: _('footers.category', 'Footers'),
    id: 'footerVariant2',
    type: 'footerVariant2',
    preview: FooterVariant2Preview,
  },
  footerVariant3: {
    component: FooterVariant3,
    plugins: footerVariant3Plugins,
    title: _('footerVariant3.block.title', 'Footer 3'),
    meta: {
      component: footerVariant3ComponentMeta,
      block: footerVariant3BlockMeta,
    },
    config: () => footerVariant3DefaultConfig,
    thumbnail:
      'https://assets.scene.io/global/thumbnails/blocks/footer-variant-3.png',
    category: _('footers.category', 'Footers'),
    id: 'footerVariant3',
    type: 'footerVariant3',
    preview: FooterVariant3Preview,
  },
  media: {
    component: Media,
    plugins: mediaPlugins,
    title: _('media.block.title', 'Media (Image/Video)'),
    meta: {
      component: mediaComponentMeta,
      block: mediaBlockMeta,
    },
    config: () => mediaDefaultConfig,
    thumbnail: 'https://assets.scene.io/global/thumbnails/blocks/media.png',
    category: _('elements.category', 'Elements'),
    id: 'media',
    type: 'media',
    preview: MediaPreview,
  },
  splitHero: {
    component: SplitHero,
    plugins: splitHeroPlugins,
    title: _('splitHero.block.title', 'Hero 1'),
    meta: {
      component: splitHeroComponentMeta,
      block: splitHeroBlockMeta,
    },
    config: () => splitHeroDefaultConfig,
    thumbnail:
      'https://assets.scene.io/global/thumbnails/blocks/split-hero.png',
    category: _('hero.category', 'Hero'),
    id: 'splitHero',
    type: 'splitHero',
    preview: SplitHeroPreview,
  },
  splitHero2: {
    component: SplitHero2,
    plugins: splitHero2Plugins,
    title: _('splitHero2.block.title', 'Hero 2'),
    meta: {
      component: splitHero2ComponentMeta,
      block: splitHero2BlockMeta,
    },
    config: () => splitHero2DefaultConfig,
    thumbnail:
      'https://assets.scene.io/global/thumbnails/blocks/split-hero-2.png',
    category: _('hero.category', 'Hero'),
    id: 'splitHero2',
    type: 'splitHero2',
    preview: SplitHero2Preview,
  },
  banner: {
    component: Banner,
    plugins: bannerPlugins,
    title: _('banner.block.title', 'Hero 3'),
    meta: {
      component: bannerComponentMeta,
      block: bannerBlockMeta,
    },
    config: () => bannerDefaultConfig,
    thumbnail: 'https://assets.scene.io/global/thumbnails/blocks/banner.png',
    category: _('hero.category', 'Hero'),
    id: 'banner',
    type: 'banner',
    preview: BannerPreview,
  },
  hero4: {
    component: Hero4,
    plugins: hero4Plugins,
    title: _('banner.block.title', 'Hero 4'),
    meta: {
      component: hero4ComponentMeta,
      block: hero4BlockMeta,
    },
    config: () => hero4DefaultConfig,
    thumbnail: 'https://assets.scene.io/global/thumbnails/blocks/hero-4.png',
    category: _('hero.category', 'Hero'),
    id: 'hero4',
    type: 'hero4',
    preview: Hero4Preview,
  },
  hero5: {
    component: Hero5,
    plugins: hero5Plugins,
    title: _('banner.block.title', 'Hero 5'),
    meta: {
      component: hero5ComponentMeta,
      block: hero5BlockMeta,
    },
    config: () => hero5DefaultConfig,
    thumbnail: 'https://assets.scene.io/global/thumbnails/blocks/hero-5.png',
    category: _('hero.category', 'Hero'),
    id: 'hero5',
    type: 'hero5',
    preview: Hero5Preview,
  },
  videoHero: {
    component: VideoHero,
    plugins: videoHeroPlugins,
    title: _('banner.block.title', 'Video Hero'),
    meta: {
      component: videoHeroComponentMeta,
      block: videoHeroBlockMeta,
    },
    config: () => videoHeroDefaultConfig,
    thumbnail:
      'https://assets.scene.io/global/thumbnails/blocks/video-hero.png',
    category: _('hero.category', 'Hero'),
    id: 'videoHero',
    type: 'videoHero',
    preview: VideoHeroPreview,
  },
  parallaxHero: {
    component: ParallaxHero,
    plugins: parallaxHeroPlugins,
    title: _('banner.block.title', 'Parallax Hero'),
    meta: {
      component: parallaxHeroComponentMeta,
      block: parallaxHeroBlockMeta,
    },
    config: () => parallaxHeroDefaultConfig,
    thumbnail:
      'https://assets.scene.io/global/thumbnails/blocks/parallax-hero.png',
    category: _('hero.category', 'Hero'),
    id: 'parallaxHero',
    type: 'parallaxHero',
    preview: ParallaxHeroPreview,
  },
  heroWithForm1: {
    component: HeroWithForm1,
    plugins: heroWithForm1Plugins,
    title: _('banner.block.title', 'Hero with form 1'),
    meta: {
      component: heroWithForm1ComponentMeta,
      block: heroWithForm1BlockMeta,
    },
    config: () => heroWithForm1DefaultConfig,
    thumbnail:
      'https://assets.scene.io/global/thumbnails/blocks/hero-with-form-1.png',
    category: _('hero.category', 'Hero'),
    id: 'heroWithForm1',
    type: 'heroWithForm1',
    preview: HeroWithForm1Preview,
  },
  heroWithForm2: {
    component: HeroWithForm2,
    plugins: heroWithForm2Plugins,
    title: _('banner.block.title', 'Hero with form 2'),
    meta: {
      component: heroWithForm2ComponentMeta,
      block: heroWithForm2BlockMeta,
    },
    config: () => heroWithForm2DefaultConfig,
    thumbnail:
      'https://assets.scene.io/global/thumbnails/blocks/hero-with-form-2.png',
    category: _('hero.category', 'Hero'),
    id: 'heroWithForm2',
    type: 'heroWithForm2',
    preview: HeroWithForm2Preview,
  },
  heroWithForm3: {
    component: HeroWithForm3,
    plugins: heroWithForm3Plugins,
    title: _('banner.block.title', 'Hero with form 3'),
    meta: {
      component: heroWithForm3ComponentMeta,
      block: heroWithForm3BlockMeta,
    },
    config: () => heroWithForm3DefaultConfig,
    thumbnail:
      'https://assets.scene.io/global/thumbnails/blocks/hero-with-form-3.png',
    category: _('hero.category', 'Hero'),
    id: 'heroWithForm3',
    type: 'heroWithForm3',
    preview: HeroWithForm3Preview,
  },
  heroWithForm4: {
    component: HeroWithForm4,
    plugins: heroWithForm4Plugins,
    title: _('banner.block.title', 'Hero with form 4'),
    meta: {
      component: heroWithForm4ComponentMeta,
      block: heroWithForm4BlockMeta,
    },
    config: () => heroWithForm4DefaultConfig,
    thumbnail:
      'https://assets.scene.io/global/thumbnails/blocks/hero-with-form-4.png',
    category: _('hero.category', 'Hero'),
    id: 'heroWithForm4',
    type: 'heroWithForm4',
    preview: HeroWithForm4Preview,
  },
  logoStrip: {
    component: LogoStrip,
    plugins: logoStripPlugins,
    title: _('logoStrip.block.title', 'Logo Strip'),
    meta: {
      component: logoStripComponentMeta,
      block: logoStripBlockMeta,
    },
    config: () => logoStripDefaultConfig,
    thumbnail:
      'https://assets.scene.io/global/thumbnails/blocks/logo-strip.png',
    category: _('logoStrip.category', 'Logos'),
    id: 'logoStrip',
    type: 'logoStrip',
    preview: LogoStripPreview,
  },
  logoStrip2: {
    component: LogoStrip2,
    plugins: logoStrip2Plugins,
    title: _('logoStrip2.block.title', 'Logo Strip 2'),
    meta: {
      component: logoStrip2ComponentMeta,
      block: logoStrip2BlockMeta,
    },
    config: () => logoStrip2DefaultConfig,
    thumbnail:
      'https://assets.scene.io/global/thumbnails/blocks/logo-strip-2.png',
    category: _('logoStrip.category', 'Logos'),
    id: 'logoStrip2',
    type: 'logoStrip2',
    preview: LogoStrip2Preview,
  },
  galleryGrid: {
    component: GalleryGrid,
    plugins: galleryGridPlugins,
    title: _('galleryGrid.block.title', 'Gallery'),
    meta: {
      component: galleryGridComponentMeta,
      block: galleryGridBlockMeta,
    },
    config: () => galleryGridDefaultConfig,
    thumbnail:
      'https://assets.scene.io/global/thumbnails/blocks/gallery-grid.png',
    category: _('galleryGrid.category', 'Galleries'),
    id: 'galleryGrid',
    type: 'galleryGrid',
    preview: GalleryGridPreview,
  },
  masonryGallery: {
    component: MasonryGallery,
    plugins: masonryGalleryPlugins,
    title: _('masonryGallery.block.title', 'Sequential Masonry Gallery'),
    meta: {
      component: masonryGalleryComponentMeta,
      block: masonryGalleryBlockMeta,
    },
    config: () => masonryGalleryDefaultConfig,
    thumbnail:
      'https://assets.scene.io/global/thumbnails/blocks/masonry-gallery.png',
    category: _('galleries.category', 'Galleries'),
    id: 'masonryGallery',
    type: 'masonryGallery',
    preview: MasonryGalleryPreview,
  },
  masonryGallery2: {
    component: MasonryGallery2,
    plugins: masonryGallery2Plugins,
    title: _('masonryGallery2.block.title', 'Masonry Gallery'),
    meta: {
      component: masonryGallery2ComponentMeta,
      block: masonryGallery2BlockMeta,
    },
    config: () => masonryGallery2DefaultConfig,
    thumbnail:
      'https://assets.scene.io/global/thumbnails/blocks/masonry-gallery-2.png',
    category: _('galleries.category', 'Galleries'),
    id: 'masonryGallery2',
    type: 'masonryGallery2',
    preview: MasonryGallery2Preview,
  },
  testimonialVariant1: {
    component: TestimonialVariant1,
    plugins: testimonialVariant1Plugins,
    title: _('testimonialVariant1.block.title', 'Testimonials'),
    meta: {
      component: testimonialVariant1ComponentMeta,
      block: testimonialVariant1BlockMeta,
    },
    config: () => testimonialVariant1DefaultConfig,
    thumbnail:
      'https://assets.scene.io/global/thumbnails/blocks/testimonial-1.png',
    category: _('testimonials.category', 'Testimonials'),
    id: 'testimonialVariant1',
    type: 'testimonialVariant1',
    preview: TestimonialVariant1Preview,
  },
  featureGrid: {
    component: FeatureGrid,
    plugins: featureGridPlugins,
    title: _('featureGrid.block.title', 'Feature 1'),
    meta: {
      component: featureGridComponentMeta,
      block: featureGridBlockMeta,
    },
    config: () => featureGridDefaultConfig,
    thumbnail:
      'https://assets.scene.io/global/thumbnails/blocks/feature-grid.png',
    category: _('feature.category', 'Feature'),
    id: 'featureGrid',
    type: 'featureGrid',
    preview: FeatureGridPreview,
  },
  featureGrid2: {
    component: FeatureGrid2,
    plugins: featureGrid2Plugins,
    title: _('featureGrid2.block.title', 'Feature 2'),
    meta: {
      component: featureGrid2ComponentMeta,
      block: featureGrid2BlockMeta,
    },
    config: () => featureGrid2DefaultConfig,
    thumbnail: 'https://assets.scene.io/global/thumbnails/blocks/feature-2.png',
    category: _('feature.category', 'Feature'),
    id: 'featureGrid2',
    type: 'featureGrid2',
    preview: FeatureGrid2Preview,
  },
  featureGrid3: {
    component: FeatureGrid3,
    plugins: featureGrid3Plugins,
    title: _('featureGrid3.block.title', 'Feature 3'),
    meta: {
      component: featureGrid3ComponentMeta,
      block: featureGrid3BlockMeta,
    },
    config: () => featureGrid3DefaultConfig,
    thumbnail:
      'https://assets.scene.io/global/thumbnails/blocks/feature-grid-3.png',
    category: _('feature.category', 'Feature'),
    id: 'featureGrid3',
    type: 'featureGrid3',
    preview: FeatureGrid3Preview,
  },
  featureGrid4: {
    component: FeatureGrid4,
    plugins: featureGrid4Plugins,
    title: _('featureGrid4.block.title', 'Feature 4'),
    meta: {
      component: featureGrid4ComponentMeta,
      block: featureGrid4BlockMeta,
    },
    config: () => featureGrid4DefaultConfig,
    thumbnail:
      'https://assets.scene.io/global/thumbnails/blocks/feature-grid-4.png',
    category: _('feature.category', 'Feature'),
    id: 'featureGrid4',
    type: 'featureGrid4',
    preview: FeatureGrid4Preview,
  },
  featureGrid5: {
    component: FeatureGrid5,
    plugins: featureGrid5Plugins,
    title: _('featureGrid5.block.title', 'Feature 5'),
    meta: {
      component: featureGrid5ComponentMeta,
      block: featureGrid5BlockMeta,
    },
    config: () => featureGrid5DefaultConfig,
    thumbnail:
      'https://assets.scene.io/global/thumbnails/blocks/feature-grid-5.png',
    category: _('feature.category', 'Feature'),
    id: 'featureGrid5',
    type: 'featureGrid5',
    preview: FeatureGrid5Preview,
  },
  featureWithForm: {
    component: FeatureWithForm,
    plugins: featureWithFormPlugins,
    title: _('featureWithForm.block.title', 'Feature 6'),
    meta: {
      component: featureWithFormComponentMeta,
      block: featureWithFormBlockMeta,
    },
    config: () => featureWithFormDefaultConfig,
    thumbnail:
      'https://assets.scene.io/global/thumbnails/blocks/feature-with-form.png',
    category: _('feature.category', 'Feature'),
    id: 'featureWithForm',
    type: 'featureWithForm',
    preview: FeatureWithFormPreview,
  },
  embed: {
    component: Embed,
    plugins: embedPlugins,
    title: _('embed.block.title', 'Embed'),
    meta: {
      component: embedComponentMeta,
      block: embedBlockMeta,
    },
    config: () => embedDefaultConfig,
    thumbnail: 'https://assets.scene.io/global/thumbnails/blocks/embed.png',
    category: _('embed.category', 'Embeds'),
    id: 'embed',
    type: 'embed',
    preview: EmbedPreview,
  },
  fAQ1: {
    component: FAQ1,
    plugins: fAQ1Plugins,
    title: _('fAQ1.block.title', 'FAQ 1'),
    meta: {
      component: fAQ1ComponentMeta,
      block: fAQ1BlockMeta,
    },
    config: () => fAQ1DefaultConfig,
    thumbnail: 'https://assets.scene.io/global/thumbnails/blocks/faq1.png',
    category: _('fAQs.category', 'FAQs'),
    id: 'fAQ1',
    type: 'fAQ1',
    preview: FAQ1Preview,
  },
  fAQ2: {
    component: FAQ2,
    plugins: fAQ2Plugins,
    title: _('fAQ2.block.title', 'FAQ 2'),
    meta: {
      component: fAQ2ComponentMeta,
      block: fAQ2BlockMeta,
    },
    config: () => fAQ2DefaultConfig,
    thumbnail: 'https://assets.scene.io/global/thumbnails/blocks/faq2.png',
    category: _('fAQs.category', 'FAQs'),
    id: 'fAQ2',
    type: 'fAQ2',
    preview: FAQ2Preview,
  },
  fAQ3: {
    component: FAQ3,
    plugins: fAQ3Plugins,
    title: _('fAQ3.block.title', 'FAQ 3'),
    meta: {
      component: fAQ3ComponentMeta,
      block: fAQ3BlockMeta,
    },
    config: () => fAQ3DefaultConfig,
    thumbnail: 'https://assets.scene.io/global/thumbnails/blocks/faq3.png',
    category: _('fAQs.category', 'FAQs'),
    id: 'fAQ3',
    type: 'fAQ3',
    preview: FAQ3Preview,
  },
  timeline: {
    component: Timeline,
    plugins: timelinePlugins,
    title: _('timeline.block.title', 'Timeline'),
    meta: {
      component: timelineComponentMeta,
      block: timelineBlockMeta,
    },
    config: () => timelineDefaultConfig,
    thumbnail: 'https://assets.scene.io/global/thumbnails/blocks/timeline.png',
    category: _('timeline.category', 'Timeline'),
    id: 'timeline',
    type: 'timeline',
    preview: TimelinePreview,
  },
  subscriptionForm: {
    component: SubscriptionForm,
    plugins: subscriptionFormPlugins,
    title: _('subscriptionForm.block.title', 'Email list'),
    meta: {
      component: subscriptionFormComponentMeta,
      block: subscriptionFormBlockMeta,
    },
    config: () => subscriptionFormDefaultConfig,
    thumbnail:
      'https://assets.scene.io/global/thumbnails/blocks/subscription-form.png',
    category: _('subscriptionForm.category', 'Forms'),
    id: 'subscriptionForm',
    type: 'subscriptionForm',
    preview: SubscriptionFormPreview,
  },
  contactForm: {
    component: ContactForm,
    plugins: contactFormPlugins,
    title: _('contactForm.block.title', 'Contact form'),
    meta: {
      component: contactFormComponentMeta,
      block: contactFormBlockMeta,
    },
    config: () => contactFormDefaultConfig,
    thumbnail:
      'https://assets.scene.io/global/thumbnails/blocks/contact-form.png',
    category: _('contactForm.category', 'Forms'),
    id: 'contactForm',
    type: 'contactForm',
    preview: ContactFormPreview,
  },
  ctaBanner1: {
    component: CTABanner1,
    plugins: ctaBanner1Plugins,
    title: _('ctaBanner2.block.title', 'CTA Banner 1'),
    meta: {
      component: ctaBanner1ComponentMeta,
      block: ctaBanner1BlockMeta,
    },
    config: () => ctaBanner1DefaultConfig,
    thumbnail:
      'https://assets.scene.io/global/thumbnails/blocks/cta-banner-1.png',
    category: _('ctaBanner1.category', 'CTAs'),
    id: 'ctaBanner1',
    type: 'ctaBanner1',
    preview: CTABanner1Preview,
  },
  ctaBanner2: {
    component: CTABanner2,
    plugins: ctaBanner2Plugins,
    title: _('ctaBanner2.block.title', 'CTA Banner 2'),
    meta: {
      component: ctaBanner2ComponentMeta,
      block: ctaBanner2BlockMeta,
    },
    config: () => ctaBanner2DefaultConfig,
    thumbnail:
      'https://assets.scene.io/global/thumbnails/blocks/cta-banner-2.png',
    category: _('ctaBanner2.category', 'CTAs'),
    id: 'ctaBanner2',
    type: 'ctaBanner2',
    preview: CTABanner2Preview,
  },
  ctaBanner3: {
    component: CTABanner3,
    plugins: ctaBanner3Plugins,
    title: _('ctaBanner3.block.title', 'CTA Banner 3'),
    meta: {
      component: ctaBanner3ComponentMeta,
      block: ctaBanner3BlockMeta,
    },
    config: () => ctaBanner3DefaultConfig,
    thumbnail:
      'https://assets.scene.io/global/thumbnails/blocks/cta-banner-3.png',
    category: _('ctaBanner3.category', 'CTAs'),
    id: 'ctaBanner3',
    type: 'ctaBanner3',
    preview: CTABanner3Preview,
  },
  ctaBanner4: {
    component: CTABanner4,
    plugins: ctaBanner4Plugins,
    title: _('ctaBanner4.block.title', 'CTA Banner 4'),
    meta: {
      component: ctaBanner4ComponentMeta,
      block: ctaBanner4BlockMeta,
    },
    config: () => ctaBanner4DefaultConfig,
    thumbnail:
      'https://assets.scene.io/global/thumbnails/blocks/cta-banner-4.png',
    category: _('ctaBanner4.category', 'CTAs'),
    id: 'ctaBanner4',
    type: 'ctaBanner4',
    preview: CTABanner4Preview,
  },
  pricingVariant1: {
    component: PricingVariant1,
    plugins: pricingVariant1Plugins,
    title: _('pricingVariant1.block.title', 'Pricing 1'),
    meta: {
      component: pricingVariant1ComponentMeta,
      block: pricingVariant1BlockMeta,
    },
    config: () => pricingVariant1DefaultConfig,
    thumbnail:
      'https://assets.scene.io/global/thumbnails/blocks/pricing-variant-1.png',
    category: _('pricingVariant1.category', 'Pricing'),
    id: 'pricingVariant1',
    type: 'pricingVariant1',
    preview: PricingVariant1Preview,
  },
})

export const categoryOrder: { [index: string]: number } = {
  Headers: 1,
  Hero: 2,
  Feature: 3,
  CTAs: 4,
  Grids: 5,
  Elements: 6,
  Embeds: 7,
  Pricing: 8,
  Forms: 9,
  Logos: 10,
  Galleries: 11,
  Testimonials: 12,
  FAQs: 13,
  Timeline: 14,
  Footers: 15,
}
