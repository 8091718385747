import React from 'react'
import { TabsMenuComponent } from '@sceneio/schemas/lib/components/tabsComponentSchema'
import clsx from 'clsx'
import { editorItemsControlsFactory } from '../../../tools/editor/editor'
import resolveComponentId from '../../../tools/helpers/resolveComponentId'
import Button from '../../Button/Button'
import { Container } from '../../Container/Container'
import { tabButtonSampleFactory } from '../tabsMeta'
import { isTabActiveInEditor } from './helpers'

function TabsMenu({
  editor,
  cid,
  show,
  defaultActive = 0,
  preview,
  ...tabsMenu
}: TabsMenuComponent) {
  if (!show) {
    return null
  }

  return (
    <div data-plugin-tabs-menu className="bui-d-flex">
      <Container
        className="bui-flex-wrap"
        editor={{
          ...editor,
          id: `${editor?.id}.tabsMenu`,
        }}
        {...tabsMenu}
        name="componentArray"
      >
        {tabsMenu?.items?.map(
          (
            {
              id: buttonId,
              childrenWrapProps = {},
              className: buttonClassname,
              ...restButtonProps
            },
            index,
          ) => {
            const defaultActiveIdx =
              tabsMenu?.items?.length &&
              defaultActive > tabsMenu?.items?.length - 1
                ? 0
                : defaultActive
            return (
              <Button
                editor={{
                  ...editor,
                  id: `${editor?.id}.tabsMenu.items[${index}]`,
                  wysiwyg: {
                    type: 'ITEMS',
                    ...editorItemsControlsFactory({
                      itemsArr: tabsMenu.items || [],
                      itemsId: `${editor?.id}.tabsMenu.items`,
                      itemFactory: tabButtonSampleFactory,
                      index: index,
                      onAddCloneExistingItem: false,
                      additionalItemsToAdd: [
                        {
                          path: `${editor?.id}.tabsContent.items`,
                          isArrayPath: true,
                          cloneExistingItem: true,
                        },
                      ],
                      additionalItemsToDelete: [
                        {
                          path: `${editor?.id}.tabsContent.items`,
                          isArrayPath: true,
                        },
                      ],
                    }),
                  },
                }}
                className={clsx(
                  'sc-tabs-menu__button',
                  {
                    active: preview
                      ? isTabActiveInEditor({
                          editor,
                          index,
                          tabsLength: tabsMenu?.items?.length || 0,
                        })
                      : defaultActiveIdx === index,
                  },
                  buttonClassname,
                )}
                childrenWrapProps={{
                  ...childrenWrapProps,
                  dataAttributes: {
                    'data-persistent-selection-id': editor?.id,
                  },
                }}
                key={buttonId}
                cid={resolveComponentId(cid, `button-${index}`)}
                dataAttributes={{
                  'data-plugin-tab-button': index,
                }}
                {...restButtonProps}
              />
            )
          },
        )}
      </Container>
    </div>
  )
}

export default TabsMenu
