import { BlockComponentMeta, BlockMeta } from '../../blockCommonTypes'
import { blockDefaultConfigFactory } from '../../helpers'
import type {
  HeroWithForm3Block,
  MediaComponent,
} from '@sceneio/schemas/lib/types'
import { DEFAULT_HEADINGS_CSS_VARIABLES } from '../../../components/Text/constants/globalTextCssVariables'
import { mediaDefaults } from '../../../components/Media/mediaMeta'
import { mergeDeepRight } from '@sceneio/tools'
import { textToLexical } from '@sceneio/wysiwyg-utils'
import {
  formDefaults,
  formFieldSampleFactory,
} from '../../../components/Form/formMeta'

const MEDIA_DEFAULTS = mediaDefaults.config
const FORM_DEFAULTS = formDefaults.config

export const heroWithForm3ComponentMeta: BlockComponentMeta<HeroWithForm3Block> =
  {
    block: {
      path: 'components.block',
      name: 'Block',
      type: 'block',
      defaultConfig: {
        meta: {
          dataType: 'block-component',
        },
        minHeight: {
          data: {
            '--min-height-default': '100vh',
          },
        },
        background: {
          responsiveConfig: {
            default: {
              image: {
                src: 'https://assets.scene.io/global/placeholders/media-1200x900.png',
              },
            },
          },
          fit: 'cover',
        },
        horizontalAlignment: {
          data: {
            '--horizontal-alignment-default': 'center',
          },
        },
        verticalAlignment: {
          data: {
            '--vertical-alignment-default': 'center',
          },
        },
      },
    },
    blockContainer: {
      path: 'components.block.blockContainer',
      name: 'Container',
      disableToggleShow: true,
      defaultConfig: {
        meta: {
          dataType: 'block-container-component',
        },
        responsiveConfig: {
          default: {
            maxWidth: '50%',
            rowGap: '1.5rem',
          },
          xs: {
            maxWidth: '100%',
          },
        },

        horizontalAlignment: {
          data: {
            '--horizontal-alignment-default': 'center',
          },
        },
        padding: {
          data: {
            '--padding-default-bottom': '3rem',
            '--padding-default-top': '3rem',
            '--padding-default-left': '1rem',
            '--padding-default-right': '1rem',
          },
        },
      },
      type: 'blockContainer',
    },
    heading: {
      path: 'components.heading',
      name: 'Heading',
      inlineEditors: [
        {
          editorType: 'inlineText',
          editorPath: 'components.heading',
        },
      ],
      defaultConfig: {
        meta: {
          dataType: 'text-component',
        },
        ...DEFAULT_HEADINGS_CSS_VARIABLES['h1'],
        children: textToLexical('Medium length hero headline goes here', 'h1'),
        horizontalAlignment: {
          data: {
            '--horizontal-alignment-default': 'center',
          },
        },
        as: 'h1',
        show: true,
      },
      type: 'text',
    },
    subheading: {
      path: 'components.subheading',
      name: 'Subheading',
      inlineEditors: [
        {
          editorType: 'inlineText',
          editorPath: 'components.subheading',
        },
      ],
      defaultConfig: {
        meta: {
          dataType: 'text-component',
        },
        font: {
          meta: {
            dataType: 'font-style',
          },
        },
        children: textToLexical('Subheading for a block'),
        horizontalAlignment: {
          data: {
            '--horizontal-alignment-default': 'center',
          },
        },
        as: 'p',
        show: false,
      },
      type: 'text',
    },
    text: {
      path: 'components.text',
      name: 'Text',
      inlineEditors: [
        {
          editorType: 'inlineText',
          editorPath: 'components.text',
          editorOptions: {
            include: ['table', 'list'],
          },
        },
      ],
      defaultConfig: {
        meta: {
          dataType: 'text-component',
        },
        font: {
          meta: {
            dataType: 'font-style',
          },
        },
        children: textToLexical(
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique. Duis cursus, mi quis viverra ornare, eros dolor interdum nulla, ut commodo diam libero vitae erat.',
        ),
        horizontalAlignment: {
          data: {
            '--horizontal-alignment-default': 'center',
          },
        },
        as: 'p',
        show: true,
      },
      type: 'text',
    },
    author: {
      path: 'components.author',
      inlineEditors: [
        {
          editorType: 'inlineText',
          editorPath: 'components.author',
        },
      ],
      defaultConfig: {
        meta: {
          dataType: 'text-component',
        },
        font: {
          meta: {
            dataType: 'font-style',
          },
        },
        children: textToLexical('Author Name'),
        horizontalAlignment: {
          data: {
            '--horizontal-alignment-default': 'center',
          },
        },
        as: 'p',
        show: false,
      },
      name: 'Author',
      type: 'text',
    },
    logo: {
      path: 'components.logo',
      name: 'Logo',
      defaultConfig: {
        meta: {
          dataType: 'image-component',
        },
        responsiveConfig: {
          default: {
            src: 'https://assets.scene.io/global/placeholders/logo-ipsum-vertical.svg',
            width: 170,
          },
        },
        show: false,
      },
      type: 'image',
    },
    background: {
      path: 'components.background',
      name: 'Media layer',
      components: {
        credits: {
          path: 'components.credits',
          inlineEditors: [
            {
              editorType: 'inlineText',
              editorPath: 'components.background.components.credits',
            },
          ],
          disableEditing: true,
          name: 'Credits',
          type: 'text',
        },
      },
      defaultConfig: {
        ...(mergeDeepRight(MEDIA_DEFAULTS, {
          components: {
            ...MEDIA_DEFAULTS.components,
            video: {
              isBackgroundVideo: true,
            },
          },
          show: false,
        }) as MediaComponent),
      },
      type: 'media',
    },
    form: {
      type: 'form',
      path: 'components.form',
      name: 'Form',
      disableToggleShow: true,
      components: {
        items: {
          isArrayComponent: true,
          path: 'items',
          name: 'Email field',
          type: 'formField',
          disableToggleShow: true,
          components: {
            label: {
              path: 'components.label',
              name: 'Label',
              type: 'text',
              inlineEditors: [
                {
                  editorType: 'inlineText',
                  editorPath: 'components.label',
                  editorOptions: {
                    isNestedArrayItem: true,
                  },
                },
              ],
            },
            field: {
              path: 'components.field',
              name: 'Input',
              type: 'input',
            },
            requiredValueError: {
              path: 'components.requiredValueError',
              name: 'Required value',
              type: 'text',
              inlineEditors: [
                {
                  editorType: 'inlineText',
                  editorPath: 'components.requiredValueError',
                  editorOptions: {
                    isNestedArrayItem: true,
                  },
                },
              ],
            },
            invalidPatternError: {
              path: 'components.invalidPatternError',
              name: 'Invalid pattern',
              type: 'text',
              inlineEditors: [
                {
                  editorType: 'inlineText',
                  editorPath: 'components.invalidPatternError',
                  editorOptions: {
                    isNestedArrayItem: true,
                  },
                },
              ],
            },
            minLengthError: {
              path: 'components.minLengthError',
              name: 'Min length',
              type: 'text',
              inlineEditors: [
                {
                  editorType: 'inlineText',
                  editorPath: 'components.minLengthError',
                  editorOptions: {
                    isNestedArrayItem: true,
                  },
                },
              ],
            },
            maxLengthError: {
              path: 'components.maxLengthError',
              name: 'Max length',
              type: 'text',
              inlineEditors: [
                {
                  editorType: 'inlineText',
                  editorPath: 'components.maxLengthError',
                  editorOptions: {
                    isNestedArrayItem: true,
                  },
                },
              ],
            },
          },
        },
        button: {
          path: 'components.button',
          name: 'Submit button',
          type: 'button',
          configEditor: {
            formProps: {
              excludedSettings: ['link'],
            },
          },
        },
        successMessage: {
          path: 'components.successMessage',
          name: 'Success message',
          type: 'text',
          inlineEditors: [
            {
              editorType: 'inlineText',
              editorPath: 'components.successMessage',
              editorOptions: {
                isNestedArrayItem: true,
              },
            },
          ],
        },
        errorMessage: {
          path: 'components.errorMessage',
          name: 'Error message',
          type: 'text',
          inlineEditors: [
            {
              editorType: 'inlineText',
              editorPath: 'components.errorMessage',
              editorOptions: {
                isNestedArrayItem: true,
              },
            },
          ],
        },
      },
      defaultConfig: {
        ...FORM_DEFAULTS,
        items: [
          formFieldSampleFactory({
            name: 'email',
            type: 'email',
            showLabel: false,
            placeholder: 'Enter your email',
            label: 'Email',
          }),
        ],
        flow: {
          data: {
            '--flow-default': 'row',
            '--flow-xs': 'column',
          },
        },
        components: {
          ...FORM_DEFAULTS.components,
          button: {
            ...FORM_DEFAULTS.components.button,
            children: textToLexical('Join waitlist'),
            font: {
              meta: {
                dataType: 'font-style',
              },
              data: {
                default: {
                  ...FORM_DEFAULTS.components.button.font?.data?.default,
                  '--font-default-weight-default': 600,
                },
              },
            },
          },
          successMessage: {
            ...FORM_DEFAULTS.components.successMessage,
            horizontalAlignment: {
              data: {
                '--horizontal-alignment-default': 'center',
              },
            },
          },
          errorMessage: {
            ...FORM_DEFAULTS.components.errorMessage,
            children: textToLexical(
              'Something went wrong, and we couldn’t submit your email. Please try again.',
            ),
            horizontalAlignment: {
              data: {
                '--horizontal-alignment-default': 'center',
              },
            },
          },
        },
      },
    },
  }

export const heroWithForm3BlockMeta: BlockMeta<HeroWithForm3Block> = {
  defaultConfig: {},
  configEditor: {},
}

export const heroWithForm3DefaultConfig =
  blockDefaultConfigFactory<HeroWithForm3Block>({
    componentMeta: heroWithForm3ComponentMeta,
    blockMeta: heroWithForm3BlockMeta,
    version: 1,
  })
