import { ComponentMeta } from '../componentCommonTypes'
import { TextType } from './textType'
import { componentDefaultConfigFactory } from '../helpers'

export const textComponentMeta: ComponentMeta<'text'> = {
  defaultConfig: {
    meta: {
      dataType: 'text-component',
    },
    font: {
      meta: {
        dataType: 'font-style',
      },
    },
    className: '',
    as: 'div',
    link: {
      type: 'internal',
      href: '',
      target: '_self',
    },
    dataAttributes: {},
    isAnimated: true,
    ariaAttributes: {},
    shouldHyphenate: false,
    show: true,
  },
}

export const textDefaults = componentDefaultConfigFactory<'text'>({
  componentMeta: textComponentMeta,
})
