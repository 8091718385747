import { TextBlock, TextComponent } from '@sceneio/schemas/lib/types'
import { randomString } from '@sceneio/tools'
import { BlockComponentMeta, BlockMeta } from '../blockCommonTypes'
import { blockDefaultConfigFactory } from '../helpers'
import { blockContainerLayoutAdditionalFormFieldRenderer } from './form/additionalFormFieldRenderers'
import { DEFAULT_HEADINGS_CSS_VARIABLES } from '../../components/Text/constants/globalTextCssVariables'
import { blockContainerDefaults } from '../../components/BlockContainer/blockContainerMeta'
import { textToLexical } from '@sceneio/wysiwyg-utils'

const BLOCK_CONTAINER_DEFAULTS = blockContainerDefaults.config

const textContentSampleFactory = (): TextComponent => ({
  id: randomString(8),
  meta: {
    dataType: 'text-component',
  },
  children: textToLexical(
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique. Duis cursus, mi quis viverra ornare.',
  ),
  ...DEFAULT_HEADINGS_CSS_VARIABLES['h4'],
  horizontalAlignment: {
    data: {
      '--horizontal-alignment-default': 'center',
    },
  },
  as: 'h4',
})

export const textComponentMeta: BlockComponentMeta<TextBlock> = {
  block: {
    path: 'components.block',
    name: 'Block',
    type: 'block',
    defaultConfig: {
      meta: {
        dataType: 'block-component',
      },
      horizontalAlignment: {
        data: {
          '--horizontal-alignment-default': 'center',
        },
      },
    },
  },
  blockContainer: {
    path: 'components.block.blockContainer',
    name: 'Container',
    disableToggleShow: true,
    defaultConfig: {
      meta: {
        dataType: 'block-container-component',
      },
      responsiveConfig: {
        default: {
          maxWidth: 'screen',
          rowGap: '1.5rem',
          columnGap: '1.5rem',
        },
      },
      padding: BLOCK_CONTAINER_DEFAULTS.padding,
      direction: 'vertical',
    },
    configEditor: {
      additionalFormFields: {
        layout: {
          formFieldRenderer: blockContainerLayoutAdditionalFormFieldRenderer,
        },
      },
    },
    type: 'blockContainer',
  },
  content: {
    path: 'components.content',
    components: {
      items: {
        isArrayComponent: true,
        path: 'items',
        name: 'Text',
        type: 'text',
        disableToggleShow: true,
        sampleFactory: textContentSampleFactory,
      },
    },
    disableToggleShow: true,
    inlineEditors: [
      {
        editorType: 'inlineText',
        editorPath: 'components.content.items',
        editorOptions: {
          isItemArray: true,
          include: ['table', 'list'],
        },
      },
    ],
    configEditor: {
      disable: true,
    },
    defaultConfig: {
      meta: {
        dataType: 'container-component',
      },
      items: [textContentSampleFactory()],
    },
    name: 'Content',
    type: 'container',
  },
}

export const textBlockMeta: BlockMeta<TextBlock> = {}

export const textDefaultConfig = blockDefaultConfigFactory<TextBlock>({
  componentMeta: textComponentMeta,
  blockMeta: textBlockMeta,
  version: 1,
})
