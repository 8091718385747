import { InferType, array, bool, object, string } from 'yup'
import { marginStyleSchema } from '../styles/marginStyleSchema'
import { paddingStyleSchema } from '../styles/paddingStyleSchema'
import { borderStyleSchema } from '../styles/borderStyleSchema'
import { boxShadowStyleSchema } from '../styles/boxShadowStyleSchema'
import { horizontalAlignmentFlexStyleSchema } from '../styles/horizontalAlignmentFlexStyleSchema'
import { verticalAlignmentStyleSchema } from '../styles/verticalAlignmentStyleSchema'
import { styleSchema } from '../shared/styleSchema'
import { gapStyleSchema } from '../styles/gapStyleSchema'
import { componentEditorSchema } from '../shared/componentEditorSchema'
import { mediaComponentSchema } from './mediaComponentSchema'
import { containerComponentSchema } from './containerComponentSchema'
import { textComponentSchema } from './textComponentSchema'
import { buttonComponentSchema } from './buttonComponentSchema'
import { idSchema } from '../shared/idSchema'
import { backgroundSchema } from './common/backgroundSchema'
import { iconComponentSchema } from './iconComponentSchema'

const checkListItemComponentSchema = containerComponentSchema
  .shape({
    id: idSchema.required(),
    components: object({
      icon: iconComponentSchema,
      text: textComponentSchema,
    }).noUnknown(),
  })
  .noUnknown()

export const pricingCardComponentSchema = object({
  meta: object({
    dataType: string().oneOf(['pricing-card-component']).required(),
  })
    .noUnknown()
    .required(),
  direction: string().oneOf(['vertical', 'horizontal']),
  swapOrder: bool(),
  components: object({
    media: mediaComponentSchema,
    badge: containerComponentSchema
      .shape({ components: object({ text: textComponentSchema }).noUnknown() })
      .noUnknown(),
    contentContainer: containerComponentSchema,
    tagline: textComponentSchema,
    heading: textComponentSchema,
    description: textComponentSchema,
    buttons: containerComponentSchema
      .shape({
        items: array().of(
          buttonComponentSchema
            .shape({
              id: idSchema.required(),
            })
            .noUnknown(),
        ),
      })
      .noUnknown(),
    subheading: textComponentSchema,
    checkList: containerComponentSchema
      .shape({
        items: array().of(checkListItemComponentSchema),
      })
      .noUnknown(),
  }).noUnknown(),
  className: string(),
  margin: marginStyleSchema.optional(),
  padding: paddingStyleSchema.optional(),
  border: borderStyleSchema.optional(),
  boxShadow: boxShadowStyleSchema.optional(),
  background: backgroundSchema.optional(),
  horizontalAlignment: horizontalAlignmentFlexStyleSchema.optional(),
  verticalAlignment: verticalAlignmentStyleSchema.optional(),
  gap: gapStyleSchema.optional(),
  show: bool(),
  style: styleSchema,
  editor: componentEditorSchema,
  cid: string(),
  id: string(),
  preview: bool(),
}).noUnknown()

export type PricingCardComponent = InferType<typeof pricingCardComponentSchema>

export type CheckListItemComponent = InferType<
  typeof checkListItemComponentSchema
>

export type PricingCardComponentStyles = Pick<
  PricingCardComponent,
  | 'margin'
  | 'padding'
  | 'boxShadow'
  | 'horizontalAlignment'
  | 'verticalAlignment'
  | 'border'
  | 'gap'
>
