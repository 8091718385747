import { ReferencesType } from '@sceneio/referencing-tools/lib/referencesTypes'
import { SnippetType, SnippetConfigType } from './snippetsTypes'
import { changeObjectPropertyByJmespath, deepClone } from '@sceneio/tools'
import { referencesCleanup } from '@sceneio/referencing-tools'
import { ALLOWED_SNIPPET_FIELDS_BY_SNIPPET_TYPE } from '@sceneio/config-snippets'

const MAX_DEPTH_LEVEL = 10

export type MaterializeSnippetDataType = {
  references: ReferencesType
  [key: string]: any
}

export const materializeSnippets = ({
  data,
  snippets,
  depthLevel = 0,
}: {
  snippets: SnippetType[]
  data: MaterializeSnippetDataType
  depthLevel?: number
}): Record<string, any> => {
  if (depthLevel > MAX_DEPTH_LEVEL) {
    throw Error('[materializeSnippets]: Max depth level reached')
  }
  if (!data?.references) {
    return data
  }

  const originData = deepClone(data)

  const cleanedReferences = referencesCleanup({
    references: originData.references,
    data: originData,
  })

  return cleanedReferences.reduce((acc, reference) => {
    // skip if irrelevant reference
    if (reference.typeTo !== 'SNIPPET') {
      return acc
    }

    const snippet = snippets.find((snip) => snip.id === reference.idTo)

    // skip if snippet not found
    if (!snippet) {
      return acc
    }

    const snippetType = snippet?.type as SnippetConfigType

    // recursive materialisation of snippet
    const materialisedSnippet = snippet?.references?.length
      ? materializeSnippets({
          data: snippet,
          snippets,
          depthLevel: depthLevel + 1,
        })
      : snippet

    return changeObjectPropertyByJmespath(
      originData,
      reference.pathFrom,
      materialisedSnippet.data.value,
      {
        shouldMergeValue: reference.resolveMethod === 'MERGE',
        snippetAllowedFields:
          ALLOWED_SNIPPET_FIELDS_BY_SNIPPET_TYPE[snippetType],
      },
    )
  }, originData)
}
