import { InferType, array, bool, object, string } from 'yup'
import { styleSchema } from '../shared/styleSchema'
import { marginStyleSchema } from '../styles/marginStyleSchema'
import { paddingStyleSchema } from '../styles/paddingStyleSchema'
import { borderStyleSchema } from '../styles/borderStyleSchema'
import { boxShadowStyleSchema } from '../styles/boxShadowStyleSchema'
import { backgroundSchema } from './common/backgroundSchema'
import { componentEditorSchema } from '../shared/componentEditorSchema'
import { textComponentSchema } from './textComponentSchema'
import { formFieldComponentSchema } from './formFieldComponentSchema'
import { idSchema } from '../shared/idSchema'
import { buttonComponentSchema } from './buttonComponentSchema'
import { flowStyleSchema } from '../styles/flowStyleSchema'
import { gapStyleSchema } from '../styles/gapStyleSchema'
import { verticalAlignmentStyleSchema } from '../styles/verticalAlignmentStyleSchema'
import { horizontalAlignmentFlexStyleSchema } from '../styles/horizontalAlignmentFlexStyleSchema'

export const formComponentSchema = object({
  meta: object({
    dataType: string().oneOf(['form-component']).required(),
  })
    .noUnknown()
    .required(),
  settings: object({
    provider: string().oneOf(['formspark']).required(),
    formId: string(),
  }).noUnknown(),
  items: array()
    .of(
      formFieldComponentSchema
        .shape({
          id: idSchema.required(),
        })
        .noUnknown(),
    )
    .required(),
  components: object({
    button: buttonComponentSchema,
    successMessage: textComponentSchema,
    errorMessage: textComponentSchema,
  }).noUnknown(),
  flow: flowStyleSchema.optional(),
  gap: gapStyleSchema.optional(),
  horizontalAlignment: horizontalAlignmentFlexStyleSchema.optional(),
  verticalAlignment: verticalAlignmentStyleSchema.optional(),
  margin: marginStyleSchema.optional(),
  padding: paddingStyleSchema.optional(),
  border: borderStyleSchema.optional(),
  boxShadow: boxShadowStyleSchema.optional(),
  background: backgroundSchema.optional(),
  style: styleSchema,
  className: string(),
  preview: bool(),
  editor: componentEditorSchema,
  show: bool(),
  cid: string(),
}).noUnknown()

export type FormComponent = InferType<typeof formComponentSchema>

export type FormComponentStyles = Pick<
  FormComponent,
  | 'margin'
  | 'padding'
  | 'boxShadow'
  | 'border'
  | 'horizontalAlignment'
  | 'verticalAlignment'
  | 'gap'
  | 'flow'
>
