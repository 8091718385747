import React, {
  CSSProperties,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from 'react'
import ColorPicker, { useColorPicker } from 'react-best-gradient-color-picker'

import {
  useFloating,
  autoUpdate,
  offset,
  shift,
  flip,
} from '@floating-ui/react-dom'
import { useOnClickOutside } from '@sceneio/hooks/lib/useOnClickOutside'
import { clsx } from 'clsx'
import * as Form from '@radix-ui/react-form'
import { ColorInput } from './components/ColorInput'
import { debounce, stringify } from '@sceneio/tools'
import { ClearButton } from '../ClearButton/ClearButton'
import { createPortal } from 'react-dom'
import { SnippetsDropdown } from '../SnippetsDropdown/SnippetsDropdown'
import { SnippetColor } from './components/SnippetColor'
import { SaveButton } from '../SaveButton/SaveButton'
import { useFormContext } from '../../form/context/FormContext'
import { InvalidReferenceIcon } from '../../components/InvalidReferenceIcon/InvalidReferenceIcon'

const DEFAULT_COLOR = '#000000'

export type RHFColorPickerPropType = {
  label?: ReactNode | string
  name: string
  onChange?: (arg0: string) => void
  className?: string
  style?: CSSProperties
  hidden?: boolean
  inputClassName?: string
  allowGradient?: boolean
  width?: number
  height?: number
  value?: string
  isClearable?: boolean
  allowSnippets?: boolean
}

const getRecentlyUsedColors = (key: string): string[] => {
  const recentlyUsedColors =
    localStorage.getItem(key) || stringify(Array(18).fill('#dadada'))
  return JSON.parse(recentlyUsedColors)
}

const setRecentlyUsedColors = (color: string, key: string) => {
  if (color) {
    const recentlyUsedColors = getRecentlyUsedColors(key)
    let currentColors = recentlyUsedColors
    currentColors.splice(-1)
    currentColors = [color, ...currentColors]

    const uniqColors = currentColors.filter((value, index, array) => {
      return array.indexOf(value) === index
    })
    const resultColors = [
      ...uniqColors,
      ...Array(18 - uniqColors.length).fill('#dadada'),
    ]
    localStorage.setItem(key, stringify(resultColors))
  }
}

export const RHFColorPicker = ({
  name,
  hidden,
  style,
  inputClassName,
  className,
  label,
  allowGradient = true,
  width = 240,
  height = 240,
  value,
  onChange,
  isClearable = false,
  allowSnippets = true,
}: RHFColorPickerPropType) => {
  const {
    setValue,
    placeholders,
    values,
    onCreateSnippet,
    onDetachSnippet,
    onDeleteSnippet,
    onAssignSnippet,
    onIssueSnippet,
    allowSaveSnippet,
    onDiscardSnippet,
    parentSnippet,
    meta,
    forceBreakpoint,
    disabledSnippetActions,
  } = useFormContext({
    snippetsType: 'ATOMIC_COLOR',
    metaPath: name,
    name,
  })
  const [editSnippetMode, setEditSnippetMode] = useState(false)
  const isSnippet = Object.keys(meta?.snippet || {}).length > 0
  const hasSnippetUnpublishedChanges = meta?.snippet?.hasUnpublishedChanges
  const snippetId = meta?.snippet?.id
  const isInvalidReference = meta?.invalidReference

  const [color, setColor] = useState(
    value || values[name] || placeholders[name] || DEFAULT_COLOR,
  )
  const [showColorPicker, setShowColorPicker] = useState(false)
  const recentlyUsedColorsKey = `colorPicker:usedColors`

  const recentlyUsedColorsArray = getRecentlyUsedColors(recentlyUsedColorsKey)

  const handleSubmit = useCallback(
    debounce((color: string) => {
      if (onChange) {
        onChange(color)
      } else {
        setValue(name, color, {
          customData: {
            parentSnippetId: snippetId,
            parentSnippetPath: meta?.snippet?.snippetPath,
          },
        })
      }
      setRecentlyUsedColors(color, recentlyUsedColorsKey)
    }, 250),
    [onChange, setValue, snippetId],
  ) as (value: string) => void

  const handleColorChange = (color: string) => {
    setColor(color)
    handleSubmit(color)
  }

  useEffect(() => {
    if (value) {
      setColor(value)
    }
  }, [value])

  useEffect(() => {
    if (!value) {
      setColor(values[name] || placeholders[name] || DEFAULT_COLOR)
    }
  }, [stringify({ values: values, placeholders })])

  const { valueToHex, isGradient, previousColors, rgbaArr, setA } =
    useColorPicker(color, handleColorChange)

  const { refs, floatingStyles } = useFloating({
    whileElementsMounted: autoUpdate,
    placement: 'left',
    middleware: [offset(30), flip(), shift()],
  })

  const containerClasses = clsx(
    'sc-rhf-color-picker tw-container tw-text-xs',
    {
      'tw-hidden': hidden,
    },
    className,
  )

  useOnClickOutside(
    (e: Event) => {
      if (showColorPicker) {
        // fix due to unsupported EyeDropper API on safari & mozilla browsers
        if (e?.target instanceof HTMLElement) {
          // can't be part of ignoreClass array due to dynamic generated class
          const isCustomEyeDropper = e?.target?.className?.startsWith(
            'rbgcpEyedropperCover',
          )
          if (isCustomEyeDropper) {
            return
          }
        }
        setShowColorPicker(false)
      }
    },
    {
      refs: [refs.floating],
      ignoreClass: 'sc-rhf-color-picker__modal',
    },
  )

  const showActionButtons =
    !forceBreakpoint || (forceBreakpoint && name.includes(forceBreakpoint))

  const onAssignSnippetCallback = (
    id: string,
    options?: { shouldReplace?: boolean },
  ) => {
    setEditSnippetMode(false)
    onAssignSnippet({
      id,
      fieldName: name,
      parentSnippetId:
        (options?.shouldReplace ? undefined : snippetId) || parentSnippet?.id,
      parentSnippetPath: parentSnippet?.path,
    })
  }

  const onDetachSnippetCallback = () => {
    onDetachSnippet({
      id: snippetId,
      fieldName: name,
      parentSnippetId: parentSnippet?.id,
      parentSnippetPath: parentSnippet?.path,
    })
  }

  return (
    <Form.Field className={containerClasses} style={style} name={name}>
      <Form.Label
        className={clsx(
          'tw-flex tw-flex-col tw-gap-1 tw-bg-form-field-bg tw-rounded tw-p-1 tw-relative tw-group hover:tw-outline hover:tw-outline-1 hover:tw-outline-offset-[-1px] hover:tw-outline-border-color focus-within:tw-outline-border-color focus-within:tw-outline-1 focus-within:tw-outline-offset-[-1px] focus-within:tw-outline',
          {
            'tw-bg-white': forceBreakpoint && !name.includes(forceBreakpoint),
          },
        )}
      >
        {label && (
          <div className={clsx('tw-p-1 tw-text-label-color')}>{label}</div>
        )}
        {isSnippet && (
          <SnippetColor
            color={color}
            snippetName={meta?.snippet?.name}
            onAssignSnippet={onAssignSnippetCallback}
            onDetachSnippet={
              !disabledSnippetActions?.includes('detach')
                ? onDetachSnippetCallback
                : undefined
            }
            onEditClick={() => setEditSnippetMode(!editSnippetMode)}
            onResetClick={() => {
              setEditSnippetMode(false)
              onDiscardSnippet({ id: snippetId })
            }}
            hasOverrides={hasSnippetUnpublishedChanges}
            editSnippetMode={editSnippetMode}
            snippetId={snippetId}
            showActionButtons={showActionButtons}
          />
        )}
        {(!isSnippet || editSnippetMode || hasSnippetUnpublishedChanges) && (
          <div className="tw-flex tw-items-center tw-justify-between tw-rounded tw-px-1">
            <div className="tw-flex tw-items-center tw-flex-1 tw-min-w-0 tw-py-1">
              {isInvalidReference && (
                <InvalidReferenceIcon className={'tw-mr-2'} />
              )}
              <div
                ref={refs.setReference}
                className="sc-rhf-color-picker__preview tw-inline-block tw-relative tw-shrink-0 tw-w-4 tw-h-4 tw-mr-1 tw-rounded tw-border tw-border-border-color"
                onClick={() => {
                  if (!showColorPicker) {
                    setShowColorPicker(true)
                  }
                }}
              >
                <div
                  className="sc-rhf-color-picker__color tw-absolute tw-w-full tw-h-full tw-top-0 tw-left-0 tw-z-[2] tw-rounded-[3px]"
                  style={{
                    background: color,
                  }}
                ></div>
                <div
                  className="sc-rhf-color-picker__checkers tw-absolute tw-w-full tw-h-full tw-top-0 tw-left-0 tw-z-[1] tw-rounded"
                  style={{
                    background:
                      "url('data:image/svg+xml;utf8,%3Csvg%20width%3D%226%22%20height%3D%226%22%20viewBox%3D%220%200%206%206%22%20fill%3D%22none%22%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%3E%3Cpath%20d%3D%22M0%200H3V3H0V0Z%22%20fill%3D%22%23E1E1E1%22/%3E%3Cpath%20d%3D%22M3%200H6V3H3V0Z%22%20fill%3D%22white%22/%3E%3Cpath%20d%3D%22M3%203H6V6H3V3Z%22%20fill%3D%22%23E1E1E1%22/%3E%3Cpath%20d%3D%22M0%203H3V6H0V3Z%22%20fill%3D%22white%22/%3E%3C/svg%3E%0A')",
                  }}
                ></div>
              </div>
              <ColorInput
                hidden={hidden}
                inputClassName={inputClassName}
                valueToHex={valueToHex}
                isGradient={isGradient}
                onChange={handleColorChange}
                disabled={showColorPicker}
                color={color}
                placeholder={placeholders[name]}
              />
            </div>
            <div className="tw-flex tw-items-center tw-gap-2">
              {!isGradient && (
                <div className="tw-w-auto">
                  <Form.Control
                    tabIndex={1}
                    className={clsx(
                      'sc-rhf-color-picker__alpha-input tw-w-[50px] alpha-input tw-truncate tw-bg-transparent focus:tw-outline-none tw-px-2 tw-text-label-color group-hover:tw-border-l group-hover:tw-border-border-color group-focus-within:tw-border-border-color tw-border-l tw-border-transparent tw-text-right',
                      inputClassName,
                    )}
                    required
                    hidden={hidden}
                    value={Math.floor(rgbaArr[3] * 100)}
                    onFocus={(e) => e.target.select()}
                    onChange={(e) => {
                      const value = e.target.value
                      setA(parseInt(value))
                    }}
                    onKeyDown={(e) => {
                      e.stopPropagation()
                    }}
                  />
                </div>
              )}
              {!isSnippet && showActionButtons && allowSnippets && (
                <SnippetsDropdown
                  icon="LinkSimple"
                  snippetsType="ATOMIC_COLOR"
                  onCreateSnippet={(snippetName) => {
                    onCreateSnippet({
                      name: snippetName,
                      data: { value: color },
                      type: 'ATOMIC_COLOR',
                      fieldName: name,
                      parentSnippetId: snippetId || parentSnippet?.id,
                      parentSnippetPath: parentSnippet?.path,
                    })
                  }}
                  onAssignSnippet={onAssignSnippetCallback}
                  onDeleteSnippet={onDeleteSnippet}
                />
              )}
            </div>
          </div>
        )}
        {hasSnippetUnpublishedChanges && allowSaveSnippet && (
          <SaveButton
            onClick={() => {
              onIssueSnippet({ id: snippetId, name: meta?.snippet?.name })
              setEditSnippetMode(false)
            }}
            className="tw-mt-2"
          />
        )}
        {isClearable && values[name] && <ClearButton name={name} />}
      </Form.Label>
      {showColorPicker && (
        <>
          {/* react-best-gradient-color-picker does not work properly for shadow-dom, so we need to portal the modal outside of shadow-dom  */}
          {createPortal(
            <div
              ref={refs.setFloating}
              style={{
                ...floatingStyles,
                border: '1px solid #E7E8E5',
                borderRadius: '16px',
                position: 'fixed',
                padding: '8px',
                zIndex: 9999,
                fontSize: '0.75rem',
                backgroundColor: '#fff',
              }}
              className="sc-rhf-color-picker__modal"
            >
              <ColorPicker
                disableDarkMode
                width={width}
                height={height}
                hideAdvancedSliders
                hideColorGuide
                hideInputType
                hideColorTypeBtns={!allowGradient}
                value={color}
                onChange={handleColorChange}
                presets={
                  recentlyUsedColorsArray.length
                    ? recentlyUsedColorsArray
                    : previousColors
                }
              />
            </div>,
            document.body,
          )}
        </>
      )}
    </Form.Field>
  )
}
