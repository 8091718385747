import { InferType, bool, object, string } from 'yup'
import { componentEditorSchema } from '../shared/componentEditorSchema'
import { styleSchema } from '../shared/styleSchema'
import { borderStyleSchema } from '../styles/borderStyleSchema'
import { boxShadowStyleSchema } from '../styles/boxShadowStyleSchema'
import { marginStyleSchema } from '../styles/marginStyleSchema'
import { paddingStyleSchema } from '../styles/paddingStyleSchema'
import { linkSchema } from './common/linkSchema'
import { imageComponentSchema } from './imageComponentSchema'
import { textComponentSchema } from './textComponentSchema'
import { videoComponentSchema } from './videoComponentSchema'
import { overlayStyleSchema } from '../styles/overlayStyleSchema'

export const mediaComponentSchema = object({
  meta: object({
    dataType: string().oneOf(['media-component']).required(),
  })
    .noUnknown()
    .required(),
  className: string(),
  show: bool(),
  style: styleSchema,
  cover: bool(),
  border: borderStyleSchema.optional(),
  margin: marginStyleSchema.optional(),
  padding: paddingStyleSchema.optional(),
  link: linkSchema.optional(),
  boxShadow: boxShadowStyleSchema.optional(),
  overlay: overlayStyleSchema.optional(),
  editor: componentEditorSchema,
  cid: string(),
  format: string().oneOf(['image', 'video']),
  animation: string().oneOf(['panZoom', '']),
  components: object({
    credits: textComponentSchema.optional(),
    caption: textComponentSchema.optional(),
    video: videoComponentSchema.optional(),
    image: imageComponentSchema.optional(),
  }).noUnknown(),
  fit: string().oneOf(['cover', 'contain']),
  id: string(),
  preview: bool(),
}).noUnknown()

export type MediaComponent = InferType<typeof mediaComponentSchema>

export type MediaComponentStyles = Pick<
  MediaComponent,
  'margin' | 'padding' | 'border' | 'boxShadow'
>
