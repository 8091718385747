import { Background } from '@sceneio/schemas/lib/components/common/backgroundSchema'

export function getBackgroundCssVariables({
  background,
  prefix,
}: {
  background?: Background
  prefix: string
}) {
  const { responsiveConfig } = background || {}

  if (responsiveConfig) {
    return Object.entries(responsiveConfig).reduce(
      (acc, [bp, config]) => ({
        ...acc,
        ...(config?.overlay && {
          [`--${prefix}-overlay-${bp}`]: config.overlay,
        }),
        ...(config?.color && {
          [`--${prefix}-background-color-${bp}`]: config.color,
        }),
        ...(bp !== 'default' &&
          !config?.overlay && {
            [`--${prefix}-overlay-${bp}`]: 'transparent',
          }),
        ...(bp !== 'default' &&
          !config?.color && {
            [`--${prefix}-background-color-${bp}`]: 'transparent',
          }),
      }),
      {},
    )
  }
  return {}
}
