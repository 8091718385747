import { InferType, array, boolean, object, string } from 'yup'
import { cssLengthUnitSchema } from './common/cssLengthUnitSchema'
import { idSchema } from '../shared/idSchema'

const boxShadowArrayItemSchema = object({
  x: cssLengthUnitSchema,
  y: cssLengthUnitSchema,
  blur: cssLengthUnitSchema,
  spread: cssLengthUnitSchema,
  color: string(),
  id: idSchema.required(),
  inset: boolean(),
}).required()

const boxShadowArraySchema = array().of(boxShadowArrayItemSchema)

const boxShadowResponsiveSchema = object({
  default: boxShadowArraySchema,
  hover: boxShadowArraySchema.optional(),
  active: boxShadowArraySchema.optional(),
}).noUnknown()

export const boxShadowResponsiveConfigSchema = object({
  default: boxShadowResponsiveSchema,
  xs: boxShadowResponsiveSchema.optional(),
  md: boxShadowResponsiveSchema.optional(),
  lg: boxShadowResponsiveSchema.optional(),
})
  .noUnknown()
  .required()

export const boxShadowStyleSchema = object({
  data: object({
    responsiveConfig: boxShadowResponsiveConfigSchema,
  }).noUnknown(),
  meta: object({
    dataType: string().oneOf(['box-shadow-style']).required(),
  })
    .noUnknown()
    .required(),
}).noUnknown()

export type BoxShadowStyle = InferType<typeof boxShadowStyleSchema>
export type BoxShadowArrayItem = InferType<typeof boxShadowArrayItemSchema>
