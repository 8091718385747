export type BreakpointEnum = 'default' | 'xs' | 'sm' | 'md' | 'lg' | 'xl'
export type AvailableBreakpointsEnum = 'default' | 'xs' | 'md' | 'lg'
export const ALL_BREAKPOINTS: BreakpointEnum[] = [
  'default',
  'xs',
  'sm',
  'md',
  'lg',
  'xl',
]
// Breakpoints available to user
export const AVAILABLE_BREAKPOINTS: BreakpointEnum[] = [
  'default',
  'xs',
  'md',
  'lg',
]
