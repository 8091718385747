import { ComponentMeta } from '../componentCommonTypes'
import { componentDefaultConfigFactory } from '../helpers'
import { randomString } from '@sceneio/tools'
import { DEFAULT_HEADINGS_CSS_VARIABLES } from '../Text/constants/globalTextCssVariables'
import { mediaDefaults } from '../Media/mediaMeta'
import {
  DEFAULT_LINK_BUTTON_CSS_VARIABLES,
  DEFAULT_OUTLINE_BUTTON_CSS_VARIABLES,
} from '../Button/constants/globalButtonCssVariables'
import { textToLexical } from '@sceneio/wysiwyg-utils'

const MEDIA_DEFAULTS = mediaDefaults.config

export const buttonSampleFactory = () => ({
  meta: {
    dataType: 'button-component',
  },
  id: randomString(8),
  children: textToLexical('Button', 'array-item'),
  width: {
    data: {
      '--width-default': 'auto',
      '--width-xs': '100%',
    },
  },
  ...DEFAULT_LINK_BUTTON_CSS_VARIABLES,
})

export const cardComponentMeta: ComponentMeta<'card'> = {
  defaultConfig: {
    meta: {
      dataType: 'card-component',
    },
    border: {
      meta: {
        dataType: 'border-style',
      },
      data: {
        default: {
          '--border-default-color-default': '#DCDCDC',
          '--border-default-width-default': '1px',
          '--border-default-style-default': 'solid',
          '--border-default-top-style-default': 'solid',
          '--border-default-bottom-style-default': 'solid',
          '--border-default-left-style-default': 'solid',
          '--border-default-right-style-default': 'solid',
          '--border-default-radius-top-left-default': '0px',
          '--border-default-radius-top-right-default': '0px',
          '--border-default-radius-bottom-left-default': '0px',
          '--border-default-radius-bottom-right-default': '0px',
        },
      },
    },
    components: {
      media: MEDIA_DEFAULTS,
      contentContainer: {
        meta: {
          dataType: 'container-component',
        },
        padding: {
          data: {
            '--padding-default-top': '3rem',
            '--padding-default-bottom': '3rem',
            '--padding-default-left': '3rem',
            '--padding-default-right': '3rem',
          },
        },
      },
      tagline: {
        meta: {
          dataType: 'text-component',
        },
        children: textToLexical('Tagline'),
        as: 'p',
        font: {
          meta: {
            dataType: 'font-style',
          },
          data: {
            default: {
              '--font-default-weight-default': 'bold',
            },
          },
        },
        padding: {
          data: {
            '--padding-default-bottom': '0.5rem',
          },
        },
      },
      heading: {
        meta: {
          dataType: 'text-component',
        },
        ...DEFAULT_HEADINGS_CSS_VARIABLES['h3'],
        children: textToLexical(
          'Medium length section heading goes here',
          'h3',
        ),
        as: 'h3',
        padding: {
          data: {
            '--padding-default-bottom': '1.5rem',
          },
        },
      },
      description: {
        meta: {
          dataType: 'text-component',
        },
        font: {
          meta: {
            dataType: 'font-style',
          },
        },
        children: textToLexical(
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique.',
        ),
        as: 'p',
        padding: {
          data: {
            '--padding-default-bottom': '2rem',
          },
        },
      },
      buttons: {
        meta: {
          dataType: 'container-component',
        },
        gap: {
          data: {
            '--row-gap-default': '1rem',
            '--column-gap-default': '0.5rem',
          },
        },
        flow: {
          data: {
            '--flow-default': 'row',
            '--flow-xs': 'column',
          },
        },
        horizontalAlignment: {
          data: {
            '--horizontal-alignment-default': 'flex-start',
          },
        },
        verticalAlignment: {
          data: {
            '--vertical-alignment-default': 'center',
          },
        },
        items: [
          {
            meta: {
              dataType: 'button-component',
            },
            id: randomString(8),
            children: textToLexical('Button', 'array-item'),
            width: {
              data: {
                '--width-default': 'auto',
                '--width-xs': '100%',
              },
            },
            ...DEFAULT_OUTLINE_BUTTON_CSS_VARIABLES,
          },
          {
            meta: {
              dataType: 'button-component',
            },
            id: randomString(8),
            children: textToLexical('Button', 'array-item'),
            width: {
              data: {
                '--width-default': 'auto',
                '--width-xs': '100%',
              },
            },
            ...DEFAULT_LINK_BUTTON_CSS_VARIABLES,
          },
        ],
      },
    },
  },
}

export const cardDefaults = componentDefaultConfigFactory<'card'>({
  componentMeta: cardComponentMeta,
})
