import { insert } from 'ramda'
import { nestedIdsRandomizer } from '@sceneio/tools'

type CommonItemsFactoryType = {
  itemsArr: any[]
  itemsId: string
  index: number
  onAddCloneExistingItem?: boolean
  additionalItemsToAdd?: Array<{
    path: string
    config?: Object
    isArrayPath?: boolean
    cloneExistingItem?: boolean
  }>
  additionalItemsToDelete?: Array<{ path: string; isArrayPath: boolean }>
}
type FactoryReturnType = () => {
  id: string
  value: any
  additionalItemsToAdd?: Array<{
    path: string
    config?: Object
    isArrayPath?: boolean
    cloneExistingItem?: boolean
    position?: number
  }>
  additionalItemsToDelete?: Array<{
    path: string
    isArrayPath: boolean
    position?: number
  }>
}

type AddItemFactory = CommonItemsFactoryType & {
  itemFactory: () => any
}
export function addItemFactory({
  itemsArr,
  itemsId,
  itemFactory,
  index,
  additionalItemsToAdd,
  onAddCloneExistingItem = true,
}: AddItemFactory): FactoryReturnType {
  return function () {
    return {
      id: itemsId,
      value: insert(
        index + 1,
        onAddCloneExistingItem
          ? nestedIdsRandomizer(itemsArr[index])
          : itemFactory(),
        itemsArr,
      ),
      additionalItemsToAdd: additionalItemsToAdd?.map((item) => ({
        ...item,
        position: index,
      })),
    }
  }
}

type RemoveItemFactory = CommonItemsFactoryType

export function removeItemFactory({
  itemsArr,
  itemsId,
  index,
  additionalItemsToDelete,
}: RemoveItemFactory): FactoryReturnType {
  return function () {
    return {
      id: itemsId,
      value: itemsArr.filter((_, i) => i !== index),
      additionalItemsToDelete: additionalItemsToDelete?.map((item) => ({
        ...item,
        position: index,
      })),
    }
  }
}

type EditorItemsControlsFactory = AddItemFactory & RemoveItemFactory

type EditorItemsControlFactoryReturn = {
  itemAdd: FactoryReturnType
  itemRemove: FactoryReturnType
  disableItemAdd: boolean
  disableItemRemove: boolean
}

export function editorItemsControlsFactory({
  itemsId,
  itemsArr,
  itemFactory,
  index,
  additionalItemsToAdd,
  additionalItemsToDelete,
  onAddCloneExistingItem,
}: EditorItemsControlsFactory): EditorItemsControlFactoryReturn {
  return {
    itemAdd: addItemFactory({
      itemsId,
      itemsArr,
      itemFactory,
      index,
      additionalItemsToAdd,
      onAddCloneExistingItem,
    }),
    itemRemove: removeItemFactory({
      itemsId,
      itemsArr,
      index,
      additionalItemsToDelete,
    }),
    disableItemAdd: false,
    disableItemRemove: itemsArr.length <= 1,
  }
}
