import React from 'react'
import { pricingVariant1DefaultConfig } from './pricingVariant1Meta'
import PricingVariant1 from './PricingVariant1'

const PricingVariant1Preview = (config) => {
  const defaultData =
    Object.keys(config).length !== 0 ? config : pricingVariant1DefaultConfig

  return <PricingVariant1 {...defaultData} />
}

export default PricingVariant1Preview
