import { InferType, bool, mixed, object, string } from 'yup'
import { marginStyleSchema } from '../styles/marginStyleSchema'
import { paddingStyleSchema } from '../styles/paddingStyleSchema'
import { borderStyleSchema } from '../styles/borderStyleSchema'
import { boxShadowStyleSchema } from '../styles/boxShadowStyleSchema'
import { styleSchema } from '../shared/styleSchema'
import { componentEditorSchema } from '../shared/componentEditorSchema'
import { containerComponentSchema } from './containerComponentSchema'
import { textComponentSchema } from './textComponentSchema'
import { buttonComponentSchema } from './buttonComponentSchema'

export const accordionItemComponentSchema = object({
  meta: object({
    dataType: string().oneOf(['accordion-item-component']).required(),
  })
    .noUnknown()
    .required(),
  components: object({
    headerContainer: containerComponentSchema,
    bodyContainer: containerComponentSchema,
    heading: textComponentSchema,
    text: textComponentSchema,
    icon: buttonComponentSchema,
  }).noUnknown(),
  swapIconSide: bool(),
  className: string(),
  margin: marginStyleSchema.optional(),
  padding: paddingStyleSchema.optional(),
  border: borderStyleSchema.optional(),
  boxShadow: boxShadowStyleSchema.optional(),
  show: bool(),
  style: styleSchema,
  editor: componentEditorSchema,
  cid: string(),
}).noUnknown()

export type AccordionItemComponent = InferType<
  typeof accordionItemComponentSchema
>

export type AccordionItemComponentStyles = Pick<
  AccordionItemComponent,
  'margin' | 'padding' | 'boxShadow' | 'border'
>
