import React from 'react'
import { TabPaneComponent } from '@sceneio/schemas/lib/components/tabsComponentSchema'
import clsx from 'clsx'
import { getRootLevelCssVariables } from '../../../tools/cssVariables/getRootLevelCssVariables'
import { getShadowCssVariables } from '../../../tools/cssVariables/getShadowCssVariables'
import { getBackgroundCssVariables } from '../../../tools/cssVariables/getBackgroundCssVariables'
import { twicPicsSourceUrlResolver } from '@sceneio/middleware/lib/twicPicsSourceUrlResolver'
import { resolveBreakpointImageClasses } from '../../Image/helpers/resolveBreakpointImageClasses'
import { resolveDefaultImageClasses } from '../../Image/helpers/resolveDefaultImageClasses'
import {
  AvailableBreakpointsEnum,
  BreakpointEnum,
} from '../../Image/constants/breakpoints'

function TabPane({
  children,
  index,
  padding,
  margin,
  border,
  boxShadow,
  background,
  horizontalAlignment,
  verticalAlignment,
  gap,
  style = {},
  editor,
  defaultActive = 0,
  preview,
  show,
}: TabPaneComponent) {
  const {
    fit,
    parallax,
    responsiveConfig: bgResponsiveConfig,
  } = background || {}

  return (
    <div
      data-plugin-tab-pane
      data-component-id={editor?.id}
      className={clsx(
        'sc-tab-pane',
        {
          show: preview ? show : defaultActive === index,
        },
        editor?.resolveClassName?.({
          blockId: editor.blockId,
          componentId: editor.id,
        }),
      )}
      style={{
        ...style,
        ...getRootLevelCssVariables({
          cssVariables: {
            horizontalAlignment,
            verticalAlignment,
            gap,
            padding,
            margin,
            border,
          },
          prefix: 'tab-pane',
        }),
        ...getShadowCssVariables({
          shadow: boxShadow,
          shadowType: 'box',
          prefix: 'tap-pane',
        }),
        ...getBackgroundCssVariables({
          background,
          prefix: 'tab-pane',
        }),
      }}
    >
      {children}
      {bgResponsiveConfig && Object.keys(bgResponsiveConfig).length && (
        <>
          <div className="sc-tab-pane__bg-overlay" />
          {Object.entries(bgResponsiveConfig).map(([bp, config]) => {
            const { image } = config || {}
            return (
              <React.Fragment key={bp}>
                {image?.src && (
                  <div
                    style={{
                      backgroundImage: `url(${image.src}?twic=v1/output=preview)`,
                    }}
                    data-twic-background={`url(${twicPicsSourceUrlResolver(
                      image.src,
                    )}${editor ? `?cover=${fit === 'cover'}` : ''})`}
                    className={clsx('sc-tab-pane__bg-image', {
                      'sc-tab-pane__bg-image--parallax': parallax,
                      'sc-tab-pane__bg-image--cover': fit === 'cover',
                      [resolveBreakpointImageClasses({
                        breakpoint: bp as BreakpointEnum,
                        elementClassName: 'sc-tab-pane__bg-image',
                      })]: bp !== 'default',
                      [resolveDefaultImageClasses({
                        breakpointsArr: (
                          Object.keys(
                            bgResponsiveConfig,
                          ) as AvailableBreakpointsEnum[]
                        ).filter((bp) => bgResponsiveConfig[bp]),
                        elementClassName: 'sc-tab-pane__bg-image',
                      })]: bp === 'default',
                    })}
                  />
                )}
              </React.Fragment>
            )
          })}
        </>
      )}
    </div>
  )
}

export default TabPane
