import { normalizeJMESPath } from '@sceneio/tools/lib/normalizeJMESPath'
import {
  ReferencesObjectType,
  transformReferencesFromArrayToObject,
} from './helpers/transformReferencesFromArrayToObject'
import { transformReferencesFromObjectToArray } from './helpers/transformReferencesFromObjectToArray'
import { ReferencesType, ReferenceType } from './referencesTypes'
import { search } from 'jmespath'

export type ReferencesCleanupType = {
  references: ReferencesType
  data: Record<string, any>
  targetPath?: string
  options?: {
    shouldNotifyWhenNonExistingReferenceFound?: boolean
  }
}

function differentiateReferences({
  referencesObject,
  targetPath,
}: {
  targetPath?: string
  referencesObject: ReferencesObjectType
}): {
  relatedReferencesObject: ReferencesObjectType
  unrelatedReferencesObject: ReferencesObjectType
} {
  let relatedReferencesObject = {}
  let unrelatedReferencesObject = {}

  if (!targetPath) {
    return {
      relatedReferencesObject: referencesObject,
      unrelatedReferencesObject,
    }
  }

  Object.keys(referencesObject).forEach((referencePath) => {
    if (referencePath.startsWith(targetPath)) {
      relatedReferencesObject[referencePath] = referencesObject[referencePath]
    } else {
      unrelatedReferencesObject[referencePath] = referencesObject[referencePath]
    }
  })

  return {
    relatedReferencesObject,
    unrelatedReferencesObject,
  }
}

export function referencesCleanup({
  references = [],
  data,
  targetPath,
  options,
}: ReferencesCleanupType): ReferencesType {
  const { shouldNotifyWhenNonExistingReferenceFound } = options || {}
  const referencesObject = transformReferencesFromArrayToObject(references)
  const result = {}

  // differentiate related & unrelated references according to optional targetPath
  const { relatedReferencesObject, unrelatedReferencesObject } =
    differentiateReferences({ referencesObject: referencesObject, targetPath })

  let processedReferencesPaths = []

  const relatedReferencesPaths = Object.keys(relatedReferencesObject).sort()

  relatedReferencesPaths.forEach((referencePath) => {
    const normalizedJMESReferencePath = normalizeJMESPath(referencePath)

    const isReferenceExist = search(data, normalizedJMESReferencePath)

    const isNestedReference = processedReferencesPaths.some((refPath) =>
      referencePath.startsWith(refPath),
    )

    if (!isReferenceExist && shouldNotifyWhenNonExistingReferenceFound) {
      console.error(
        `[referencesCleanup]: ${referencePath} does not exist within data`,
      )
    }

    if (isReferenceExist && !isNestedReference) {
      result[referencePath] = relatedReferencesObject[referencePath]
    }

    processedReferencesPaths.push(referencePath)
  })

  return transformReferencesFromObjectToArray({
    ...unrelatedReferencesObject,
    ...result,
  })
}
