import type {
  ButtonComponent,
  FooterVariant3Block,
  FooterVariant3ColumnConfig,
} from '@sceneio/schemas/lib/types'
import { mergeDeepRight, randomString } from '@sceneio/tools'
import { DEFAULT_LINK_BUTTON_CSS_VARIABLES } from '../../../components/Button/constants/globalButtonCssVariables'
import { containerComponentFormRegisterValuesFactory } from '../../../components/Container/form/ContainerComponentForm'
import {
  BlockComponentMeta,
  BlockMeta,
  ComponentDefinition,
} from '../../blockCommonTypes'
import { blockDefaultConfigFactory } from '../../helpers'
import { textToLexical } from '@sceneio/wysiwyg-utils'
import { buttonContainerLayoutAdditionalFormFieldRenderer } from '../../Hero/SplitHero/form/additionalFormFieldRenderers'

export const navLinkSampleFactory = (data = {}): ButtonComponent =>
  mergeDeepRight(DEFAULT_LINK_BUTTON_CSS_VARIABLES, {
    children: textToLexical('Menu item', 'array-item'),
    meta: {
      dataType: 'button-component',
    },
    id: randomString(8),
    link: {
      type: 'internal',
      href: '#',
      target: '_self',
    },
    ...data,
    font: {
      meta: {
        dataType: 'font-style',
      },
      data: {
        default: {
          '--font-default-size-default': '0.95rem',
        },
      },
    },
    padding: {
      data: {
        '--padding-default-left': '0rem',
        '--padding-default-right': '0rem',
        '--padding-default-top': '0rem',
        '--padding-default-bottom': '0rem',
      },
    },
  })

type GenerateColumnComponentsType = Partial<
  Record<
    keyof FooterVariant3ColumnConfig,
    ComponentDefinition & {
      components?: Record<string, ComponentDefinition>
    }
  >
>

const generateColumnComponents = (
  column: 'column1' | 'column2' | 'column3' | 'column4',
  {
    shouldShow,
    menuItemsChildren = ['Menu item', 'Menu item', 'Menu item', 'Menu item'],
  }: {
    shouldShow: {
      logo: boolean
      upperText: boolean
      bottomText: boolean
      menuTitle: boolean
      menu: boolean
      social: boolean
    }
    menuItemsChildren: string[]
  },
): GenerateColumnComponentsType => ({
  logo: {
    name: 'Logo',
    type: 'image',
    path: `logo`,
    defaultConfig: {
      meta: {
        dataType: 'image-component',
      },
      show: shouldShow.logo,
      responsiveConfig: {
        default: {
          src: 'https://assets.scene.io/global/placeholders/logo-ipsum-horizontal.svg',
          alt: 'Footer Logo',
          width: 150,
        },
      },
    },
  },
  upperText: {
    name: 'Upper text',
    type: 'text',
    path: `upperText`,
    defaultConfig: {
      meta: {
        dataType: 'text-component',
      },
      show: shouldShow.upperText,
      children: JSON.stringify({
        root: {
          children: [
            {
              children: [
                {
                  detail: 0,
                  format: 0,
                  mode: 'normal',
                  style: '',
                  text: 'Aa short tag line that represents ',
                  type: 'text',
                  version: 1,
                },
              ],
              direction: null,
              format: '',
              indent: 0,
              type: 'paragraph',
              version: 1,
            },
            {
              children: [
                {
                  detail: 0,
                  format: 0,
                  mode: 'normal',
                  style: '',
                  text: 'a brand, product, or company.',
                  type: 'text',
                  version: 1,
                },
              ],
              direction: 'ltr',
              format: '',
              indent: 0,
              type: 'paragraph',
              version: 1,
            },
            {
              children: [],
              direction: null,
              format: '',
              indent: 0,
              type: 'paragraph',
              version: 1,
            },
            {
              children: [
                {
                  detail: 0,
                  format: 0,
                  mode: 'normal',
                  style: '',
                  text: 'It should be memorable, catchy, ',
                  type: 'text',
                  version: 1,
                },
              ],
              direction: 'ltr',
              format: '',
              indent: 0,
              type: 'paragraph',
              version: 1,
            },
            {
              children: [
                {
                  detail: 0,
                  format: 0,
                  mode: 'normal',
                  style: '',
                  text: 'and convey the essence of what the brand or product represents.',
                  type: 'text',
                  version: 1,
                },
              ],
              direction: 'ltr',
              format: '',
              indent: 0,
              type: 'paragraph',
              version: 1,
            },
          ],
          direction: null,
          format: '',
          indent: 0,
          type: 'root',
          version: 1,
        },
      }),
      as: 'p',
      font: {
        meta: {
          dataType: 'font-style',
        },
        data: {
          default: {
            '--font-default-size-default': '0.95rem',
          },
        },
      },
      horizontalAlignment: {
        data: {
          '--horizontal-alignment-xs': 'center',
        },
      },
    },
    inlineEditors: [
      {
        editorType: 'inlineText',
        editorPath: `components.${column}.upperText`,
        editorOptions: {
          include: ['list'],
        },
      },
    ],
  },
  bottomText: {
    name: 'Bottom text',
    type: 'text',
    path: `bottomText`,
    defaultConfig: {
      meta: {
        dataType: 'text-component',
      },
      show: shouldShow.bottomText,
      children: textToLexical(
        'Lorem ipsum dolor sit amet, consectetur adipisicing elit officiis corporis optio natus.',
      ),
      as: 'p',
      font: {
        meta: {
          dataType: 'font-style',
        },
        data: {
          default: {
            '--font-default-size-default': '0.95rem',
          },
        },
      },
      horizontalAlignment: {
        data: {
          '--horizontal-alignment-xs': 'center',
        },
      },
    },
    inlineEditors: [
      {
        editorType: 'inlineText',
        editorPath: `components.${column}.bottomText`,
        editorOptions: {
          include: ['list'],
        },
      },
    ],
  },
  menuTitle: {
    name: 'Menu title',
    type: 'text',
    path: `menuTitle`,
    defaultConfig: {
      meta: {
        dataType: 'text-component',
      },
      show: shouldShow.menuTitle,
      children: textToLexical('Logical group heading', 'h6'),
      as: 'h6',
      font: {
        meta: {
          dataType: 'font-style',
        },
        data: {
          default: {
            '--font-default-size-default': '1.1rem',
            '--font-default-weight-default': 600,
          },
        },
      },
    },
    inlineEditors: [
      {
        editorType: 'inlineText',
        editorPath: `components.${column}.menuTitle`,
      },
    ],
  },
  menu: {
    name: 'Menu',
    path: `menu`,
    type: 'container',
    components: {
      items: {
        isArrayComponent: true,
        sampleFactory: () => ({
          ...navLinkSampleFactory(),
          children: textToLexical('Menu item', 'array-item'),
        }),
        path: `items`,
        name: 'Link',
        type: 'button',
        disableToggleShow: true,
      },
    },
    inlineEditors: [
      {
        editorType: 'inlineNavLink',
        editorPath: `components.${column}.menu.items`,
        editorOptions: {
          sampleFactory: () => ({
            ...navLinkSampleFactory(),
            children: textToLexical('Menu item', 'array-item'),
          }),
        },
      },
    ],
    defaultConfig: {
      meta: {
        dataType: 'container-component',
      },
      show: shouldShow.menu,
      items: [
        {
          ...navLinkSampleFactory(),
          children: textToLexical(menuItemsChildren[0], 'array-item'),
        },
        {
          ...navLinkSampleFactory(),
          children: textToLexical(menuItemsChildren[1], 'array-item'),
        },
        {
          ...navLinkSampleFactory(),
          children: textToLexical(menuItemsChildren[2], 'array-item'),
        },
        {
          ...navLinkSampleFactory(),
          children: textToLexical(menuItemsChildren[3], 'array-item'),
        },
      ],
      gap: {
        data: {
          [`--row-gap-default`]: '1rem',
        },
      },
      flow: {
        data: {
          '--flow-default': 'column',
        },
      },
      verticalAlignment: {
        data: {
          '--vertical-alignment-default': 'center',
        },
      },
    },
    configEditor: {
      additionalFormFields: {
        layout: {
          formFieldRenderer: buttonContainerLayoutAdditionalFormFieldRenderer,
        },
      },
      formProps: {
        name: 'componentArray',
        registerValues:
          containerComponentFormRegisterValuesFactory('componentArray'),
      },
    },
  },
  // social: {
  //   name: 'Social buttons',
  //   type: 'social',
  //   path: `social`,
  //   defaultConfig: {
  //     show: shouldShow.social,
  //     mode: 'share',
  //     variant: 'regular',
  //     gap: 4,
  //     services: ['facebook', 'twitter', 'linkedIn'],
  //   },
  // },
})

export const footerVariant3ComponentMeta: BlockComponentMeta<FooterVariant3Block> =
  {
    block: {
      path: 'components.block',
      name: 'Block',
      type: 'block',
      defaultConfig: {
        meta: {
          dataType: 'block-component',
        },
        padding: {
          data: {
            '--padding-default-top': '2rem',
            '--padding-default-left': '2rem',
            '--padding-default-right': '2rem',
            '--padding-default-bottom': '2rem',
          },
        },
      },
    },
    blockContainer: {
      path: 'components.block.blockContainer',
      name: 'Container',
      disableToggleShow: true,
      defaultConfig: {
        meta: {
          dataType: 'block-container-component',
        },
        padding: {
          data: {
            '--padding-default-top': '1rem',
            '--padding-default-bottom': '1rem',
          },
        },
        responsiveConfig: {
          default: {
            rowGap: '2rem',
            maxWidth: 'screen',
            columnGap: '1.5rem',
          },
        },
        verticalAlignment: {
          data: {
            '--vertical-alignment-default': 'center',
          },
        },
        horizontalAlignment: {
          data: {
            '--horizontal-alignment-xs': 'center',
          },
        },
      },
      type: 'blockContainer',
    },
    column1: {
      components: generateColumnComponents('column1', {
        shouldShow: {
          logo: true,
          upperText: false,
          social: false,
          bottomText: false,
          menuTitle: false,
          menu: false,
        },
        menuItemsChildren: [
          'Easy to read',
          'Clear & Concise',
          'Descriptive',
          'Consistent',
        ],
      }),
      defaultConfig: {
        show: true,
        meta: {
          dataType: 'container-component',
        },
        gap: {
          data: {
            [`--row-gap-default`]: '1rem',
          },
        },
        horizontalAlignment: {
          data: {
            '--horizontal-alignment-default': 'flex-start',
            '--horizontal-alignment-xs': 'center',
          },
        },
      },
      name: 'Column 1',
      path: 'components.column1',
      configEditor: {
        formProps: {
          name: 'group',
          registerValues: containerComponentFormRegisterValuesFactory('group'),
        },
      },
      type: 'container',
    },
    column2: {
      components: {
        logo: {
          name: 'Logo',
          type: 'image',
          path: `logo`,
          defaultConfig: {
            meta: {
              dataType: 'image-component',
            },
            show: false,
            responsiveConfig: {
              default: {
                src: 'https://assets.scene.io/global/placeholders/logo-ipsum-horizontal.svg',
                alt: 'Footer Logo',
                width: 150,
              },
            },
          },
        },
        upperText: {
          name: 'Upper text',
          type: 'text',
          path: `upperText`,
          defaultConfig: {
            show: false,
            meta: {
              dataType: 'text-component',
            },
            children: JSON.stringify({
              root: {
                children: [
                  {
                    children: [
                      {
                        detail: 0,
                        format: 0,
                        mode: 'normal',
                        style: '',
                        text: 'Aa short tag line that represents ',
                        type: 'text',
                        version: 1,
                      },
                    ],
                    direction: null,
                    format: '',
                    indent: 0,
                    type: 'paragraph',
                    version: 1,
                  },
                  {
                    children: [
                      {
                        detail: 0,
                        format: 0,
                        mode: 'normal',
                        style: '',
                        text: 'a brand, product, or company.',
                        type: 'text',
                        version: 1,
                      },
                    ],
                    direction: 'ltr',
                    format: '',
                    indent: 0,
                    type: 'paragraph',
                    version: 1,
                  },
                  {
                    children: [],
                    direction: null,
                    format: '',
                    indent: 0,
                    type: 'paragraph',
                    version: 1,
                  },
                  {
                    children: [
                      {
                        detail: 0,
                        format: 0,
                        mode: 'normal',
                        style: '',
                        text: 'It should be memorable, catchy, ',
                        type: 'text',
                        version: 1,
                      },
                    ],
                    direction: 'ltr',
                    format: '',
                    indent: 0,
                    type: 'paragraph',
                    version: 1,
                  },
                  {
                    children: [
                      {
                        detail: 0,
                        format: 0,
                        mode: 'normal',
                        style: '',
                        text: 'and convey the essence of what the brand or product represents.',
                        type: 'text',
                        version: 1,
                      },
                    ],
                    direction: 'ltr',
                    format: '',
                    indent: 0,
                    type: 'paragraph',
                    version: 1,
                  },
                ],
                direction: null,
                format: '',
                indent: 0,
                type: 'root',
                version: 1,
              },
            }),
            as: 'p',
            font: {
              meta: {
                dataType: 'font-style',
              },
              data: {
                default: {
                  '--font-default-size-default': '0.95rem',
                },
              },
            },
            horizontalAlignment: {
              data: {
                '--horizontal-alignment-xs': 'center',
              },
            },
          },
          inlineEditors: [
            {
              editorType: 'inlineText',
              editorPath: `components.column2.upperText`,
              editorOptions: {
                include: ['list'],
              },
            },
          ],
        },
        bottomText: {
          name: 'Bottom text',
          type: 'text',
          path: `bottomText`,
          defaultConfig: {
            meta: {
              dataType: 'text-component',
            },
            show: false,
            children: textToLexical(
              'Lorem ipsum dolor sit amet, consectetur adipisicing elit officiis corporis optio natus.',
            ),
            as: 'p',
            font: {
              meta: {
                dataType: 'font-style',
              },
              data: {
                default: {
                  '--font-default-size-default': '0.95rem',
                },
              },
            },
            horizontalAlignment: {
              data: {
                '--horizontal-alignment-xs': 'center',
              },
            },
          },
          inlineEditors: [
            {
              editorType: 'inlineText',
              editorPath: `components.column2.bottomText`,
              editorOptions: {
                include: ['list'],
              },
            },
          ],
        },
        menuTitle: {
          name: 'Menu title',
          type: 'text',
          path: `menuTitle`,
          defaultConfig: {
            show: false,
            meta: {
              dataType: 'text-component',
            },
            children: textToLexical('Logical group heading', 'h6'),
            as: 'h6',
            font: {
              meta: {
                dataType: 'font-style',
              },
              data: {
                default: {
                  '--font-default-size-default': '1.1rem',
                  '--font-default-weight-default': 600,
                },
              },
            },
          },
          inlineEditors: [
            {
              editorType: 'inlineText',
              editorPath: `components.column2.menuTitle`,
            },
          ],
        },
        menu: {
          name: 'Menu',
          path: `menu`,
          type: 'container',
          components: {
            items: {
              isArrayComponent: true,
              sampleFactory: () => ({
                ...navLinkSampleFactory(),
                children: textToLexical('Menu item', 'array-item'),
              }),
              path: `items`,
              name: 'Link',
              type: 'button',
              disableToggleShow: true,
            },
          },
          inlineEditors: [
            {
              editorType: 'inlineNavLink',
              editorPath: `components.column2.menu.items`,
              editorOptions: {
                sampleFactory: () => ({
                  ...navLinkSampleFactory(),
                  children: textToLexical('Menu item', 'array-item'),
                }),
              },
            },
          ],
          defaultConfig: {
            meta: {
              dataType: 'container-component',
            },
            show: true,
            items: [
              {
                ...navLinkSampleFactory(),
                children: textToLexical('Clear & Concise', 'array-item'),
              },
              {
                ...navLinkSampleFactory(),
                children: textToLexical('Easy to read', 'array-item'),
              },
              {
                ...navLinkSampleFactory(),
                children: textToLexical('Consistent', 'array-item'),
              },
              {
                ...navLinkSampleFactory(),
                children: textToLexical('Descriptive', 'array-item'),
              },
            ],
            gap: {
              data: {
                '--row-gap-default': '1rem',
                '--column-gap-default': '1.5rem',
              },
            },
            flow: {
              data: {
                '--flow-default': 'row',
                '--flow-md': 'column',
                '--flow-xs': 'column',
              },
            },
            verticalAlignment: {
              data: {
                '--vertical-alignment-default': 'center',
              },
            },
          },
          configEditor: {
            additionalFormFields: {
              layout: {
                formFieldRenderer:
                  buttonContainerLayoutAdditionalFormFieldRenderer,
              },
            },
            formProps: {
              name: 'componentArray',
              registerValues:
                containerComponentFormRegisterValuesFactory('componentArray'),
            },
          },
        },
      },
      defaultConfig: {
        meta: {
          dataType: 'container-component',
        },
        show: true,
        gap: {
          data: {
            [`--row-gap-default`]: '1rem',
          },
        },
        verticalAlignment: {
          data: {
            '--vertical-alignment-default': 'center',
          },
        },
        horizontalAlignment: {
          data: {
            '--horizontal-alignment-xs': 'center',
            '--horizontal-alignment-default': 'center',
          },
        },
      },
      name: 'Column 2',
      path: 'components.column2',
      configEditor: {
        formProps: {
          name: 'group',
          registerValues: containerComponentFormRegisterValuesFactory('group'),
        },
      },
      type: 'container',
    },
    column3: {
      components: {
        logo: {
          name: 'Logo',
          type: 'image',
          path: `logo`,
          defaultConfig: {
            meta: {
              dataType: 'image-component',
            },
            show: false,
            responsiveConfig: {
              default: {
                src: 'https://assets.scene.io/global/placeholders/logo-ipsum-horizontal.svg',
                alt: 'Footer Logo',
                width: 150,
              },
            },
            padding: {
              data: {
                '--padding-default-top': 0,
                '--padding-default-left': '1rem',
                '--padding-default-right': '0rem',
                '--padding-default-bottom': 0,
              },
            },
          },
        },
        upperText: {
          name: 'Upper text',
          type: 'text',
          path: `upperText`,
          defaultConfig: {
            meta: {
              dataType: 'text-component',
            },
            show: false,
            children: JSON.stringify({
              root: {
                children: [
                  {
                    children: [
                      {
                        detail: 0,
                        format: 0,
                        mode: 'normal',
                        style: '',
                        text: 'Aa short tag line that represents ',
                        type: 'text',
                        version: 1,
                      },
                    ],
                    direction: null,
                    format: '',
                    indent: 0,
                    type: 'paragraph',
                    version: 1,
                  },
                  {
                    children: [
                      {
                        detail: 0,
                        format: 0,
                        mode: 'normal',
                        style: '',
                        text: 'a brand, product, or company.',
                        type: 'text',
                        version: 1,
                      },
                    ],
                    direction: 'ltr',
                    format: '',
                    indent: 0,
                    type: 'paragraph',
                    version: 1,
                  },
                  {
                    children: [],
                    direction: null,
                    format: '',
                    indent: 0,
                    type: 'paragraph',
                    version: 1,
                  },
                  {
                    children: [
                      {
                        detail: 0,
                        format: 0,
                        mode: 'normal',
                        style: '',
                        text: 'It should be memorable, catchy, ',
                        type: 'text',
                        version: 1,
                      },
                    ],
                    direction: 'ltr',
                    format: '',
                    indent: 0,
                    type: 'paragraph',
                    version: 1,
                  },
                  {
                    children: [
                      {
                        detail: 0,
                        format: 0,
                        mode: 'normal',
                        style: '',
                        text: 'and convey the essence of what the brand or product represents.',
                        type: 'text',
                        version: 1,
                      },
                    ],
                    direction: 'ltr',
                    format: '',
                    indent: 0,
                    type: 'paragraph',
                    version: 1,
                  },
                ],
                direction: null,
                format: '',
                indent: 0,
                type: 'root',
                version: 1,
              },
            }),
            as: 'p',
            font: {
              meta: {
                dataType: 'font-style',
              },
              data: {
                default: {
                  '--font-default-size-default': '0.95rem',
                },
              },
            },
            horizontalAlignment: {
              data: {
                '--horizontal-alignment-xs': 'center',
              },
            },
          },
          inlineEditors: [
            {
              editorType: 'inlineText',
              editorPath: `components.column1.upperText`,
              editorOptions: {
                include: ['list'],
              },
            },
          ],
        },
        bottomText: {
          name: 'Bottom text',
          type: 'text',
          path: `bottomText`,
          defaultConfig: {
            meta: {
              dataType: 'text-component',
            },
            show: false,
            children: textToLexical(
              'Lorem ipsum dolor sit amet, consectetur adipisicing elit officiis corporis optio natus.',
            ),
            as: 'p',
            font: {
              meta: {
                dataType: 'font-style',
              },
              data: {
                default: {
                  '--font-default-size-default': '0.95rem',
                },
              },
            },
            horizontalAlignment: {
              data: {
                '--horizontal-alignment-xs': 'center',
              },
            },
          },
          inlineEditors: [
            {
              editorType: 'inlineText',
              editorPath: `components.column1.bottomText`,
              editorOptions: {
                include: ['list'],
              },
            },
          ],
        },
        menuTitle: {
          name: 'Menu title',
          type: 'text',
          path: `menuTitle`,
          defaultConfig: {
            meta: {
              dataType: 'text-component',
            },
            show: false,
            children: textToLexical('Logical group heading', 'h6'),
            as: 'h6',
            font: {
              meta: {
                dataType: 'font-style',
              },
              data: {
                default: {
                  '--font-default-size-default': '1.1rem',
                  '--font-default-weight-default': 600,
                },
              },
            },
          },
          inlineEditors: [
            {
              editorType: 'inlineText',
              editorPath: `components.column1.menuTitle`,
            },
          ],
        },
        menu: {
          name: 'Menu',
          path: `menu`,
          type: 'container',
          components: {
            items: {
              isArrayComponent: true,
              sampleFactory: () => ({
                ...navLinkSampleFactory(),
                children: textToLexical('Menu item', 'array-item'),
              }),
              path: `items`,
              name: 'Link',
              type: 'button',
              disableToggleShow: true,
            },
          },
          inlineEditors: [
            {
              editorType: 'inlineNavLink',
              editorPath: `components.column1.menu.items`,
              editorOptions: {
                sampleFactory: () => ({
                  ...navLinkSampleFactory(),
                  children: textToLexical('Menu item', 'array-item'),
                }),
              },
            },
          ],
          defaultConfig: {
            meta: {
              dataType: 'container-component',
            },
            show: true,
            items: [
              {
                id: randomString(8),
                meta: {
                  dataType: 'button-component',
                },
                font: {
                  meta: {
                    dataType: 'font-style',
                  },
                  data: {
                    hover: {
                      '--font-default-text-decoration-line-hover': 'underline',
                      '--font-default-text-decoration-color-hover': '#000',
                    },
                    default: {
                      '--font-default-size-default': '0.95rem',
                    },
                  },
                },
                link: {
                  href: '',
                  type: 'external',
                  target: '_self',
                },
                color: {
                  data: {
                    hover: {
                      '--color-default-hover': '#000',
                    },
                    active: {
                      '--color-default-active': '#000',
                    },
                    default: {
                      '--color-default-default': '#000',
                    },
                  },
                },
                border: {
                  meta: {
                    dataType: 'border-style',
                  },
                  data: {
                    hover: {
                      '--border-default-color-hover': 'transparent',
                    },
                    default: {
                      '--border-default-radius-top-left-default': '100px',
                      '--border-default-radius-top-right-default': '100px',
                      '--border-default-radius-bottom-left-default': '100px',
                      '--border-default-radius-bottom-right-default': '100px',
                    },
                  },
                },
                padding: {
                  data: {
                    '--padding-default-top': '0.5rem',
                    '--padding-default-left': '0.5rem',
                    '--padding-default-right': '0.5rem',
                    '--padding-default-bottom': '0.5rem',
                  },
                },
                children: textToLexical('Facebook'),
                components: {
                  icon: {
                    meta: {
                      dataType: 'icon-component',
                    },
                    gap: {
                      data: {
                        '--column-gap-default': '8px',
                      },
                    },
                    mode: 'only',
                    responsiveConfig: {
                      default: {
                        default: {
                          name: 'FacebookLogo',
                          provider: 'phosphor',
                        },
                      },
                    },
                    width: {
                      data: {
                        '--width-default': '24px',
                      },
                    },
                  },
                },
                backgroundColor: {
                  data: {
                    hover: {
                      '--background-color-default-hover': 'rgba(0, 0, 0, 0.08)',
                    },
                    active: {
                      '--background-color-default-active': 'transparent',
                    },
                    default: {
                      '--background-color-default-default': 'transparent',
                    },
                  },
                },
              },
              {
                id: randomString(8),
                meta: {
                  dataType: 'button-component',
                },
                font: {
                  meta: {
                    dataType: 'font-style',
                  },
                  data: {
                    hover: {
                      '--font-default-text-decoration-line-hover': 'underline',
                      '--font-default-text-decoration-color-hover': '#000',
                    },
                    default: {
                      '--font-default-size-default': '0.95rem',
                    },
                  },
                },
                link: {
                  href: '#',
                  type: 'internal',
                  target: '_self',
                },
                color: {
                  data: {
                    hover: {
                      '--color-default-hover': '#000',
                    },
                    active: {
                      '--color-default-active': '#000',
                    },
                    default: {
                      '--color-default-default': '#000',
                    },
                  },
                },
                border: {
                  meta: {
                    dataType: 'border-style',
                  },
                  data: {
                    hover: {
                      '--border-default-color-hover': 'transparent',
                    },
                    default: {
                      '--border-default-radius-top-left-default': '100px',
                      '--border-default-radius-top-right-default': '100px',
                      '--border-default-radius-bottom-left-default': '100px',
                      '--border-default-radius-bottom-right-default': '100px',
                    },
                  },
                },
                padding: {
                  data: {
                    '--padding-default-top': '0.5rem',
                    '--padding-default-left': '0.5rem',
                    '--padding-default-right': '0.5rem',
                    '--padding-default-bottom': '0.5rem',
                  },
                },
                children: textToLexical('Linkedin'),
                components: {
                  icon: {
                    meta: {
                      dataType: 'icon-component',
                    },
                    gap: {
                      data: {
                        '--column-gap-default': '8px',
                      },
                    },
                    responsiveConfig: {
                      default: {
                        default: {
                          name: 'LinkedinLogo',
                          provider: 'phosphor',
                        },
                      },
                    },
                    mode: 'only',
                    width: {
                      data: {
                        '--width-default': '24px',
                      },
                    },
                  },
                },
                backgroundColor: {
                  data: {
                    hover: {
                      '--background-color-default-hover': 'rgba(0, 0, 0, 0.08)',
                    },
                    active: {
                      '--background-color-default-active': 'transparent',
                    },
                    default: {
                      '--background-color-default-default': 'transparent',
                    },
                  },
                },
              },
              {
                id: randomString(8),
                meta: {
                  dataType: 'button-component',
                },
                font: {
                  meta: {
                    dataType: 'font-style',
                  },
                  data: {
                    hover: {
                      '--font-default-text-decoration-line-hover': 'underline',
                      '--font-default-text-decoration-color-hover': '#000',
                    },
                    default: {
                      '--font-default-size-default': '0.95rem',
                    },
                  },
                },
                link: {
                  href: '#',
                  type: 'internal',
                  target: '_self',
                },
                color: {
                  data: {
                    hover: {
                      '--color-default-hover': '#000',
                    },
                    active: {
                      '--color-default-active': '#000',
                    },
                    default: {
                      '--color-default-default': '#000',
                    },
                  },
                },
                border: {
                  meta: {
                    dataType: 'border-style',
                  },
                  data: {
                    hover: {
                      '--border-default-color-hover': 'transparent',
                    },
                    default: {
                      '--border-default-radius-top-left-default': '100px',
                      '--border-default-radius-top-right-default': '100px',
                      '--border-default-radius-bottom-left-default': '100px',
                      '--border-default-radius-bottom-right-default': '100px',
                    },
                  },
                },
                padding: {
                  data: {
                    '--padding-default-top': '0.5rem',
                    '--padding-default-left': '0.5rem',
                    '--padding-default-right': '0.5rem',
                    '--padding-default-bottom': '0.5rem',
                  },
                },
                children: textToLexical('Instagram'),
                components: {
                  icon: {
                    meta: {
                      dataType: 'icon-component',
                    },
                    gap: {
                      data: {
                        '--column-gap-default': '8px',
                      },
                    },
                    mode: 'only',
                    responsiveConfig: {
                      default: {
                        default: {
                          name: 'InstagramLogo',
                          provider: 'phosphor',
                        },
                      },
                    },
                    width: {
                      data: {
                        '--width-default': '24px',
                      },
                    },
                  },
                },
                backgroundColor: {
                  data: {
                    hover: {
                      '--background-color-default-hover': 'rgba(0, 0, 0, 0.08)',
                    },
                    active: {
                      '--background-color-default-active': 'transparent',
                    },
                    default: {
                      '--background-color-default-default': 'transparent',
                    },
                  },
                },
              },
              {
                id: randomString(8),
                meta: {
                  dataType: 'button-component',
                },
                font: {
                  meta: {
                    dataType: 'font-style',
                  },
                  data: {
                    hover: {
                      '--font-default-text-decoration-line-hover': 'underline',
                      '--font-default-text-decoration-color-hover': '#000',
                    },
                    default: {
                      '--font-default-size-default': '0.95rem',
                    },
                  },
                },
                link: {
                  href: '#',
                  type: 'internal',
                  target: '_self',
                },
                color: {
                  data: {
                    hover: {
                      '--color-default-hover': '#000',
                    },
                    active: {
                      '--color-default-active': '#000',
                    },
                    default: {
                      '--color-default-default': '#000',
                    },
                  },
                },
                border: {
                  meta: {
                    dataType: 'border-style',
                  },
                  data: {
                    hover: {
                      '--border-default-color-hover': 'transparent',
                    },
                    default: {
                      '--border-default-radius-top-left-default': '100px',
                      '--border-default-radius-top-right-default': '100px',
                      '--border-default-radius-bottom-left-default': '100px',
                      '--border-default-radius-bottom-right-default': '100px',
                    },
                  },
                },
                padding: {
                  data: {
                    '--padding-default-top': '0.5rem',
                    '--padding-default-left': '0.5rem',
                    '--padding-default-right': '0.5rem',
                    '--padding-default-bottom': '0.5rem',
                  },
                },
                children: textToLexical('X'),
                components: {
                  icon: {
                    meta: {
                      dataType: 'icon-component',
                    },
                    gap: {
                      data: {
                        '--column-gap-default': '8px',
                      },
                    },
                    mode: 'only',
                    responsiveConfig: {
                      default: {
                        default: {
                          name: 'x',
                          provider: 'scene',
                        },
                      },
                    },
                    width: {
                      data: {
                        '--width-default': '24px',
                      },
                    },
                  },
                },
                backgroundColor: {
                  data: {
                    hover: {
                      '--background-color-default-hover': 'rgba(0, 0, 0, 0.08)',
                    },
                    active: {
                      '--background-color-default-active': 'transparent',
                    },
                    default: {
                      '--background-color-default-default': 'transparent',
                    },
                  },
                },
              },
              {
                id: randomString(8),
                meta: {
                  dataType: 'button-component',
                },
                font: {
                  meta: {
                    dataType: 'font-style',
                  },
                  data: {
                    hover: {
                      '--font-default-text-decoration-line-hover': 'underline',
                      '--font-default-text-decoration-color-hover': '#000',
                    },
                    default: {
                      '--font-default-size-default': '0.95rem',
                    },
                  },
                },
                link: {
                  href: '#',
                  type: 'internal',
                  target: '_self',
                },
                color: {
                  data: {
                    hover: {
                      '--color-default-hover': '#000',
                    },
                    active: {
                      '--color-default-active': '#000',
                    },
                    default: {
                      '--color-default-default': '#000',
                    },
                  },
                },
                border: {
                  meta: {
                    dataType: 'border-style',
                  },
                  data: {
                    hover: {
                      '--border-default-color-hover': 'transparent',
                    },
                    default: {
                      '--border-default-radius-top-left-default': '100px',
                      '--border-default-radius-top-right-default': '100px',
                      '--border-default-radius-bottom-left-default': '100px',
                      '--border-default-radius-bottom-right-default': '100px',
                    },
                  },
                },
                padding: {
                  data: {
                    '--padding-default-top': '0.5rem',
                    '--padding-default-left': '0.5rem',
                    '--padding-default-right': '0.5rem',
                    '--padding-default-bottom': '0.5rem',
                  },
                },
                children: textToLexical('Youtube'),
                components: {
                  icon: {
                    meta: {
                      dataType: 'icon-component',
                    },
                    gap: {
                      data: {
                        '--column-gap-default': '8px',
                      },
                    },
                    responsiveConfig: {
                      default: {
                        default: {
                          name: 'YoutubeLogo',
                          provider: 'phosphor',
                        },
                      },
                    },
                    mode: 'only',
                    width: {
                      data: {
                        '--width-default': '24px',
                      },
                    },
                  },
                },
                backgroundColor: {
                  data: {
                    hover: {
                      '--background-color-default-hover': 'rgba(0, 0, 0, 0.08)',
                    },
                    active: {
                      '--background-color-default-active': 'transparent',
                    },
                    default: {
                      '--background-color-default-default': 'transparent',
                    },
                  },
                },
              },
            ],
            gap: {
              data: {
                '--row-gap-default': '0rem',
                '--column-gap-default': '1rem',
              },
            },
            flow: {
              data: {
                '--flow-default': 'row',
              },
            },
            verticalAlignment: {
              data: {
                '--vertical-alignment-default': 'center',
              },
            },
          },
          configEditor: {
            additionalFormFields: {
              layout: {
                formFieldRenderer:
                  buttonContainerLayoutAdditionalFormFieldRenderer,
              },
            },
            formProps: {
              name: 'componentArray',
              registerValues:
                containerComponentFormRegisterValuesFactory('componentArray'),
            },
          },
        },
      },
      defaultConfig: {
        show: true,
        meta: {
          dataType: 'container-component',
        },
        gap: {
          data: {
            [`--row-gap-default`]: '1rem',
          },
        },
        horizontalAlignment: {
          data: {
            '--horizontal-alignment-xs': 'center',
            '--horizontal-alignment-default': 'flex-end',
          },
        },
      },
      name: 'Column 3',
      path: 'components.column3',
      configEditor: {
        formProps: {
          name: 'group',
          registerValues: containerComponentFormRegisterValuesFactory('group'),
        },
      },
      type: 'container',
    },
    column4: {
      components: generateColumnComponents('column4', {
        shouldShow: {
          logo: false,
          upperText: false,
          social: false,
          bottomText: false,
          menuTitle: true,
          menu: true,
        },
        menuItemsChildren: [
          'Easy to read',
          'Clear & Concise',
          'Descriptive',
          'Consistent',
        ],
      }),
      defaultConfig: {
        show: false,
        meta: {
          dataType: 'container-component',
        },
        gap: {
          data: {
            [`--row-gap-default`]: '1rem',
          },
        },
        horizontalAlignment: {
          data: {
            '--horizontal-alignment-default': 'flex-start',
            '--horizontal-alignment-xs': 'center',
          },
        },
      },
      name: 'Column 4',
      path: 'components.column4',
      configEditor: {
        formProps: {
          name: 'group',
          registerValues: containerComponentFormRegisterValuesFactory('group'),
        },
      },
      type: 'container',
    },
    divider: {
      name: 'Divider',
      path: 'components.divider',
      defaultConfig: {
        meta: {
          dataType: 'divider-component',
        },
      },
      type: 'divider',
    },
    secondaryMenu: {
      name: 'Secondary menu',
      path: 'components.secondaryMenu',
      components: {
        items: {
          isArrayComponent: true,
          path: 'items',
          name: 'Link',
          type: 'navLink',
          disableToggleShow: true,
          sampleFactory: () => ({
            ...navLinkSampleFactory(),
            children: textToLexical('Submenu item', 'array-item'),
          }),
        },
      },
      inlineEditors: [
        {
          editorType: 'inlineNavLink',
          editorPath: 'components.secondaryMenu.items',
          editorOptions: {
            sampleFactory: () => ({
              ...navLinkSampleFactory(),
              children: textToLexical('Secondary menu item', 'array-item'),
            }),
          },
        },
      ],
      defaultConfig: {
        meta: {
          dataType: 'container-component',
        },
        items: [
          {
            ...navLinkSampleFactory(),
            children: textToLexical('Secondary menu item', 'array-item'),
          },
          {
            ...navLinkSampleFactory(),
            children: textToLexical('Secondary menu item', 'array-item'),
          },
        ],
        margin: {
          data: {
            '--margin-default-bottom': '1rem',
          },
        },
        flow: {
          data: {
            '--flow-default': 'column',
          },
        },
        show: false,
      },
      configEditor: {
        additionalFormFields: {
          layout: {
            formFieldRenderer: buttonContainerLayoutAdditionalFormFieldRenderer,
          },
        },
        formProps: {
          name: 'componentArray',
          registerValues:
            containerComponentFormRegisterValuesFactory('componentArray'),
        },
      },
      type: 'container',
    },
    social: {
      path: 'components.social',
      name: 'Social buttons',
      type: 'social',
      disableEditing: true,
    },
    copyright: {
      name: 'Copyright',
      path: 'components.copyright',
      inlineEditors: [
        {
          editorType: 'inlineText',
          editorPath: 'components.copyright',
        },
      ],
      defaultConfig: {
        meta: {
          dataType: 'text-component',
        },
        show: true,
        children: textToLexical(
          `© ${new Date().getFullYear()}, All Rights Reserved.`,
        ),
        as: 'p',
        horizontalAlignment: {
          data: {
            '--horizontal-alignment-default': 'center',
          },
        },
        font: {
          meta: {
            dataType: 'font-style',
          },
          data: {
            default: {
              '--font-default-size-default': '0.95rem',
            },
          },
        },
      },
      type: 'text',
    },
  }

export const footerVariant3BlockMeta: BlockMeta<FooterVariant3Block> = {
  defaultConfig: {},
  configEditor: {},
}

export const footerVariant3DefaultConfig =
  blockDefaultConfigFactory<FooterVariant3Block>({
    componentMeta: footerVariant3ComponentMeta,
    blockMeta: footerVariant3BlockMeta,
    version: 1,
  })
