import { VideoComponent } from '@sceneio/schemas/lib/types'
import { ComponentMeta } from '../componentCommonTypes'
import { componentDefaultConfigFactory } from '../helpers'
import { videoDefaults } from '../Video/videoMeta'
import { mergeDeepRight, randomString } from '@sceneio/tools'
import { textToLexical } from '@sceneio/wysiwyg-utils'

const VIDEO_DEFAULTS = videoDefaults.config

export const mediaComponentMeta: ComponentMeta<'media'> = {
  defaultConfig: {
    format: 'image',
    meta: {
      dataType: 'media-component',
    },
    components: {
      image: {
        meta: {
          dataType: 'image-component',
        },
        responsiveConfig: {
          default: {
            src: 'https://assets.scene.io/global/placeholders/media-1200x900.png',
          },
        },
      },
      video: {
        ...(mergeDeepRight(VIDEO_DEFAULTS, {
          isBackgroundVideo: false,
          showPlayButton: true,
          showPoster: false,
          showControls: true,
        }) as VideoComponent),
      },
      credits: {
        children: textToLexical('Provide image credits'),
        as: 'p',
        meta: {
          dataType: 'text-component',
        },
        font: {
          meta: {
            dataType: 'font-style',
          },
          data: {
            default: {
              '--font-default-size-default': '0.75rem',
            },
          },
        },
        color: {
          data: {
            default: {
              '--color-default-default': '#fff',
            },
          },
        },
        textShadow: {
          meta: {
            dataType: 'text-shadow-style',
          },
          data: {
            responsiveConfig: {
              default: {
                default: [
                  {
                    id: randomString(8),
                    x: '1px',
                    y: '1px',
                    blur: '3px',
                    color: 'rgba(0,0,0,0.3)',
                  },
                ],
              },
            },
          },
        },
        backgroundColor: {
          data: {
            default: {
              '--background-color-default-default': 'rgba(0, 0, 0, 0.1)',
            },
          },
        },
        padding: {
          data: {
            '--padding-default-bottom': '0.1rem',
            '--padding-default-top': '0.1rem',
            '--padding-default-left': '0.5rem',
            '--padding-default-right': '0.5rem',
          },
        },
        linkStyle: {
          data: {
            hover: {
              '--link-default-text-decoration-color-hover': '#fff',
            },
          },
        },
        isAnimated: false,
        show: false,
      },
      caption: {
        children: textToLexical(
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique. Duis cursus, mi quis viverra ornare, eros dolor interdum nulla, ut commodo diam libero vitae erat.',
        ),
        as: 'p',
        meta: {
          dataType: 'text-component',
        },
        font: {
          meta: {
            dataType: 'font-style',
          },
          data: {
            default: {
              '--font-default-size-default': '0.95rem',
              '--font-default-style-default': 'italic',
            },
          },
        },
        color: {
          data: {
            default: {
              '--color-default-default': '#b5b3b3',
            },
          },
        },
        linkStyle: {
          data: {
            default: {
              '--link-default-color-default': '#000',
              '--link-default-text-decoration-color-default': '#000',
            },
            hover: {
              '--link-default-color-hover': '#000',
              '--link-default-text-decoration-color-hover': '#000',
            },
          },
        },
        margin: {
          data: {
            '--margin-default-bottom': 0,
            '--margin-default-top': '0.25rem',
            '--margin-default-left': '0.5rem',
            '--margin-default-right': '0.5rem',
          },
        },
        show: false,
        isAnimated: true,
      },
    },
    animation: '',
    show: true,
  },
}

export const mediaDefaults = componentDefaultConfigFactory<'media'>({
  componentMeta: mediaComponentMeta,
})
