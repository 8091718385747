import { InferType, object, string } from 'yup'
import { cssLengthUnitSchema } from './common/cssLengthUnitSchema'

const borderLineStyleSchema = string().oneOf([
  'solid',
  'double',
  'dotted',
  'dashed',
  'none',
  'wavy',
])

export const borderStyleDataSchema = object({
  default: object({
    '--border-default-color-default': string(),
    '--border-default-width-default': cssLengthUnitSchema,
    '--border-default-radius-top-left-default': cssLengthUnitSchema,
    '--border-default-radius-top-right-default': cssLengthUnitSchema,
    '--border-default-radius-bottom-left-default': cssLengthUnitSchema,
    '--border-default-radius-bottom-right-default': cssLengthUnitSchema,
    '--border-default-style-default': borderLineStyleSchema,
    '--border-default-top-style-default': borderLineStyleSchema,
    '--border-default-right-style-default': borderLineStyleSchema,
    '--border-default-bottom-style-default': borderLineStyleSchema,
    '--border-default-left-style-default': borderLineStyleSchema,
    '--border-xs-color-default': string(),
    '--border-xs-width-default': cssLengthUnitSchema,
    '--border-xs-radius-top-left-default': cssLengthUnitSchema,
    '--border-xs-radius-top-right-default': cssLengthUnitSchema,
    '--border-xs-radius-bottom-left-default': cssLengthUnitSchema,
    '--border-xs-radius-bottom-right-default': cssLengthUnitSchema,
    '--border-xs-style-default': borderLineStyleSchema,
    '--border-xs-top-style-default': borderLineStyleSchema,
    '--border-xs-right-style-default': borderLineStyleSchema,
    '--border-xs-bottom-style-default': borderLineStyleSchema,
    '--border-xs-left-style-default': borderLineStyleSchema,
    '--border-md-color-default': string(),
    '--border-md-width-default': cssLengthUnitSchema,
    '--border-md-radius-top-left-default': cssLengthUnitSchema,
    '--border-md-radius-top-right-default': cssLengthUnitSchema,
    '--border-md-radius-bottom-left-default': cssLengthUnitSchema,
    '--border-md-radius-bottom-right-default': cssLengthUnitSchema,
    '--border-md-style-default': borderLineStyleSchema,
    '--border-md-top-style-default': borderLineStyleSchema,
    '--border-md-right-style-default': borderLineStyleSchema,
    '--border-md-bottom-style-default': borderLineStyleSchema,
    '--border-md-left-style-default': borderLineStyleSchema,
    '--border-lg-color-default': string(),
    '--border-lg-width-default': cssLengthUnitSchema,
    '--border-lg-radius-top-left-default': cssLengthUnitSchema,
    '--border-lg-radius-top-right-default': cssLengthUnitSchema,
    '--border-lg-radius-bottom-left-default': cssLengthUnitSchema,
    '--border-lg-radius-bottom-right-default': cssLengthUnitSchema,
    '--border-lg-style-default': borderLineStyleSchema,
    '--border-lg-top-style-default': borderLineStyleSchema,
    '--border-lg-right-style-default': borderLineStyleSchema,
    '--border-lg-bottom-style-default': borderLineStyleSchema,
    '--border-lg-left-style-default': borderLineStyleSchema,
  })
    .noUnknown()
    .optional(),
  hover: object({
    '--border-default-color-hover': string(),
    '--border-default-width-hover': cssLengthUnitSchema,
    '--border-default-radius-top-left-hover': cssLengthUnitSchema,
    '--border-default-radius-top-right-hover': cssLengthUnitSchema,
    '--border-default-radius-bottom-left-hover': cssLengthUnitSchema,
    '--border-default-radius-bottom-right-hover': cssLengthUnitSchema,
    '--border-default-style-hover': borderLineStyleSchema,
    '--border-default-top-style-hover': borderLineStyleSchema,
    '--border-default-right-style-hover': borderLineStyleSchema,
    '--border-default-bottom-style-hover': borderLineStyleSchema,
    '--border-default-left-style-hover': borderLineStyleSchema,
    '--border-xs-color-hover': string(),
    '--border-xs-width-hover': cssLengthUnitSchema,
    '--border-xs-radius-top-left-hover': cssLengthUnitSchema,
    '--border-xs-radius-top-right-hover': cssLengthUnitSchema,
    '--border-xs-radius-bottom-left-hover': cssLengthUnitSchema,
    '--border-xs-radius-bottom-right-hover': cssLengthUnitSchema,
    '--border-xs-style-hover': borderLineStyleSchema,
    '--border-xs-top-style-hover': borderLineStyleSchema,
    '--border-xs-right-style-hover': borderLineStyleSchema,
    '--border-xs-bottom-style-hover': borderLineStyleSchema,
    '--border-xs-left-style-hover': borderLineStyleSchema,
    '--border-md-color-hover': string(),
    '--border-md-width-hover': cssLengthUnitSchema,
    '--border-md-radius-top-left-hover': cssLengthUnitSchema,
    '--border-md-radius-top-right-hover': cssLengthUnitSchema,
    '--border-md-radius-bottom-left-hover': cssLengthUnitSchema,
    '--border-md-radius-bottom-right-hover': cssLengthUnitSchema,
    '--border-md-style-hover': borderLineStyleSchema,
    '--border-md-top-style-hover': borderLineStyleSchema,
    '--border-md-right-style-hover': borderLineStyleSchema,
    '--border-md-bottom-style-hover': borderLineStyleSchema,
    '--border-md-left-style-hover': borderLineStyleSchema,
    '--border-lg-color-hover': string(),
    '--border-lg-width-hover': cssLengthUnitSchema,
    '--border-lg-radius-top-left-hover': cssLengthUnitSchema,
    '--border-lg-radius-top-right-hover': cssLengthUnitSchema,
    '--border-lg-radius-bottom-left-hover': cssLengthUnitSchema,
    '--border-lg-radius-bottom-right-hover': cssLengthUnitSchema,
    '--border-lg-style-hover': borderLineStyleSchema,
    '--border-lg-top-style-hover': borderLineStyleSchema,
    '--border-lg-right-style-hover': borderLineStyleSchema,
    '--border-lg-bottom-style-hover': borderLineStyleSchema,
    '--border-lg-left-style-hover': borderLineStyleSchema,
  })
    .noUnknown()
    .optional(),
  active: object({
    '--border-default-color-active': string(),
    '--border-default-width-active': cssLengthUnitSchema,
    '--border-default-radius-top-left-active': cssLengthUnitSchema,
    '--border-default-radius-top-right-active': cssLengthUnitSchema,
    '--border-default-radius-bottom-left-active': cssLengthUnitSchema,
    '--border-default-radius-bottom-right-active': cssLengthUnitSchema,
    '--border-default-style-active': borderLineStyleSchema,
    '--border-default-top-style-active': borderLineStyleSchema,
    '--border-default-right-style-active': borderLineStyleSchema,
    '--border-default-bottom-style-active': borderLineStyleSchema,
    '--border-default-left-style-active': borderLineStyleSchema,
    '--border-xs-color-active': string(),
    '--border-xs-width-active': cssLengthUnitSchema,
    '--border-xs-radius-top-left-active': cssLengthUnitSchema,
    '--border-xs-radius-top-right-active': cssLengthUnitSchema,
    '--border-xs-radius-bottom-left-active': cssLengthUnitSchema,
    '--border-xs-radius-bottom-right-active': cssLengthUnitSchema,
    '--border-xs-style-active': borderLineStyleSchema,
    '--border-xs-top-style-active': borderLineStyleSchema,
    '--border-xs-right-style-active': borderLineStyleSchema,
    '--border-xs-bottom-style-active': borderLineStyleSchema,
    '--border-xs-left-style-active': borderLineStyleSchema,
    '--border-md-color-active': string(),
    '--border-md-width-active': cssLengthUnitSchema,
    '--border-md-radius-top-left-active': cssLengthUnitSchema,
    '--border-md-radius-top-right-active': cssLengthUnitSchema,
    '--border-md-radius-bottom-left-active': cssLengthUnitSchema,
    '--border-md-radius-bottom-right-active': cssLengthUnitSchema,
    '--border-md-style-active': borderLineStyleSchema,
    '--border-md-top-style-active': borderLineStyleSchema,
    '--border-md-right-style-active': borderLineStyleSchema,
    '--border-md-bottom-style-active': borderLineStyleSchema,
    '--border-md-left-style-active': borderLineStyleSchema,
    '--border-lg-color-active': string(),
    '--border-lg-width-active': cssLengthUnitSchema,
    '--border-lg-radius-top-left-active': cssLengthUnitSchema,
    '--border-lg-radius-top-right-active': cssLengthUnitSchema,
    '--border-lg-radius-bottom-left-active': cssLengthUnitSchema,
    '--border-lg-radius-bottom-right-active': cssLengthUnitSchema,
    '--border-lg-style-active': borderLineStyleSchema,
    '--border-lg-top-style-active': borderLineStyleSchema,
    '--border-lg-right-style-active': borderLineStyleSchema,
    '--border-lg-bottom-style-active': borderLineStyleSchema,
    '--border-lg-left-style-active': borderLineStyleSchema,
  })
    .noUnknown()
    .optional(),
}).noUnknown()

export const borderStyleSchema = object({
  data: borderStyleDataSchema,
  meta: object({
    dataType: string().oneOf(['border-style']).required(),
  })
    .noUnknown()
    .required(),
}).noUnknown()

export type BorderStyle = InferType<typeof borderStyleSchema>
