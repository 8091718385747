import { BlockComponent, CTABanner3Block } from '@sceneio/schemas/lib/types'
import { randomString } from '@sceneio/tools'
import { containerComponentFormRegisterValuesFactory } from '../../../components/Container/form/ContainerComponentForm'
import { DEFAULT_HEADINGS_CSS_VARIABLES } from '../../../components/Text/constants/globalTextCssVariables'
import { BlockComponentMeta, BlockMeta } from '../../blockCommonTypes'
import { blockDefaultConfigFactory } from '../../helpers'
import { blockContainerDefaults } from '../../../components/BlockContainer/blockContainerMeta'
import { DEFAULT_OUTLINE_BUTTON_CSS_VARIABLES } from '../../../components/Button/constants/globalButtonCssVariables'
import { textToLexical } from '@sceneio/wysiwyg-utils'
import {
  blockLayoutAdditionalFormFieldRenderer,
  buttonContainerLayoutAdditionalFormFieldRenderer,
} from '../../Hero/SplitHero/form/additionalFormFieldRenderers'

const BLOCK_CONTAINER_DEFAULTS = blockContainerDefaults.config

export const buttonSampleFactory = (data = {}) => ({
  id: randomString(8),
  meta: {
    dataType: 'button-component',
  },
  font: {
    meta: {
      dataType: 'font-style',
    },
  },
  children: textToLexical('Button', 'array-item'),
  width: {
    data: {
      '--width-default': 'auto',
      '--width-xs': '100%',
    },
  },
  ...data,
})

export const ctaBanner3ComponentMeta: BlockComponentMeta<CTABanner3Block> = {
  block: {
    path: 'components.block',
    name: 'Block',
    type: 'block',
    defaultConfig: {
      meta: {
        dataType: 'block-component',
      },
      swapOrder: false,
      columnRatio: 6,
    } as BlockComponent & {
      swapOrder?: boolean
      columnRatio: number
    },
    configEditor: {
      additionalFormFields: {
        layout: {
          formFieldRenderer: blockLayoutAdditionalFormFieldRenderer,
        },
      },
    },
  },
  blockContainer: {
    path: 'components.block.blockContainer',
    name: 'Container',
    disableToggleShow: true,
    defaultConfig: {
      meta: {
        dataType: 'block-container-component',
      },
      responsiveConfig: {
        default: {
          maxWidth: 'screen',
          rowGap: '1.5rem',
          columnGap: '2rem',
        },
      },
      padding: {
        data: {
          ...BLOCK_CONTAINER_DEFAULTS.padding?.data,
          '--padding-xs-left': '2rem',
          '--padding-xs-right': '2rem',
        },
      },
    },
    type: 'blockContainer',
  },
  tagline: {
    path: 'components.tagline',
    name: 'Tagline',
    inlineEditors: [
      {
        editorType: 'inlineText',
        editorPath: 'components.tagline',
      },
    ],
    defaultConfig: {
      meta: {
        dataType: 'text-component',
      },
      children: textToLexical('Tagline'),
      as: 'p',
      margin: {
        data: {
          '--margin-default-bottom': '1rem',
        },
      },
      font: {
        meta: {
          dataType: 'font-style',
        },
        data: {
          default: {
            '--font-default-weight-default': 'bold',
          },
        },
      },
    },
    type: 'text',
  },
  heading: {
    path: 'components.heading',
    name: 'Heading',
    inlineEditors: [
      {
        editorType: 'inlineText',
        editorPath: 'components.heading',
      },
    ],
    defaultConfig: {
      meta: {
        dataType: 'text-component',
      },
      ...DEFAULT_HEADINGS_CSS_VARIABLES['h2'],
      children: textToLexical('Medium length section heading goes here', 'h2'),
      as: 'h2',
      show: true,
    },
    type: 'text',
  },
  text: {
    path: 'components.text',
    name: 'Text',
    inlineEditors: [
      {
        editorType: 'inlineText',
        editorPath: 'components.text',
        editorOptions: {
          include: ['table', 'list'],
        },
      },
    ],
    defaultConfig: {
      meta: {
        dataType: 'text-component',
      },
      font: {
        meta: {
          dataType: 'font-style',
        },
      },
      children: textToLexical(
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique. Duis cursus, mi quis viverra ornare, eros dolor interdum nulla, ut commodo diam libero vitae erat.',
      ),
      as: 'p',
      show: true,
    },
    type: 'text',
  },
  buttons: {
    path: 'components.buttons',
    components: {
      items: {
        isArrayComponent: true,
        path: 'items',
        name: 'Button',
        type: 'button',
        disableToggleShow: true,
        sampleFactory: () =>
          buttonSampleFactory({ ...DEFAULT_OUTLINE_BUTTON_CSS_VARIABLES }),
      },
    },
    inlineEditors: [
      {
        editorType: 'buttonEditor',
        editorPath: 'components.buttons.items',
        editorOptions: {
          isItemArray: true,
          sampleFactory: () =>
            buttonSampleFactory({ ...DEFAULT_OUTLINE_BUTTON_CSS_VARIABLES }),
        },
      },
    ],
    defaultConfig: {
      meta: {
        dataType: 'container-component',
      },
      items: [
        buttonSampleFactory({
          children: textToLexical('Button', 'array-item'),
        }),
        buttonSampleFactory({
          ...DEFAULT_OUTLINE_BUTTON_CSS_VARIABLES,
          children: textToLexical('2nd Button', 'array-item'),
        }),
      ],
      show: true,
      margin: {
        data: {
          '--margin-default-top': '1.5rem',
        },
      },
      padding: {
        data: {
          '--padding-default-top': '1rem',
        },
      },
      gap: {
        data: {
          [`--row-gap-default`]: '1rem',
          [`--column-gap-default`]: '1rem',
        },
      },
      flow: {
        data: {
          '--flow-default': 'row',
          '--flow-xs': 'column',
        },
      },
      horizontalAlignment: {
        data: {
          '--horizontal-alignment-default': 'flex-start',
        },
      },
      verticalAlignment: {
        data: {
          '--vertical-alignment-default': 'center',
        },
      },
    },
    name: 'Buttons',
    type: 'container',
    configEditor: {
      additionalFormFields: {
        layout: {
          formFieldRenderer: buttonContainerLayoutAdditionalFormFieldRenderer,
        },
      },
      formProps: {
        name: 'componentArray',
        registerValues:
          containerComponentFormRegisterValuesFactory('componentArray'),
      },
    },
  },
}

export const ctaBanner3BlockMeta: BlockMeta<CTABanner3Block> = {}

export const ctaBanner3DefaultConfig =
  blockDefaultConfigFactory<CTABanner3Block>({
    componentMeta: ctaBanner3ComponentMeta,
    blockMeta: ctaBanner3BlockMeta,
    version: 1,
  })
