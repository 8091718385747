import { materializeSnippets } from '@sceneio/snippets-tools'
import { BlockType } from './contentSliceTypes'
import { SnippetType } from '@sceneio/snippets-tools/lib/snippetsTypes'
import { MaterializeSnippetDataType } from '@sceneio/snippets-tools/lib/materializeSnippets'

export const materializeContentBlock = ({
  block,
  snippets,
}: {
  block: BlockType
  snippets: SnippetType[]
}) => {
  let materializedBlock = materializeSnippets({
    data: block as MaterializeSnippetDataType,
    snippets,
  })

  const isReusableContentBlockDraft =
    Object.keys(block?.reusableContentBlockDraft || {}).length > 0

  if (isReusableContentBlockDraft) {
    materializedBlock = {
      ...materializedBlock,
      reusableContentBlockDraft: materializeSnippets({
        data: (block?.reusableContentBlockDraft ||
          {}) as MaterializeSnippetDataType,
        snippets: snippets,
      }),
    }
  }

  return materializedBlock
}
