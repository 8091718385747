import { ComponentMeta } from '../componentCommonTypes'
import { componentDefaultConfigFactory } from '../helpers'
import { DEFAULT_HEADINGS_CSS_VARIABLES } from '../Text/constants/globalTextCssVariables'
import { imageDefaults } from '../Image/imageMeta'
import { textToLexical } from '@sceneio/wysiwyg-utils'

const IMAGE_DEFAULTS = imageDefaults.config

export const galleryItemComponentMeta: ComponentMeta<'galleryItem'> = {
  defaultConfig: {
    meta: {
      dataType: 'gallery-item-component',
    },
    components: {
      image: {
        ...IMAGE_DEFAULTS,
        transformationOptions: { cover: '1:1' },
        responsiveConfig: {
          default: {
            src: 'https://assets.scene.io/global/placeholders/media-1200x900.png',
          },
        },
      },
      heading: {
        meta: {
          dataType: 'text-component',
        },
        ...DEFAULT_HEADINGS_CSS_VARIABLES['h4'],
        children: textToLexical('Short heading', 'h4'),
        as: 'h4',
        padding: {
          data: {
            '--padding-default-bottom': '0.5rem',
            '--padding-default-top': '1rem',
            '--padding-default-left': '0.5rem',
            '--padding-default-right': '0.5rem',
          },
        },
        show: false,
      },
      description: {
        meta: {
          dataType: 'text-component',
        },
        font: {
          meta: {
            dataType: 'font-style',
          },
        },
        children: textToLexical('Lorem ipsum dolor sit amet, consectetur.'),
        as: 'p',
        padding: {
          data: {
            '--padding-default-top': '0rem',
            '--padding-default-bottom': '0.5rem',
            '--padding-default-left': '0.5rem',
            '--padding-default-right': '0.5rem',
          },
        },
        show: false,
      },
    },
  },
}

export const galleryItemDefaults = componentDefaultConfigFactory<'galleryItem'>(
  {
    componentMeta: galleryItemComponentMeta,
  },
)
