import clsx from 'clsx'
import React, { CSSProperties, ReactNode } from 'react'
import * as Form from '@radix-ui/react-form'
import { ErrorMessage } from '../ErrorMessage/ErrorMessage'
import { ClearButton } from '../ClearButton/ClearButton'
import { Icon } from '@sceneio/ui-icons'
import { useFormContext } from '../../form/context/FormContext'
import { InvalidReferenceIcon } from '../../components/InvalidReferenceIcon/InvalidReferenceIcon'

const FILENAME_REGEX = new RegExp(/([^\/.]+)$|([^\/]+)(\.[^\/.]+)$/g)

export type RHFMediaInputInteractionCallbackArgs = {
  fieldName: string
  sourceType: 'IMAGE' | 'VIDEO'
}

export type RHFMediaInputPropType = {
  label?: ReactNode | string
  name: string
  className?: string
  style?: CSSProperties
  onMediaAddClick?: (data: RHFMediaInputInteractionCallbackArgs) => void
  hidden?: boolean
  mediaType?: 'image' | 'video'
  isClearable?: boolean
}

export const RHFMediaInput = ({
  hidden,
  className,
  label,
  style,
  onMediaAddClick,
  name,
  mediaType = 'image',
  isClearable = false,
}: RHFMediaInputPropType) => {
  const { values, placeholders, error, watch, forceBreakpoint, meta } =
    useFormContext({ name })
  const src = watch(name, placeholders[name] || '')

  const isInvalidReference = meta?.invalidReference

  const containerClasses = clsx(
    'sc-rhf-media-input tw-container tw-text-xs',
    {
      'tw-hidden': hidden,
    },
    className,
  )

  const fileName = src.match(FILENAME_REGEX)?.[0]

  return (
    <Form.Field className={containerClasses} style={style} name={name}>
      <Form.Label
        className={clsx(
          'tw-bg-form-field-bg tw-flex tw-flex-col tw-rounded tw-px-2 tw-py-2 hover:tw-outline hover:tw-outline-1 hover:tw-outline-offset-[-1px] hover:tw-outline-border-color focus-within:tw-outline-border-color focus-within:tw-outline-1 focus-within:tw-outline-offset-[-1px] focus-within:tw-outline',
          {
            'tw-bg-white': forceBreakpoint && !name.includes(forceBreakpoint),
          },
        )}
      >
        {label && (
          <span className={'tw-text-label-color tw-py-1'}>{label}</span>
        )}
        <button
          tabIndex={1}
          className="tw-flex tw-gap-2 focus-visible:tw-outline-none"
          type="button"
          onClick={() =>
            onMediaAddClick &&
            onMediaAddClick({
              fieldName: name,
              sourceType: mediaType.toUpperCase() as 'IMAGE' | 'VIDEO',
            })
          }
        >
          {isInvalidReference && <InvalidReferenceIcon />}
          <div
            className="tw-w-[16px] tw-h-[16px] tw-shrink-0"
            style={{
              backgroundImage:
                "url('data:image/svg+xml;utf8,%3Csvg%20width%3D%226%22%20height%3D%226%22%20viewBox%3D%220%200%206%206%22%20fill%3D%22none%22%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%3E%3Cpath%20d%3D%22M0%200H3V3H0V0Z%22%20fill%3D%22%23E1E1E1%22/%3E%3Cpath%20d%3D%22M3%200H6V3H3V0Z%22%20fill%3D%22white%22/%3E%3Cpath%20d%3D%22M3%203H6V6H3V3Z%22%20fill%3D%22%23E1E1E1%22/%3E%3Cpath%20d%3D%22M0%203H3V6H0V3Z%22%20fill%3D%22white%22/%3E%3C/svg%3E%0A')",
            }}
          >
            {mediaType === 'image' && (
              <img
                className={clsx('tw-w-full tw-h-full', {
                  'tw-hidden': !src,
                })}
                src={src}
              />
            )}
            {mediaType === 'video' && (
              <div className="tw-w-full tw-h-full tw-bg-form-field-bg">
                <Icon provider="phosphor" icon="Play" />
              </div>
            )}
          </div>
          <span className="tw-truncate">{fileName || mediaType}</span>
        </button>
        {isClearable && values[name] && <ClearButton name={name} />}
      </Form.Label>
      {error && <ErrorMessage message={error?.message} />}
    </Form.Field>
  )
}
