import { InferType, bool, object, string } from 'yup'
import { styleSchema } from '../shared/styleSchema'
import { colorStyleSchema } from '../styles/colorStyleSchema'
import { marginStyleSchema } from '../styles/marginStyleSchema'
import { paddingStyleSchema } from '../styles/paddingStyleSchema'
import { thicknessStyleSchema } from '../styles/thicknessStyleSchema'
import { componentEditorSchema } from '../shared/componentEditorSchema'

export const dividerComponentSchema = object({
  meta: object({
    dataType: string().oneOf(['divider-component']).required(),
  })
    .noUnknown()
    .required(),
  thickness: thicknessStyleSchema.optional(),
  margin: marginStyleSchema.optional(),
  padding: paddingStyleSchema.optional(),
  color: colorStyleSchema.optional(),
  style: styleSchema,
  className: string(),
  isAnimated: bool(),
  show: bool(),
  editor: componentEditorSchema,
}).noUnknown()

export type DividerComponent = InferType<typeof dividerComponentSchema>

export type DividerComponentStyles = Pick<
  DividerComponent,
  'margin' | 'padding' | 'thickness' | 'color'
>
