import { RHFNumericInput } from '../../../atoms/Input/NumericInput/NumericInput'
import { RHFSelect } from '../../../atoms/Select/Select'
import { BreakpointType } from '../../../atoms/BreakpointDropdown/BreakpointDropdown'
import { StateType } from '../../../atoms/StateTabs/StateTabs'
import { RHFColorPicker } from '../../../atoms/ColorPicker/ColorPicker'
import { RHFBorderSelect } from '../../../atoms/BorderSelect/BorderSelect'
import { useFormContext } from '../../../form/context/FormContext'

type BreakpointBorderFieldsPropType = {
  valuesPath: string
  breakpoint: BreakpointType | 'default'
  state: StateType
}

export const BreakpointBorderFields = ({
  breakpoint,
  valuesPath,
  state,
}: BreakpointBorderFieldsPropType) => {
  const { values, setValues } = useFormContext({ name: valuesPath })
  const path = valuesPath ? `${valuesPath}.` : ''
  const onBorderStyleChange = (borderStyle: string) => {
    const updatedBorderSideStyle: Record<string, any> = {}
    const borderSides = ['top', 'right', 'bottom', 'left']
    borderSides.forEach((side) => {
      if (
        values[
          `${path}${state}.--border-${breakpoint}-${side}-style-${state}`
        ] !== 'none'
      ) {
        updatedBorderSideStyle[
          `${path}${state}.--border-${breakpoint}-${side}-style-${state}`
        ] = borderStyle
      }
    })

    updatedBorderSideStyle[
      `${path}${state}.--border-${breakpoint}-style-${state}`
    ] = borderStyle

    setValues(updatedBorderSideStyle)
  }

  const isClearable = false

  return (
    <div className="tw-flex tw-flex-col tw-gap-1">
      <RHFColorPicker
        name={`${path}${state}.--border-${breakpoint}-color-${state}`}
        isClearable={isClearable}
      />
      <div className="tw-flex tw-flex-row tw-gap-1">
        <RHFSelect
          name={`${path}${state}.--border-${breakpoint}-style-${state}`}
          className="tw-basis-4/12"
          options={[
            { value: 'solid', label: 'Solid' },
            { value: 'dashed', label: 'Dashed' },
            { value: 'dotted', label: 'Dotted' },
          ]}
          onChange={(value) => onBorderStyleChange(value)}
          isClearable={isClearable}
        />
        <RHFBorderSelect
          state={state}
          breakpoint={breakpoint}
          valuesPath={valuesPath}
          options={['top', 'right', 'bottom', 'left']}
          className="tw-basis-3/12"
        />
        <RHFNumericInput
          name={`${path}${state}.--border-${breakpoint}-width-${state}`}
          units={['px', 'rem', 'em']}
          className="tw-basis-5/12"
          isClearable={isClearable}
        />
      </div>
    </div>
  )
}
