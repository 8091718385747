import { textToLexical } from '@sceneio/wysiwyg-utils'
import {
  formDefaults,
  formFieldSampleFactory,
} from '../../../components/Form/formMeta'
import { BlockComponentMeta, BlockMeta } from '../../blockCommonTypes'
import { blockDefaultConfigFactory } from '../../helpers'
import { ContactFormBlock } from '@sceneio/schemas/lib/contentBlocks/schemas/contactFormBlockSchema'
import { DEFAULT_HEADINGS_CSS_VARIABLES } from '../../../components/Text/constants/globalTextCssVariables'

const FORM_DEFAULTS = formDefaults.config

export const contactFormComponentMeta: BlockComponentMeta<ContactFormBlock> = {
  block: {
    path: 'components.block',
    name: 'Block',
    type: 'block',
    defaultConfig: {
      meta: {
        dataType: 'block-component',
      },
      horizontalAlignment: {
        data: {
          '--horizontal-alignment-default': 'center',
        },
      },
      verticalAlignment: {
        data: {
          '--vertical-alignment-default': 'center',
        },
      },
    },
  },
  blockContainer: {
    path: 'components.block.blockContainer',
    name: 'Container',
    disableToggleShow: true,
    defaultConfig: {
      meta: {
        dataType: 'block-container-component',
      },
      responsiveConfig: {
        default: {
          maxWidth: '50%',
          rowGap: '20px',
        },
        md: {
          maxWidth: '75%',
        },
        xs: {
          maxWidth: '100%',
        },
      },
      padding: {
        data: {
          '--padding-default-bottom': '4rem',
          '--padding-default-top': '4rem',
        },
      },
      horizontalAlignment: {
        data: {
          '--horizontal-alignment-default': 'center',
        },
      },
    },
    type: 'blockContainer',
  },
  tagline: {
    path: 'components.tagline',
    name: 'Tagline',
    inlineEditors: [
      {
        editorType: 'inlineText',
        editorPath: 'components.tagline',
      },
    ],
    defaultConfig: {
      meta: {
        dataType: 'text-component',
      },
      children: textToLexical('Tagline'),
      as: 'p',
      font: {
        meta: {
          dataType: 'font-style',
        },
        data: {
          default: {
            '--font-default-weight-default': 'bold',
          },
        },
      },
      horizontalAlignment: {
        data: {
          '--horizontal-alignment-default': 'center',
        },
      },
      show: false,
    },
    type: 'text',
  },
  heading: {
    path: 'components.heading',
    name: 'Heading',
    inlineEditors: [
      {
        editorType: 'inlineText',
        editorPath: 'components.heading',
      },
    ],
    defaultConfig: {
      meta: {
        dataType: 'text-component',
      },
      ...DEFAULT_HEADINGS_CSS_VARIABLES['h2'],
      children: textToLexical('Contact us', 'h2'),
      as: 'h2',
      horizontalAlignment: {
        data: {
          '--horizontal-alignment-default': 'center',
        },
      },
    },
    type: 'text',
  },
  subheading: {
    path: 'components.subheading',
    name: 'Subheading',
    inlineEditors: [
      {
        editorType: 'inlineText',
        editorPath: 'components.subheading',
        editorOptions: {
          include: ['table', 'list'],
        },
      },
    ],
    defaultConfig: {
      meta: {
        dataType: 'text-component',
      },
      font: {
        meta: {
          dataType: 'font-style',
        },
      },
      children: textToLexical('Use the form below to send us a message'),
      as: 'p',
      horizontalAlignment: {
        data: {
          '--horizontal-alignment-default': 'center',
        },
      },
      padding: {
        data: {
          '--padding-default-bottom': '1rem',
        },
      },
    },
    type: 'text',
  },
  form: {
    type: 'form',
    path: 'components.form',
    name: 'Form',
    disableToggleShow: true,
    components: {
      items: {
        isArrayComponent: true,
        allowDelete: false,
        allowAdd: false,
        path: 'items',
        name: 'Input',
        type: 'formField',
        components: {
          label: {
            path: 'components.label',
            name: 'Label',
            type: 'text',
            inlineEditors: [
              {
                editorType: 'inlineText',
                editorPath: 'components.label',
                editorOptions: {
                  isNestedArrayItem: true,
                },
              },
            ],
          },
          field: {
            path: 'components.field',
            name: 'Input',
            type: 'input',
          },
          requiredValueError: {
            path: 'components.requiredValueError',
            name: 'Required value',
            type: 'text',
            inlineEditors: [
              {
                editorType: 'inlineText',
                editorPath: 'components.requiredValueError',
                editorOptions: {
                  isNestedArrayItem: true,
                },
              },
            ],
          },
          invalidPatternError: {
            path: 'components.invalidPatternError',
            name: 'Invalid pattern',
            type: 'text',
            inlineEditors: [
              {
                editorType: 'inlineText',
                editorPath: 'components.invalidPatternError',
                editorOptions: {
                  isNestedArrayItem: true,
                },
              },
            ],
          },
          minLengthError: {
            path: 'components.minLengthError',
            name: 'Min length',
            type: 'text',
            inlineEditors: [
              {
                editorType: 'inlineText',
                editorPath: 'components.minLengthError',
                editorOptions: {
                  isNestedArrayItem: true,
                },
              },
            ],
          },
          maxLengthError: {
            path: 'components.maxLengthError',
            name: 'Max length',
            type: 'text',
            inlineEditors: [
              {
                editorType: 'inlineText',
                editorPath: 'components.maxLengthError',
                editorOptions: {
                  isNestedArrayItem: true,
                },
              },
            ],
          },
        },
      },
      button: {
        path: 'components.button',
        name: 'Submit button',
        type: 'button',
        configEditor: {
          formProps: {
            excludedSettings: ['link'],
          },
        },
      },
      successMessage: {
        path: 'components.successMessage',
        name: 'Success message',
        type: 'text',
        inlineEditors: [
          {
            editorType: 'inlineText',
            editorPath: 'components.successMessage',
            editorOptions: {
              isNestedArrayItem: true,
            },
          },
        ],
      },
      errorMessage: {
        path: 'components.errorMessage',
        name: 'Error message',
        type: 'text',
        inlineEditors: [
          {
            editorType: 'inlineText',
            editorPath: 'components.errorMessage',
            editorOptions: {
              isNestedArrayItem: true,
            },
          },
        ],
      },
    },
    defaultConfig: {
      ...FORM_DEFAULTS,
      items: [
        formFieldSampleFactory({
          name: 'email',
          type: 'email',
          placeholder: '',
          label: 'Email',
        }),
        formFieldSampleFactory({
          name: 'subject',
          type: 'text',
          label: 'Subject',
          required: false,
          show: false,
        }),
        formFieldSampleFactory({
          name: 'firstName',
          type: 'text',
          placeholder: '',
          label: 'First name',
          required: false,
        }),
        formFieldSampleFactory({
          name: 'lastName',
          type: 'text',
          placeholder: '',
          label: 'Last name',
          required: false,
        }),
        formFieldSampleFactory({
          name: 'tel',
          type: 'tel',
          placeholder: '',
          label: 'Telephone number',
          required: false,
          show: false,
        }),
        formFieldSampleFactory({
          name: 'companyName',
          type: 'text',
          placeholder: '',
          label: 'Company',
          required: false,
          show: false,
        }),
        formFieldSampleFactory({
          name: 'message',
          type: 'textarea',
          placeholder: '',
          label: 'Message',
          required: false,
        }),
      ],
      flow: {
        data: {
          '--flow-default': 'column',
        },
      },
      components: {
        ...FORM_DEFAULTS.components,
        successMessage: {
          ...FORM_DEFAULTS.components.successMessage,
          horizontalAlignment: {
            data: {
              '--horizontal-alignment-default': 'center',
            },
          },
        },
        errorMessage: {
          ...FORM_DEFAULTS.components.errorMessage,
          children: textToLexical('Something went wrong. Please try again.'),
          horizontalAlignment: {
            data: {
              '--horizontal-alignment-default': 'center',
            },
          },
        },
      },
    },
  },
}

export const contactFormBlockMeta: BlockMeta<ContactFormBlock> = {
  defaultConfig: {},
  configEditor: {},
}

export const contactFormDefaultConfig =
  blockDefaultConfigFactory<ContactFormBlock>({
    componentMeta: contactFormComponentMeta,
    blockMeta: contactFormBlockMeta,
    version: 1,
  })
