import { Icon } from '@sceneio/ui-icons'
import { SnippetTypeToIcon } from '../../SnippetsDropdown/components/SnippetTypeToIcon'
import { OverridesBadge } from '../../OverridesBadge/OverridesBadge'
import { SnippetNameInput } from '../../SnippetNameInput/SnippetNameInput'
import { SnippetChangeDropdown } from '../../SnippetChangeDropdown/SnippetChangeDropdown'

export const SnippetColor = ({
  color,
  snippetName,
  snippetId,
  editSnippetMode,
  hasOverrides,
  showActionButtons,
  onDetachSnippet,
  onAssignSnippet,
  onEditClick,
  onResetClick,
}: {
  color: string
  snippetName: string
  snippetId: string
  editSnippetMode: boolean
  hasOverrides: boolean
  showActionButtons: boolean
  onDetachSnippet?: () => void
  onAssignSnippet?: (id: string, options?: { shouldReplace?: boolean }) => void
  onEditClick: () => void
  onResetClick: () => void
}) => {
  return (
    <div className="tw-flex tw-items-center tw-justify-between tw-rounded tw-px-1 tw-group/color-snippet">
      <div className="tw-flex tw-items-center tw-flex-1 tw-min-w-0 tw-py-1">
        {hasOverrides ? (
          <div className="tw-pe-2">
            <OverridesBadge />
          </div>
        ) : (
          <>
            {!editSnippetMode && (
              <SnippetTypeToIcon snippetType="ATOMIC_COLOR" value={color} />
            )}
          </>
        )}
        <SnippetChangeDropdown
          snippetsType={'ATOMIC_COLOR'}
          snippetId={snippetId}
          onAssignSnippet={onAssignSnippet}
        >
          <SnippetNameInput
            snippetName={snippetName}
            snippetId={snippetId}
            snippetType={'ATOMIC_COLOR'}
            editSnippetMode={editSnippetMode}
            hasOverrides={hasOverrides}
            className="disabled:tw-px-1 tw-mr-2 focus:tw-outline-none"
          />
        </SnippetChangeDropdown>
      </div>
      {showActionButtons && (
        <div className="tw-flex tw-items-center tw-gap-2">
          {hasOverrides ? (
            <button
              type="button"
              onClick={onResetClick}
              className="tw-p-1 tw-rounded-sm focus-visible:tw-outline-none tw-flex"
            >
              <Icon provider="phosphor" icon="ArrowClockwise" />
            </button>
          ) : (
            <button
              type="button"
              onClick={onEditClick}
              className="tw-p-1 tw-rounded-sm focus-visible:tw-outline-none tw-flex"
            >
              <Icon provider="phosphor" icon="PencilSimple" />
            </button>
          )}
          {Boolean(onDetachSnippet) && (
            <button
              type="button"
              onClick={onDetachSnippet}
              className="tw-p-1 tw-rounded-sm focus-visible:tw-outline-none"
            >
              <Icon provider="phosphor" icon="LinkBreak" />
            </button>
          )}
        </div>
      )}
    </div>
  )
}
