import { BlockComponent, HeroWithForm2Block } from '@sceneio/schemas/lib/types'
import { containerComponentFormRegisterValuesFactory } from '../../../components/Container/form/ContainerComponentForm'
import { mediaDefaults } from '../../../components/Media/mediaMeta'
import { DEFAULT_HEADINGS_CSS_VARIABLES } from '../../../components/Text/constants/globalTextCssVariables'
import { BlockComponentMeta, BlockMeta } from '../../blockCommonTypes'
import { blockDefaultConfigFactory } from '../../helpers'
import { blockLayoutAdditionalFormFieldRenderer } from '../SplitHero/form/additionalFormFieldRenderers'
import { textToLexical } from '@sceneio/wysiwyg-utils'
import {
  formDefaults,
  formFieldSampleFactory,
} from '../../../components/Form/formMeta'

const MEDIA_DEFAULTS = mediaDefaults.config
const FORM_DEFAULTS = formDefaults.config

export const heroWithForm2ComponentMeta: BlockComponentMeta<HeroWithForm2Block> =
  {
    block: {
      path: 'components.block',
      name: 'Block',
      type: 'block',
      defaultConfig: {
        swapOrder: true,
        columnRatio: 6,
        meta: {
          dataType: 'block-component',
        },
      } as BlockComponent & {
        swapOrder?: boolean
        columnRatio: number
      },
      configEditor: {
        additionalFormFields: {
          layout: {
            formFieldRenderer: blockLayoutAdditionalFormFieldRenderer,
          },
        },
      },
    },
    blockContainer: {
      path: 'components.block.blockContainer',
      name: 'Container',
      disableToggleShow: true,
      defaultConfig: {
        meta: {
          dataType: 'block-container-component',
        },
        responsiveConfig: {
          default: {
            maxWidth: 'screen',
            rowGap: '1.5rem',
          },
        },
        horizontalAlignment: {
          data: {
            '--horizontal-alignment-default': 'center',
          },
        },
      },
      type: 'blockContainer',
    },
    mediaContainer: {
      path: 'components.mediaContainer',
      name: 'Media container',
      type: 'container',
      defaultConfig: {
        meta: {
          dataType: 'container-component',
        },
        verticalAlignment: {
          data: {
            '--vertical-alignment-default': 'flex-start',
          },
        },
      },
      disableToggleShow: true,
      configEditor: {
        formProps: {
          name: 'group',
          registerValues: containerComponentFormRegisterValuesFactory('group'),
        },
      },
    },
    media: {
      path: 'components.media',
      name: 'Media',
      components: {
        credits: {
          path: 'components.credits',
          inlineEditors: [
            {
              editorType: 'inlineText',
              editorPath: 'components.media.components.credits',
            },
          ],
          disableEditing: true,
          name: 'Credits',
          type: 'text',
        },
      },
      defaultConfig: {
        ...MEDIA_DEFAULTS,
        cover: true,
        components: {
          ...MEDIA_DEFAULTS.components,
          image: {
            ...MEDIA_DEFAULTS.components.image,
            meta: {
              dataType: 'image-component',
            },
            responsiveConfig: {
              default: {
                src: 'https://assets.scene.io/global/placeholders/media-720x900.png',
              },
            },
            transformationOptions: {
              cover: '-x100p',
            },
          },
        },
      },
      type: 'media',
    },
    contentContainer: {
      path: 'components.contentContainer',
      name: 'Content container',
      type: 'container',
      defaultConfig: {
        meta: {
          dataType: 'container-component',
        },
        padding: {
          data: {
            '--padding-default-left': '4rem',
            '--padding-default-right': '4rem',
            '--padding-default-top': '2rem',
            '--padding-default-bottom': '4rem',
            '--padding-xs-left': '2rem',
            '--padding-xs-right': '2rem',
          },
        },
        verticalAlignment: {
          data: {
            '--vertical-alignment-default': 'center',
          },
        },
        gap: {
          data: {
            [`--row-gap-default`]: '1.5rem',
          },
        },
      },
      disableToggleShow: true,
      configEditor: {
        formProps: {
          name: 'group',
          registerValues: containerComponentFormRegisterValuesFactory('group'),
        },
      },
    },
    logo: {
      path: 'components.logo',
      name: 'Logo',
      defaultConfig: {
        meta: {
          dataType: 'image-component',
        },
        responsiveConfig: {
          default: {
            src: 'https://assets.scene.io/global/placeholders/logo-ipsum-horizontal.svg',
            width: 170,
          },
        },
        show: false,
      },
      type: 'image',
    },
    subheading: {
      path: 'components.subheading',
      name: 'Subheading',
      inlineEditors: [
        {
          editorType: 'inlineText',
          editorPath: 'components.subheading',
        },
      ],
      defaultConfig: {
        meta: {
          dataType: 'text-component',
        },
        font: {
          meta: {
            dataType: 'font-style',
          },
        },
        children: textToLexical('Subheading for a block'),
        as: 'p',
        show: false,
      },
      type: 'text',
    },
    heading: {
      path: 'components.heading',
      name: 'Heading',
      inlineEditors: [
        {
          editorType: 'inlineText',
          editorPath: 'components.heading',
        },
      ],
      defaultConfig: {
        ...DEFAULT_HEADINGS_CSS_VARIABLES['h1'],
        meta: {
          dataType: 'text-component',
        },
        children: textToLexical('Medium length hero headline goes here', 'h1'),
        as: 'h1',
        show: true,
      },
      type: 'text',
    },
    text: {
      path: 'components.text',
      name: 'Text',
      inlineEditors: [
        {
          editorType: 'inlineText',
          editorPath: 'components.text',
          editorOptions: {
            include: ['table', 'list'],
          },
        },
      ],
      defaultConfig: {
        meta: {
          dataType: 'text-component',
        },
        font: {
          meta: {
            dataType: 'font-style',
          },
        },
        children: textToLexical(
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique. Duis cursus, mi quis viverra ornare, eros dolor interdum nulla, ut commodo diam libero vitae erat.',
        ),
        as: 'p',
        show: true,
      },
      type: 'text',
    },
    author: {
      path: 'components.author',
      inlineEditors: [
        {
          editorType: 'inlineText',
          editorPath: 'components.author',
        },
      ],
      defaultConfig: {
        meta: {
          dataType: 'text-component',
        },
        font: {
          meta: {
            dataType: 'font-style',
          },
        },
        children: textToLexical('Author Name'),
        as: 'p',
        show: false,
      },
      name: 'Author',
      type: 'text',
    },
    form: {
      type: 'form',
      path: 'components.form',
      name: 'Form',
      disableToggleShow: true,
      components: {
        items: {
          isArrayComponent: true,
          path: 'items',
          name: 'Email field',
          type: 'formField',
          disableToggleShow: true,
          components: {
            label: {
              path: 'components.label',
              name: 'Label',
              type: 'text',
              inlineEditors: [
                {
                  editorType: 'inlineText',
                  editorPath: 'components.label',
                  editorOptions: {
                    isNestedArrayItem: true,
                  },
                },
              ],
            },
            field: {
              path: 'components.field',
              name: 'Input',
              type: 'input',
            },
            requiredValueError: {
              path: 'components.requiredValueError',
              name: 'Required value',
              type: 'text',
              inlineEditors: [
                {
                  editorType: 'inlineText',
                  editorPath: 'components.requiredValueError',
                  editorOptions: {
                    isNestedArrayItem: true,
                  },
                },
              ],
            },
            invalidPatternError: {
              path: 'components.invalidPatternError',
              name: 'Invalid pattern',
              type: 'text',
              inlineEditors: [
                {
                  editorType: 'inlineText',
                  editorPath: 'components.invalidPatternError',
                  editorOptions: {
                    isNestedArrayItem: true,
                  },
                },
              ],
            },
            minLengthError: {
              path: 'components.minLengthError',
              name: 'Min length',
              type: 'text',
              inlineEditors: [
                {
                  editorType: 'inlineText',
                  editorPath: 'components.minLengthError',
                  editorOptions: {
                    isNestedArrayItem: true,
                  },
                },
              ],
            },
            maxLengthError: {
              path: 'components.maxLengthError',
              name: 'Max length',
              type: 'text',
              inlineEditors: [
                {
                  editorType: 'inlineText',
                  editorPath: 'components.maxLengthError',
                  editorOptions: {
                    isNestedArrayItem: true,
                  },
                },
              ],
            },
          },
        },
        button: {
          path: 'components.button',
          name: 'Submit button',
          type: 'button',
          configEditor: {
            formProps: {
              excludedSettings: ['link'],
            },
          },
        },
        successMessage: {
          path: 'components.successMessage',
          name: 'Success message',
          type: 'text',
          inlineEditors: [
            {
              editorType: 'inlineText',
              editorPath: 'components.successMessage',
              editorOptions: {
                isNestedArrayItem: true,
              },
            },
          ],
        },
        errorMessage: {
          path: 'components.errorMessage',
          name: 'Error message',
          type: 'text',
          inlineEditors: [
            {
              editorType: 'inlineText',
              editorPath: 'components.errorMessage',
              editorOptions: {
                isNestedArrayItem: true,
              },
            },
          ],
        },
      },
      defaultConfig: {
        ...FORM_DEFAULTS,
        items: [
          formFieldSampleFactory({
            name: 'email',
            type: 'email',
            showLabel: false,
            placeholder: 'Enter your email',
            label: 'Email',
          }),
        ],
        flow: {
          data: {
            '--flow-default': 'row',
            '--flow-xs': 'column',
          },
        },
        horizontalAlignment: {
          data: {
            '--horizontal-alignment-default': 'center',
            '--horizontal-alignment-md': 'flex-start',
          },
        },
        components: {
          ...FORM_DEFAULTS.components,
          button: {
            ...FORM_DEFAULTS.components.button,
            children: textToLexical('Join waitlist'),
            font: {
              meta: {
                dataType: 'font-style',
              },
              data: {
                default: {
                  ...FORM_DEFAULTS.components.button.font?.data?.default,
                  '--font-default-weight-default': 600,
                },
              },
            },
          },
          successMessage: {
            ...FORM_DEFAULTS.components.successMessage,
            horizontalAlignment: {
              data: {
                '--horizontal-alignment-default': 'center',
              },
            },
          },
          errorMessage: {
            ...FORM_DEFAULTS.components.errorMessage,
            children: textToLexical(
              'Something went wrong, and we couldn’t submit your email. Please try again.',
            ),
            horizontalAlignment: {
              data: {
                '--horizontal-alignment-default': 'center',
              },
            },
          },
        },
      },
    },
  }

export const heroWithForm2BlockMeta: BlockMeta<HeroWithForm2Block> = {}

export const heroWithForm2DefaultConfig =
  blockDefaultConfigFactory<HeroWithForm2Block>({
    componentMeta: heroWithForm2ComponentMeta,
    blockMeta: heroWithForm2BlockMeta,
    version: 1,
  })
