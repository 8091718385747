import { search } from 'jmespath'

const nestedArrayPathRegex = new RegExp(/^(.?)+(\[\d+\])/)

export function convertJMESPathToArrayIdsJMESPath({
  configPath,
  data,
}: {
  configPath: string
  data: Record<string, any>
}): string {
  if (!configPath) throw Error('Property `configPath` is not defined')
  if (!data) throw Error('Property `data` is not defined')

  const normalizedConfigPath = configPath.replaceAll(/\.(\d+)/g, '[$1]')
  const isNestedArrayPath = nestedArrayPathRegex.test(normalizedConfigPath)

  if (isNestedArrayPath) {
    return normalizedConfigPath
      .split('.')
      .reduce<string[]>((acc, val) => {
        if (nestedArrayPathRegex.test(val)) {
          const id = search(data, `${[...acc, val].join('.')}.id`)
          if (!id) {
            throw Error(
              'Property `id` required for each array item is not defined',
            )
          }
          const result = val.replace(/\[\d+\]/g, (match, offset, string) => {
            return string[offset - 1] !== '|' ? `[?id=='${id}']|[0]` : match
          })
          acc.push(result)
        } else {
          acc.push(val)
        }
        return acc
      }, [])
      .join('.')
  }

  return normalizedConfigPath
}
