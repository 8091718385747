import { ButtonComponentStyles } from '@sceneio/schemas/lib/types'

export const DEFAULT_BUTTON_CSS_VARIABLES: ButtonComponentStyles = {
  font: {
    meta: {
      dataType: 'font-style',
    },
    data: {
      default: {
        '--font-default-size-default': '1rem',
        '--font-default-family-default': 'Inter, sans-serif',
        '--font-default-style-default': 'normal',
        '--font-default-weight-default': 'normal',
        '--font-default-line-height-default': 1.4,
        '--font-default-word-spacing-default': '0em',
        '--font-default-letter-spacing-default': '0em',
        '--font-default-text-transform-default': 'none',
        '--font-default-text-decoration-color-default': '#000',
        '--font-default-text-decoration-line-default': 'none',
        '--font-default-text-decoration-style-default': 'solid',
      },
      hover: {
        '--font-default-text-decoration-color-hover': '#000',
        '--font-default-text-decoration-line-hover': 'none',
        '--font-default-text-decoration-style-hover': 'solid',
      },
      active: {
        '--font-default-text-decoration-color-active': '#000',
        '--font-default-text-decoration-line-active': 'none',
        '--font-default-text-decoration-style-active': 'solid',
      },
    },
  },
  // Css styles using justify-content property so you need to provide flex alignment props not text-align
  horizontalAlignment: {
    data: {
      '--horizontal-alignment-default': 'center',
    },
  },
  padding: {
    data: {
      '--padding-default-top': '1rem',
      '--padding-default-bottom': '1rem',
      '--padding-default-left': '1.5rem',
      '--padding-default-right': '1.5rem',
    },
  },
  margin: {
    data: {
      '--margin-default-top': '0rem',
      '--margin-default-right': '0rem',
      '--margin-default-bottom': '0rem',
      '--margin-default-left': '0rem',
    },
  },
  color: {
    data: {
      default: {
        '--color-default-default': '#fff',
      },
      hover: {
        '--color-default-hover': '#000',
      },
      active: {
        '--color-default-active': '#000',
      },
    },
  },
  backgroundColor: {
    data: {
      default: {
        '--background-color-default-default': '#000',
      },
      hover: {
        '--background-color-default-hover': '#fff',
      },
      active: {
        '--background-color-default-active': '#fff',
      },
    },
  },
  width: {
    data: {
      '--width-default': 'auto',
    },
  },
  border: {
    meta: {
      dataType: 'border-style',
    },
    data: {
      default: {
        '--border-default-color-default': 'transparent',
        '--border-default-width-default': '1px',
        '--border-default-radius-top-left-default': '0px',
        '--border-default-radius-top-right-default': '0px',
        '--border-default-radius-bottom-left-default': '0px',
        '--border-default-radius-bottom-right-default': '0px',
        '--border-default-style-default': 'solid',
        '--border-default-top-style-default': 'solid',
        '--border-default-right-style-default': 'solid',
        '--border-default-bottom-style-default': 'solid',
        '--border-default-left-style-default': 'solid',
      },
      hover: {
        '--border-default-color-hover': '#000',
      },
      active: {
        '--border-default-color-active': 'transparent',
      },
    },
  },
}

export const DEFAULT_OUTLINE_BUTTON_CSS_VARIABLES: ButtonComponentStyles = {
  color: {
    data: {
      default: {
        '--color-default-default': '#000',
      },
      hover: {
        '--color-default-hover': '#fff',
      },
    },
  },
  backgroundColor: {
    data: {
      default: {
        '--background-color-default-default': '#fff',
      },
      hover: {
        '--background-color-default-hover': '#000',
      },
    },
  },
  border: {
    meta: {
      dataType: 'border-style',
    },
    data: {
      default: {
        '--border-default-color-default': '#000',
      },
      hover: {
        '--border-default-color-hover': 'transparent',
      },
    },
  },
  font: {
    meta: {
      dataType: 'font-style',
    },
  },
}

export const DEFAULT_LINK_BUTTON_CSS_VARIABLES: ButtonComponentStyles = {
  font: {
    meta: {
      dataType: 'font-style',
    },
    data: {
      hover: {
        '--font-default-text-decoration-color-hover': '#000',
        '--font-default-text-decoration-line-hover': 'underline',
      },
    },
  },
  color: {
    data: {
      default: {
        '--color-default-default': '#000',
      },
      hover: {
        '--color-default-hover': '#000',
      },
      active: {
        '--color-default-active': '#000',
      },
    },
  },
  border: {
    meta: {
      dataType: 'border-style',
    },
    data: {
      hover: {
        '--border-default-color-hover': 'transparent',
      },
    },
  },
  backgroundColor: {
    data: {
      default: {
        '--background-color-default-default': 'transparent',
      },
      hover: {
        '--background-color-default-hover': 'transparent',
      },
      active: {
        '--background-color-default-active': 'transparent',
      },
    },
  },
}

export const DEFAULT_ICON_BUTTON_CSS_VARIABLES = {
  font: {
    meta: {
      dataType: 'font-style',
    },
    data: {
      default: {
        '--font-default-size-default': '1.5rem',
      },
    },
  },
  color: {
    data: {
      default: {
        '--color-default-default': '#000',
      },
      hover: {
        '--color-default-hover': '#000',
      },
      active: {
        '--color-default-active': '#000',
      },
    },
  },
  backgroundColor: {
    data: {
      default: {
        '--background-color-default-default': 'transparent',
      },
      hover: {
        '--background-color-default-hover': 'transparent',
      },
      active: {
        '--background-color-default-active': 'transparent',
      },
    },
  },
  padding: {
    data: {
      '--padding-default-top': '0rem',
      '--padding-default-right': '0rem',
      '--padding-default-bottom': '0rem',
      '--padding-default-left': '0rem',
    },
  },
  border: {
    meta: {
      dataType: 'border-style',
    },
    data: {
      default: {
        '--border-default-top-style-default': 'none',
        '--border-default-right-style-default': 'none',
        '--border-default-bottom-style-default': 'none',
        '--border-default-left-style-default': 'none',
      },
    },
  },
}

export const DEFAULT_PRIMARY_BUTTON_CSS_VARIABLES: ButtonComponentStyles = {
  backgroundColor: {
    data: {
      default: {
        '--background-color-default-default': '#5e56f9',
      },
      hover: {
        '--background-color-default-hover': '#554de0',
      },
    },
  },
  border: {
    meta: {
      dataType: 'border-style',
    },
    data: {
      hover: {
        '--border-default-color-hover': 'transparent',
      },
    },
  },
  font: {
    meta: {
      dataType: 'font-style',
    },
  },
  color: {
    data: {
      default: {
        '--color-default-default': '#fff',
      },
      hover: {
        '--color-default-hover': '#fff',
      },
    },
  },
}
