import React from 'react'
import { TabsContentComponent } from '@sceneio/schemas/lib/components/tabsComponentSchema'
import TabPane from './TabPane'
import { isTabActiveInEditor } from './helpers'
import clsx from 'clsx'
import { getRootLevelCssVariables } from '../../../tools/cssVariables/getRootLevelCssVariables'
import { getShadowCssVariables } from '../../../tools/cssVariables/getShadowCssVariables'
import { getBackgroundCssVariables } from '../../../tools/cssVariables/getBackgroundCssVariables'
import { twicPicsSourceUrlResolver } from '@sceneio/middleware/lib/twicPicsSourceUrlResolver'
import { resolveBreakpointImageClasses } from '../../Image/helpers/resolveBreakpointImageClasses'
import { resolveDefaultImageClasses } from '../../Image/helpers/resolveDefaultImageClasses'
import {
  AvailableBreakpointsEnum,
  BreakpointEnum,
} from '../../Image/constants/breakpoints'

function TabsContent({
  editor,
  preview,
  defaultActive = 0,
  show,
  padding,
  margin,
  border,
  boxShadow,
  background,
  horizontalAlignment,
  verticalAlignment,
  gap,
  style = {},
  ...tabsContent
}: TabsContentComponent) {
  if (!show) {
    return null
  }

  const {
    fit,
    parallax,
    responsiveConfig: bgResponsiveConfig,
  } = background || {}

  return (
    <div
      data-plugin-tabs-content
      data-component-id={`${editor?.id}.tabsContent`}
      className={clsx(
        'sc-tabs-content',
        editor?.resolveClassName?.({
          blockId: editor.blockId,
          componentId: `${editor?.id}.tabsContent`,
        }),
      )}
      style={{
        ...style,
        ...getRootLevelCssVariables({
          cssVariables: {
            horizontalAlignment,
            verticalAlignment,
            gap,
            padding,
            margin,
            border,
          },
          prefix: 'tabs-content',
        }),
        ...getShadowCssVariables({
          shadow: boxShadow,
          shadowType: 'box',
          prefix: 'tabs-content',
        }),
        ...getBackgroundCssVariables({
          background,
          prefix: 'tabs-content',
        }),
      }}
    >
      {tabsContent.items?.map((tabPane, index) => {
        const defaultActiveIdx =
          tabsContent?.items?.length &&
          defaultActive > tabsContent?.items?.length - 1
            ? 0
            : defaultActive
        return (
          <TabPane
            {...tabPane}
            key={tabPane.id}
            index={index}
            defaultActive={defaultActiveIdx}
            preview={preview}
            show={
              preview
                ? isTabActiveInEditor({
                    editor,
                    index,
                    tabsLength: tabsContent?.items?.length || 0,
                  })
                : tabPane.show
            }
            editor={{
              ...editor,
              id: `${editor?.id}.tabsContent.items[${index}]`,
            }}
          />
        )
      })}
      {bgResponsiveConfig && Object.keys(bgResponsiveConfig).length && (
        <>
          <div className="sc-tabs-content__bg-overlay" />
          {Object.entries(bgResponsiveConfig).map(([bp, config]) => {
            const { image } = config || {}
            return (
              <React.Fragment key={bp}>
                {image?.src && (
                  <div
                    style={{
                      backgroundImage: `url(${image.src}?twic=v1/output=preview)`,
                    }}
                    data-twic-background={`url(${twicPicsSourceUrlResolver(
                      image.src,
                    )}${editor ? `?cover=${fit === 'cover'}` : ''})`}
                    className={clsx('sc-tabs-content__bg-image', {
                      'sc-tabs-content__bg-image--parallax': parallax,
                      'sc-tabs-content__bg-image--cover': fit === 'cover',
                      [resolveBreakpointImageClasses({
                        breakpoint: bp as BreakpointEnum,
                        elementClassName: 'sc-tabs-content__bg-image',
                      })]: bp !== 'default',
                      [resolveDefaultImageClasses({
                        breakpointsArr: (
                          Object.keys(
                            bgResponsiveConfig,
                          ) as AvailableBreakpointsEnum[]
                        ).filter((bp) => bgResponsiveConfig[bp]),
                        elementClassName: 'sc-tabs-content__bg-image',
                      })]: bp === 'default',
                    })}
                  />
                )}
              </React.Fragment>
            )
          })}
        </>
      )}
    </div>
  )
}

export default TabsContent
