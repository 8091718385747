import { array, object } from 'yup'
import {
  strictContentBlockSchema,
  defaultContentBlockSchema,
} from './contentBlockSchema'

export const defaultContentBlocksSchema = object({
  contentBlocks: array().of(defaultContentBlockSchema),
})
  .noUnknown()
  .required()

export const strictContentBlocksSchema = object({
  contentBlocks: array().of(strictContentBlockSchema),
})
  .noUnknown()
  .required()
