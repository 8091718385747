import clsx from 'clsx'
import { Icon } from '@sceneio/ui-icons'
import { OverridesBadge } from '../OverridesBadge/OverridesBadge'
import { SnippetTypeToIcon } from '../SnippetsDropdown/components/SnippetTypeToIcon'
import {
  BreakpointType,
  RHFBreakpointDropdown,
} from '../BreakpointDropdown/BreakpointDropdown'
import { SnippetNameInput } from '../SnippetNameInput/SnippetNameInput'
import { SnippetChangeDropdown } from '../SnippetChangeDropdown/SnippetChangeDropdown'
import { SNIPPET_TYPE } from '../../form/context/FormContext'

type SnippetHeaderPropType = {
  snippetType: SNIPPET_TYPE
  snippetName: string
  snippetId: string
  snippetHasOverrides: boolean
  editSnippetMode: boolean
  className?: string
  detachButton?: {
    show: boolean
    onClick: () => void
  }
  resetButton?: {
    show: boolean
    onClick: () => void
  }
  editButton?: {
    show: boolean
    onClick: () => void
  }
  removeButton?: {
    show: boolean
    onClick: () => void
  }
  breakpointDropdown?: {
    show: boolean
    activeBreakpoints: string[]
    onBreakpointClick: (arg0: BreakpointType) => void
  }
  customChildren?: JSX.Element
  onAssignSnippet?: (id: string, options?: { shouldReplace?: boolean }) => void
}

export const SnippetHeader = ({
  className,
  snippetType,
  snippetId,
  snippetHasOverrides,
  snippetName,
  detachButton,
  resetButton,
  editButton,
  removeButton,
  breakpointDropdown,
  customChildren,
  onAssignSnippet,
  editSnippetMode,
}: SnippetHeaderPropType) => {
  return (
    <div
      className={clsx(
        'sc-snippet-header tw-flex tw-items-center tw-justify-between tw-px-1 tw-gap-2',
        className,
      )}
    >
      <div className="tw-flex tw-items-center tw-gap-1 tw-min-w-0">
        {snippetHasOverrides && <OverridesBadge />}
        <SnippetTypeToIcon snippetType={snippetType} />
        <SnippetChangeDropdown
          snippetsType={snippetType}
          snippetId={snippetId}
          onAssignSnippet={onAssignSnippet}
          editSnippetMode={editSnippetMode}
        >
          <SnippetNameInput
            snippetName={snippetName}
            editSnippetMode={editSnippetMode}
            hasOverrides={snippetHasOverrides}
            snippetId={snippetId}
            snippetType={snippetType}
            className={clsx(
              'tw-font-semibold tw-rounded tw-p-1 tw-leading-6 focus:tw-outline focus:tw-outline-1 focus:tw-outline-border-color focus:tw-outline-offset-[-1px] [&:not(:disabled)]:hover:tw-outline [&:not(:disabled)]:hover:tw-outline-1 [&:not(:disabled)]:hover:tw-outline-border-color [&:not(:disabled)]:hover:tw-outline-offset-[-1px]',
              {
                'tw-bg-form-field-bg': editSnippetMode || snippetHasOverrides,
              },
            )}
          />
        </SnippetChangeDropdown>
      </div>
      <div className="tw-flex tw-items-center tw-gap-2">
        {editButton?.show && (
          <button type="button" onClick={editButton.onClick} className="tw-p-1">
            <Icon provider="phosphor" icon="PencilSimple" />
          </button>
        )}
        {detachButton?.show && (
          <button
            type="button"
            onClick={detachButton.onClick}
            className="tw-p-1"
          >
            <Icon provider="phosphor" icon="LinkBreak" />
          </button>
        )}

        {resetButton?.show && (
          <button
            type="button"
            onClick={resetButton.onClick}
            className="tw-p-1"
          >
            <Icon provider="phosphor" icon="ArrowClockwise" />
          </button>
        )}
        {breakpointDropdown?.show && (
          <RHFBreakpointDropdown
            onSelect={breakpointDropdown.onBreakpointClick}
            disabledBreakpoints={breakpointDropdown.activeBreakpoints}
          />
        )}
        {removeButton?.show && (
          <button
            type="button"
            onClick={removeButton.onClick}
            className="tw-p-1"
          >
            <Icon provider="phosphor" icon="Minus" />
          </button>
        )}
        {customChildren && customChildren}
      </div>
    </div>
  )
}
