import React, { CSSProperties, ReactNode, useEffect, useState } from 'react'
import { clsx } from 'clsx'
import * as Form from '@radix-ui/react-form'
import * as Select from '@radix-ui/react-select'
import { Icon } from '@sceneio/ui-icons'
import { ErrorMessage } from '../ErrorMessage/ErrorMessage'
import { ClearButton } from '../ClearButton/ClearButton'
import { useFormContext } from '../../form/context/FormContext'

const FALLBACK_DEPRECATED_FONT_WEIGHT = {
  bold: 700,
  bolder: 700,
  normal: 400,
  lighter: 400,
}

export type RHFSelectProps = {
  id?: string
  label?: ReactNode | string
  name: string
  onChange?: (arg0: string) => void
  className?: string
  inputClassName?: string
  required?: boolean
  style?: CSSProperties
  isDisabled?: boolean
  isClearable?: boolean
  hidden?: boolean
  options: {
    value: string | number
    label: string | JSX.Element
    disabled?: boolean
  }[]
  selectClassName?: string
  direction?: 'vertical' | 'horizontal'
  emptyOptionLabel?: string
  placeholder?: string
}

export const RHFSelect = ({
  id,
  name,
  hidden,
  style,
  className,
  selectClassName,
  label,
  direction = 'horizontal',
  isDisabled,
  isClearable = false,
  options = [],
  onChange,
  placeholder,
}: RHFSelectProps) => {
  const { setValue, placeholders, values, error, forceBreakpoint } =
    useFormContext({ name })
  const [key, setKey] = useState(+new Date())

  const containerClasses = clsx(
    'sc-rhf-select tw-container tw-text-xs',
    {
      'tw-hidden': hidden,
    },
    className,
  )

  // //TODO - open issue radixui - select can't be scrolled inside shadowDOM
  useEffect(() => {
    function handleWheel(e: WheelEvent) {
      const el =
        (e.composed && e.composedPath && e.composedPath().shift()) || e.target

      if ((el as Element).closest('[data-scrollable]')) {
        e.stopPropagation()
      }
    }

    function handleTouchMove(e: TouchEvent) {
      let el =
        (e.composed && e.composedPath && e.composedPath().shift()) || e.target

      if ((el as Element).closest('[data-scrollable]')) {
        e.stopPropagation()
      }
    }

    document.addEventListener('wheel', handleWheel, true)
    document.addEventListener('touchmove', handleTouchMove, true)

    return () => {
      document.removeEventListener('wheel', handleWheel, true)
      document.removeEventListener('touchmove', handleTouchMove, true)
    }
  }, [])

  const handleOnChange = (value: string) => {
    if (onChange) {
      onChange(value)
    } else {
      setValue(name, value)
    }
  }

  let value =
    typeof values[name] !== 'undefined' &&
    options.find((opt) => opt.value === values[name])
      ? values[name].toString()
      : undefined
  let selectPlaceholder = placeholders[name] || placeholder

  // Instead of migration replace deprecated font weights with correct values
  // Temp solution, could be deleted after a certain time (year)

  if (id === 'font-weight') {
    value =
      FALLBACK_DEPRECATED_FONT_WEIGHT[
        value as keyof typeof FALLBACK_DEPRECATED_FONT_WEIGHT
      ]?.toString() || value

    selectPlaceholder =
      FALLBACK_DEPRECATED_FONT_WEIGHT[
        selectPlaceholder as keyof typeof FALLBACK_DEPRECATED_FONT_WEIGHT
      ] || selectPlaceholder
  }

  return (
    <Form.Field className={containerClasses} style={style} name={name}>
      <Form.Label
        className={clsx(
          'tw-bg-form-field-bg tw-rounded tw-flex tw-flex-wrap tw-items-stretch tw-justify-between tw-relative hover:tw-outline hover:tw-outline-1 hover:tw-outline-offset-[-1px] hover:tw-outline-border-color focus-within:tw-outline-border-color focus-within:tw-outline-1 focus-within:tw-outline-offset-[-1px] focus-within:tw-outline',
          {
            'tw-flex-col': direction === 'vertical',
            'tw-gap-3': direction === 'horizontal',
            'tw-bg-white': forceBreakpoint && !name.includes(forceBreakpoint),
          },
        )}
      >
        {label && (
          <div
            className={clsx(' tw-py-2 tw-pl-2 tw-text-label-color', {
              'tw-mb-1': direction === 'vertical',
            })}
          >
            {label}
          </div>
        )}
        <div
          className={clsx('tw-flex tw-items-center', {
            'tw-flex-1 tw-min-w-0': !label,
          })}
        >
          <Select.Root
            key={key}
            aria-label={placeholders[name] || placeholder}
            value={value} //radix ui issue - if value is '', it does not fallback to placeholder
            onValueChange={(option) => {
              if (option) {
                handleOnChange(option)
              }
              // radix fix when value is set to '', it does not reset the select ui
              if (!option && options.find((opt) => opt.value === option)) {
                handleOnChange(option)
                setKey(+new Date())
              }
            }}
            disabled={isDisabled}
          >
            <Select.Trigger
              tabIndex={1}
              className={clsx(
                'tw-inline-flex tw-items-center tw-py-2 tw-px-2 tw-justify-between tw-w-full focus-visible:tw-outline-none data-[placeholder]:tw-text-label-color',
                { 'tw-opacity-50 tw-cursor-not-allowed': isDisabled },
                selectClassName,
              )}
            >
              <Select.Value
                placeholder={
                  options.find((opt) => opt.value === selectPlaceholder)
                    ?.label || 'Select'
                }
              />
              {(!forceBreakpoint ||
                (forceBreakpoint && name.includes(forceBreakpoint))) && (
                <Select.Icon className="tw-mx-1">
                  <Icon provider="phosphor" icon="CaretDown" />
                </Select.Icon>
              )}
            </Select.Trigger>
            <Select.Content
              position="popper"
              side="bottom"
              style={{
                minWidth: 'var(--radix-select-trigger-width)',
                maxHeight: 'var(--radix-select-content-available-height)',
              }}
              className="tw-overflow-hidden tw-bg-white tw-text-xs tw-rounded tw-border tw-border-border-color tw-p-1 tw-z-[100000] tw-my-px"
            >
              <div
                className="tw-max-h-[410px] tw-overflow-y-scroll"
                data-scrollable
              >
                <Select.Viewport>
                  {options.map((option) => (
                    <Select.Item
                      key={option.value}
                      value={option.value.toString()}
                      disabled={option.disabled}
                      className="tw-select-none tw-flex tw-justify-between tw-items-center tw-p-2 hover:tw-outline-none focus-visible:tw-outline-none data-[highlighted]:tw-bg-form-field-bg tw-rounded data-[disabled]:tw-text-label-color"
                    >
                      <Select.ItemText>{option.label}</Select.ItemText>
                      <Select.ItemIndicator className="tw-pl-1">
                        <Icon provider="phosphor" icon="Check" />
                      </Select.ItemIndicator>
                    </Select.Item>
                  ))}
                </Select.Viewport>
              </div>
            </Select.Content>
          </Select.Root>
        </div>
        {isClearable && values[name] && (
          <ClearButton name={name} onClick={() => setKey(+new Date())} />
        )}
      </Form.Label>
      {error && <ErrorMessage message={error?.message} />}
    </Form.Field>
  )
}
