export type SnippetConfigType =
  | 'ATOMIC_COLOR'
  | 'STROKE'
  | 'TEXT_SHADOW'
  | 'BOX_SHADOW'
  | 'TEXT'
  | 'BUTTON'

export const ALLOWED_SNIPPET_FIELDS_BY_SNIPPET_TYPE: {
  [key in SnippetConfigType]?: string[]
} = {
  BUTTON: [
    'width',
    'font',
    'padding',
    'margin',
    'color',
    'backgroundColor',
    'boxShadow',
    'textShadow',
    'border',
    'horizontalAlignment',
  ],
}
