import {
  TabPaneComponent,
  TabsContentComponent,
  TabsMenuComponent,
} from '@sceneio/schemas/lib/components/tabsComponentSchema'
import { ButtonComponent } from '@sceneio/schemas/lib/types'
import { randomString } from '@sceneio/tools'
import { textToLexical } from '@sceneio/wysiwyg-utils'
import { DEFAULT_BUTTON_CSS_VARIABLES } from '../Button/constants/globalButtonCssVariables'
import { ComponentMeta } from '../componentCommonTypes'
import { componentDefaultConfigFactory } from '../helpers'

export const tabButtonSampleFactory = ({
  title = 'Tab',
} = {}): ButtonComponent & {
  id: string
} => ({
  meta: {
    dataType: 'button-component',
  },
  id: randomString(8),
  children: textToLexical(title, 'array-item'),
  ...DEFAULT_BUTTON_CSS_VARIABLES,
  width: {
    data: {
      '--width-default': '200px',
      '--width-xs': '100%',
    },
  },
  padding: {
    data: {
      '--padding-default-bottom': '1rem',
      '--padding-default-top': '1rem',
      '--padding-default-left': '1.5rem',
      '--padding-default-right': '1.5rem',
    },
  },
  backgroundColor: {
    data: {
      default: {
        '--background-color-default-default': 'transparent',
      },
      active: {
        '--background-color-default-active': '#000000',
      },
    },
  },
  color: {
    data: {
      default: {
        '--color-default-default': '#000000',
      },
      active: {
        '--color-default-active': '#ffffff',
      },
    },
  },
})

export const tabPaneSampleFactory = (): TabPaneComponent => ({
  meta: {
    dataType: 'tab-pane-component',
  },
  id: randomString(8),
  flow: {
    data: {
      '--flow-default': 'row',
    },
  },
  horizontalAlignment: {
    data: {
      '--horizontal-alignment-default': 'center',
    },
  },
  verticalAlignment: {
    data: {
      '--vertical-alignment-default': 'center',
    },
  },
  gap: {
    data: {
      '--column-gap-default': '0.75rem',
    },
  },
})

export const tabsMenuDefaultConfig: TabsMenuComponent = {
  meta: {
    dataType: 'container-component',
  },
  show: true,
  flow: {
    data: {
      '--flow-default': 'row',
    },
  },
  horizontalAlignment: {
    data: {
      '--horizontal-alignment-default': 'center',
    },
  },
  verticalAlignment: {
    data: {
      '--vertical-alignment-default': 'center',
    },
  },
  margin: {
    data: {
      '--margin-default-bottom': '4rem',
    },
  },
  padding: {
    data: {
      '--padding-default-bottom': '0.5rem',
      '--padding-default-top': '0.5rem',
      '--padding-default-left': '0.5rem',
      '--padding-default-right': '0.5rem',
    },
  },
  background: {
    responsiveConfig: {
      default: {
        color: '#EEEEEE',
      },
    },
  },
}

export const tabsContentDefaultConfig: TabsContentComponent = {
  meta: {
    dataType: 'tabs-content-component',
  },
  show: true,
}

export const tabsComponentMeta: ComponentMeta<'tabs'> = {
  defaultConfig: {
    meta: {
      dataType: 'tabs-component',
    },
    defaultActive: 0,
    components: {
      tabsMenu: tabsMenuDefaultConfig,
      tabsContent: tabsContentDefaultConfig,
    },
    horizontalAlignment: {
      data: {
        '--horizontal-alignment-default': 'center',
      },
    },
    show: true,
  },
}

export const tabsDefaults = componentDefaultConfigFactory<'tabs'>({
  componentMeta: tabsComponentMeta,
})
