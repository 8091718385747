import { search } from 'jmespath'
import { isObject } from './isObject'
import { getFlatObjectKeyFromJMESPath } from './getFlatObjectKeyFromJMESPath'
import { deepClone } from './deepClone'

export function changeObjectPropertyByJmespath<T extends Object>(
  obj: T,
  jmespath: string,
  valueToChange: unknown,
  options?: {
    shouldMergeValue?: boolean
    snippetAllowedFields?: string[]
  },
) {
  const shouldMergeValue = options?.shouldMergeValue || false
  const snippetAllowedFields = options?.snippetAllowedFields

  let internalObj = { ...obj }

  if (Object.isFrozen(obj)) {
    internalObj = deepClone(obj)
  }

  const flatKey = getFlatObjectKeyFromJMESPath({ data: internalObj, jmespath })

  let targetValue = valueToChange

  if (isObject(targetValue)) {
    const searchFlatKey = flatKey.replaceAll(
      /((?!\/--)[^.]*-(?!-)[^.]*)/g,
      '"$1"',
    )

    const currentValue = search(internalObj, searchFlatKey)

    if (isObject(currentValue) && shouldMergeValue) {
      const currentValueCopy = { ...currentValue }

      if (snippetAllowedFields) {
        snippetAllowedFields.forEach((snippetField) => {
          delete currentValueCopy[snippetField]
        })
      }

      targetValue = {
        ...currentValueCopy,
        ...(targetValue as Record<string, unknown>),
      }
    }
  }

  const evalFlatKey = flatKey.replaceAll(
    /\.((?!\/--)[^.]*-(?!-)[^.]*)/g,
    '["$1"]',
  )

  const setPropertyFunction = new Function(
    'obj',
    'path',
    'value',
    `obj.${evalFlatKey} = value;`,
  )
  try {
    setPropertyFunction(internalObj, flatKey, targetValue)
  } catch (e: any) {
    console.error(`[changeObjectPropertyByJmespath]: ${e.message}`)
    throw new Error(`[changeObjectPropertyByJmespath]: ${e.message}`)
  }

  return internalObj
}
