import { TabsComponent } from '@sceneio/schemas/lib/types'

export const isTabActiveInEditor = ({
  editor,
  index,
  tabsLength,
}: {
  editor: TabsComponent['editor']
  index: number
  tabsLength: number
}) => {
  if (!editor || !editor?.id || !editor?.blockId) {
    return index === 0
  }

  const persistentComponentSelection =
    editor.persistentComponentSelection as Record<string, any>

  if (!persistentComponentSelection?.[editor.blockId]) {
    return index === 0
  }

  const selectedComponents = persistentComponentSelection[
    editor.blockId
  ] as Record<string, string>

  const selectedId = selectedComponents?.[editor.id]

  if (!selectedId) {
    return index === 0
  }
  const tabItemsPattern = /(tabsContent|tabsMenu)\.items\[(\d+)\]/
  const selectedTabIndex = selectedId.match(tabItemsPattern)?.[2]
  if (selectedTabIndex && Number(selectedTabIndex) >= tabsLength) {
    return index === 0
  }

  const isTabSelected =
    selectedId.includes(`tabsMenu.items[${index}]`) ||
    selectedId.includes(`tabsContent.items[${index}]`)

  return isTabSelected
}
